import {
  Icon,
  IconEnum,
  IconSize,
  Icons,
  ModalVariant,
} from '@pdcfrontendui/components';
import { RouteEnum, getRouteState, getSiteRoutes } from '../routes';
import { showConfirmDialog, toggleMenu } from '../appActions';

import ErrorBoundaryContainer from '../sharedComponents/ErrorBoundaryContainer';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../rootReducer';
import { currentLanguage } from '../currentLanguage';
import ids from '../testing/ids';
import { logout } from '../Login/LoginAction';
import scss from './BottomMenu.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export enum BottomMenuSelect {
  Overview = 'Overview',
  Chat = 'Chat',
  ChangeTeam = 'ChangeTeam',
}

function BottomMenuButton({
  onClick,
  children,
  counter,
  danger,
  icon: Icon,
  selected,
  disabled,
  id,
}: {
  onClick: () => void;
  children: string;
  counter?: number;
  danger?: boolean;
  icon: Icon;
  selected?: boolean;
  disabled?: boolean;
  id?: string;
}) {
  return (
    <button
      className={classNames(scss.button, selected && scss.selected)}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      {!!counter && (
        <div className={classNames(scss.counter, danger && scss.danger)}>
          {counter > 99 ? '99+' : counter}
        </div>
      )}
      <Icon className={scss.icon} size={IconSize.Small} />
      {children}
    </button>
  );
}

function BottomMenuPill({ index, hide }: { index: number; hide: boolean }) {
  return (
    <div
      className={scss.pill}
      style={{
        transform: `translate3d(${index * 100}%,0,0)`,
        opacity: hide ? 0 : undefined,
      }}
    >
      <div />
    </div>
  );
}

const BottomMenu = connect(
  ({ listViewReducer, appReducer }) => ({
    actionsRequired:
      listViewReducer.actionRequiredCounters[listViewReducer.currentTeam] ?? 0,
    numUnread: appReducer.unreadCountMap[listViewReducer.currentTeam] ?? 0,
    showingMenu: appReducer.shouldShowMenu,
    currentTeam: listViewReducer.currentTeam,
  }),
  (dispatch) => ({
    logout: () => {
      dispatch(
        showConfirmDialog({
          title: currentLanguage.ConfirmLogout,
          message: '',
          iconKey: IconEnum.Logoff,
          onOk: () => {
            dispatch(logout())?.finally(() => undefined);
          },
          buttonOkText: currentLanguage.YesLogout,
          buttonCancelText: currentLanguage.Cancel,
          variant: ModalVariant.Alert,
        })
      );
    },
    openChangeTeam: () => {
      dispatch(toggleMenu());
    },
  })
)(
  ({
    actionsRequired,
    numUnread,
    openChangeTeam,
    logout,
    showingMenu,
    currentTeam,
  }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const routeState = getRouteState(location);
    const isTeamRoute = routeState?.route === RouteEnum.Team;
    const isTeamsRoute = routeState?.route === RouteEnum.Teams;
    const isChatRoute = routeState?.route === RouteEnum.Chat;
    return (
      <ErrorBoundaryContainer>
        <nav className={scss.comp}>
          <BottomMenuPill
            index={showingMenu || isTeamsRoute ? 2 : isTeamRoute ? 0 : 1}
            hide={!showingMenu && isTeamsRoute}
          />
          <BottomMenuButton
            onClick={() =>
              navigate(
                currentTeam
                  ? getSiteRoutes().team(currentTeam)
                  : getSiteRoutes().team()
              )
            }
            icon={Icons.Overview}
            id={ids.Menu.overview}
            counter={actionsRequired}
            danger
            selected={isTeamRoute}
            disabled={isTeamsRoute}
          >
            {currentLanguage.Overview}
          </BottomMenuButton>
          <BottomMenuButton
            onClick={() => navigate(getSiteRoutes().chat(currentTeam))}
            icon={Icons.Chat}
            id={ids.Menu.chat}
            counter={numUnread}
            selected={isChatRoute}
            disabled={isTeamsRoute}
          >
            {currentLanguage.Chat}
          </BottomMenuButton>
          <BottomMenuButton
            onClick={openChangeTeam}
            icon={Icons.Group}
            id={ids.Menu.chooseTeam}
            selected={showingMenu}
          >
            {currentLanguage.ChangeTeam}
          </BottomMenuButton>
          <BottomMenuButton
            onClick={logout}
            icon={Icons.Logoff}
            id={ids.Menu.logout}
          >
            {currentLanguage.Logout}
          </BottomMenuButton>
        </nav>
      </ErrorBoundaryContainer>
    );
  }
);

export default BottomMenu;
