import { useCallback, useLayoutEffect, useRef } from 'react';

/**
 * Call a given function each frame
 */
export default (cb: VoidFunction) => {
  if (typeof window === 'undefined') {
    return;
  }

  const cbRef = useRef(cb);
  cbRef.current = cb;
  const frame = useRef<number>();

  const animate = useCallback(() => {
    cbRef.current();
    frame.current = requestAnimationFrame(animate);
  }, []);

  useLayoutEffect(() => {
    frame.current = requestAnimationFrame(animate);
    return () => {
      if (frame.current !== undefined) {
        cancelAnimationFrame(frame.current);
      }
    };
  }, [animate]);
};
