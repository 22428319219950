import MessageList, { DispatchFromProps, StateFromProps } from './MessageList';
import {
  attemptMarkMessagesAsRead,
  attemptPostMessage,
  openModal,
} from '../appActions';

import { ModalWrapperProps } from '../Modal/ModalWrapper';
import { Store } from '../rootReducer';
import { ThunkDispatch } from '../types';
import { connect } from 'react-redux';

const mapStateToProps = (store: Store): StateFromProps => ({
  dayMessageMap: store.appReducer.dayMessageMap,
  userId: store.loginReducer.userId,
  teamId: store.listViewReducer.currentTeam,
  employees:
    store.listViewReducer.employeeMap[store.listViewReducer.currentTeam] ?? {},
  currentDate: store.appReducer.globalSettings.useDateTime,
  period: store.listViewReducer.period,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  openModal: (modal: ModalWrapperProps) => {
    dispatch(openModal(modal));
  },
  callPostMessage: (personId, messageText, isInternal, date) => {
    dispatch(attemptPostMessage(personId, messageText, isInternal, date));
  },
  callMarkMessagesAsRead: (messageIds) => {
    dispatch(attemptMarkMessagesAsRead(messageIds));
  },
});

export default connect<StateFromProps, DispatchFromProps, object, Store>(
  mapStateToProps,
  mapDispatchToProps
)(MessageList);
