import type { TrackingObject } from '../api/Tracking_api';
import { z } from 'zod';
import { SHIFT_TYPE_SUGGESTIONS_MAX } from '../constants';
import { UserLoginTypeEnum } from '../api/enumLib_api';
import type { LanguageCode } from '../currentLanguage';

/**
 * Keys used to access local storage
 * Keys are arbitrary, values are not
 */
export enum LocalStorageKey {
  token = 'token',
  tokenTimestamp = 'tokenTimestamp',
  userId = 'userId',
  userType = 'userType',
  fullName = 'fullName',
  currentTeam = 'currentTeam',
  userTracking = 'userTracking',
  currentLanguage = 'currentLanguage',
  deviceToken = 'deviceToken',
  key = 'key',
  shiftTypeSuggestions = 'shiftTypeSuggestions',
}

const trackingObjectSchema: z.Schema<TrackingObject> = z.object({
  userHash: z.string(),
  sessionStart: z.coerce.date(),
  viewPort: z.object({ width: z.number(), height: z.number() }),
  device: z.string(),
  navigations: z.array(
    z.object({
      viewName: z.string(),
      timeStamp: z.coerce.date(),
      activities: z.array(
        z.object({
          name: z.string(),
          timeStamp: z.coerce.date(),
          auxDate: z.coerce.date().nullable(),
        })
      ),
    })
  ),
});

export const localStorageSchemas = {
  [LocalStorageKey.token]: z.string(),
  [LocalStorageKey.tokenTimestamp]: z.number(),
  [LocalStorageKey.userId]: z.number().finite(),
  [LocalStorageKey.userType]: z.nativeEnum(UserLoginTypeEnum),
  [LocalStorageKey.fullName]: z.string(),
  [LocalStorageKey.currentTeam]: z.string(),
  [LocalStorageKey.currentLanguage]: z.enum([
    'En',
    'Da',
    'Se',
  ]) satisfies z.Schema<LanguageCode>,
  [LocalStorageKey.deviceToken]: z.string(),
  [LocalStorageKey.key]: z.string(),
  [LocalStorageKey.shiftTypeSuggestions]: z.record(
    z.array(z.string()).max(SHIFT_TYPE_SUGGESTIONS_MAX)
  ),
  [LocalStorageKey.userTracking]: trackingObjectSchema,
} as const satisfies Record<LocalStorageKey, z.ZodType>;

export type LocalStorageItemType<T extends LocalStorageKey> = z.infer<
  (typeof localStorageSchemas)[T]
>;
