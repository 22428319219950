import EmptyState from './EmptyState';
import colorVarMap from '@pdcfrontendui/components/colors';
import { currentLanguage } from '../currentLanguage';
import { Tab } from '../routes';
import { localeFormatLongDay, Period } from '../util/dates';

function NoMessagesImage() {
  return (
    <svg
      width="60%"
      height="100%"
      viewBox="0 0 1890 1890"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
    >
      <g transform="matrix(1,0,0,1,-4088.69,-2011.42)">
        <g id="BeskederEmptyState" transform="matrix(1,0,0,1,3952.58,1305.78)">
          <rect
            x="136.107"
            y="705.635"
            width="1889.53"
            height="1889.53"
            fill="none"
          />
          <g transform="matrix(1.13555,0,0,1.1553,-307.212,-323.425)">
            <g transform="matrix(11.2037,-1.46105,1.31779,10.1051,1292.5,1876.36)">
              <path
                d="M35.8,-54.9C49.9,-46.6 67.3,-42.6 70.1,-33.1C72.9,-23.5 61.1,-8.2 56.8,6.8C52.5,21.8 55.8,36.5 50.3,44.5C44.8,52.6 30.5,54.1 18.6,52.6C6.7,51.2 -2.9,46.9 -17.1,47.6C-31.2,48.2 -49.9,53.8 -63,48.9C-76,44 -83.5,28.5 -81,14.5C-78.5,0.6 -66,-12 -59.6,-27.7C-53.1,-43.4 -52.6,-62.3 -43.7,-73C-34.8,-83.7 -17.4,-86.3 -3.3,-81.2C10.8,-76.1 21.6,-63.3 35.8,-54.9Z"
                fill={colorVarMap.colorPrimaryLightest}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(1.34276,0,0,1.34276,-460.411,-838.403)">
              <circle
                cx="1253.24"
                cy="2002.62"
                r="35.703"
                fill={colorVarMap.colorPrimaryDark}
              />
            </g>
            <g transform="matrix(6.45901,0,0,6.45901,-1277.31,-7240.1)">
              <path
                d="M464.607,1339.2L464.607,1333.85L474.324,1322.69C475.919,1320.87 477.099,1319.58 477.864,1318.81C477.067,1318.86 476.017,1318.89 474.715,1318.91L465.56,1318.96L465.56,1313.27L486.995,1313.27L486.995,1318.13L477.083,1329.56L473.592,1333.34C475.496,1333.23 476.676,1333.17 477.132,1333.17L487.752,1333.17L487.752,1339.2L464.607,1339.2Z"
                fill={colorVarMap.colorPrimaryDark}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(9.6753,0,0,10.2486,-2957.47,-12517.9)">
              <path
                d="M464.607,1339.2L464.607,1333.85L474.324,1322.69C475.919,1320.87 477.099,1319.58 477.864,1318.81C477.067,1318.86 476.017,1318.89 474.715,1318.91L465.56,1318.96L465.56,1313.27L486.995,1313.27L486.995,1318.13L477.083,1329.56L473.592,1333.34C475.496,1333.23 476.676,1333.17 477.132,1333.17L487.752,1333.17L487.752,1339.2L464.607,1339.2Z"
                fill={colorVarMap.colorPrimaryDark}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g transform="matrix(51.7313,0,0,51.7313,460.095,1103.51)">
            <path
              d="M14.45,19L12,22.5L9.55,19L3,19C2.451,19 2,18.549 2,18L2,4C2,3.451 2.451,3 3,3L21,3C21.549,3 22,3.451 22,4L22,18C22,18.549 21.549,19 21,19L14.45,19ZM13.409,17.155L20.155,17.155L20.155,4.845L3.845,4.845L3.845,17.155L10.591,17.155L12,19.186L13.409,17.155Z"
              fill={colorVarMap.colorPrimaryDark}
              fillRule="nonzero"
            />
          </g>
          <g transform="matrix(1.13555,0,0,1.1553,-347.034,-493.31)">
            <g transform="matrix(1,0,0,1,-34.8388,-144.402)">
              <path
                d="M1204.3,1943.2C1192.17,1974.78 1161.55,1997.22 1125.72,1997.22C1089.87,1997.22 1059.23,1974.74 1047.12,1943.13"
                fill="none"
                stroke={colorVarMap.colorPrimaryDark}
                strokeWidth="40.01px"
              />
            </g>
            <g transform="matrix(1,0,0,1,298.345,-144.402)">
              <path
                d="M1204.3,1943.2C1192.17,1974.78 1161.55,1997.22 1125.72,1997.22C1089.87,1997.22 1059.23,1974.74 1047.12,1943.13"
                fill="none"
                stroke={colorVarMap.colorPrimaryDark}
                strokeWidth="40.01px"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function NoMessages({
  tab,
  period,
  className,
}: {
  tab: Tab;
  period: Period;
  className?: string;
}) {
  const from = localeFormatLongDay(
    period.from,
    false,
    currentLanguage.languageCode
  );
  const to = localeFormatLongDay(
    period.to,
    false,
    currentLanguage.languageCode
  );

  return (
    <div className={className}>
      <EmptyState
        image={NoMessagesImage}
        heading={
          tab === Tab.Read
            ? currentLanguage.ReadMessagesEmptyStateTitle
            : currentLanguage.UnreadMessagesEmptyStateTitle
        }
        description={
          tab === Tab.Read
            ? currentLanguage.ReadMessagesEmptyStateDescription_2(from, to)
            : currentLanguage.UnreadMessagesEmptyStateDescription_2(from, to)
        }
      />
    </div>
  );
}
