import { Button, IconSize, Icons, Modal } from '@pdcfrontendui/components';

import { currentLanguage } from '../currentLanguage';

import './PersonelgroupInfoModal.scss';

const scss = {
  Comp: 'Comp',
  SymbolExplanation: 'symbolexplanation',
};

type Props = {
  shown: boolean;
  isTouch: boolean;
  onHide: () => void;
};

const PersonelgroupInfoModal = ({ shown, isTouch, onHide }: Props) => {
  return (
    <Modal shown={shown}>
      <Modal.Header>{currentLanguage.PersonelGroupInfoTitle}</Modal.Header>
      {isTouch ? (
        <Modal.Body className={scss.Comp}>
          {`${currentLanguage.PersonelGroupInfoBody1}\n
            ${currentLanguage.PersonelGroupInfoBody2Touch}\n
            ${currentLanguage.PersonelGroupInfoBodyTouchSymbolExplanation}\n\n`}
          <div className={scss.SymbolExplanation}>
            <Icons.Download size={IconSize.XSmall} />
            <div>
              {
                currentLanguage.PersonelGroupInfoBodyTouchSymbolExplanationFetchData
              }
            </div>
            <Icons.Unresolved size={IconSize.XSmall} />
            <div>
              {
                currentLanguage.PersonelGroupInfoBodyTouchSymbolExplanationUnresolved
              }
            </div>
            <Icons.Messages size={IconSize.XSmall} />
            <div>
              {
                currentLanguage.PersonelGroupInfoBodyTouchSymbolExplanationUnreadChat
              }
            </div>
            <Icons.Follow size={IconSize.XSmall} />
            <div>{currentLanguage.FollowGroup}</div>
            <Icons.Unfollow size={IconSize.XSmall} />
            <div>{currentLanguage.UnfollowGroup}</div>
            <Icons.NotificationOff size={IconSize.XSmall} />
            <div>{currentLanguage.NotificationsOff}</div>
            <Icons.NotificationOn size={IconSize.XSmall} />
            <div>{currentLanguage.NotificationsOn}</div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className={scss.Comp}>
          {`${currentLanguage.PersonelGroupInfoBody1}\n
            ${currentLanguage.PersonelGroupInfoBody2NonTouch}`}
        </Modal.Body>
      )}
      <Modal.Buttons>
        <Button onClick={() => onHide()}>{currentLanguage.Close}</Button>
      </Modal.Buttons>
    </Modal>
  );
};

export default PersonelgroupInfoModal;
