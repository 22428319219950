import Button, { ButtonStyle } from '@pdcfrontendui/components/Button';

import SearchBar from '@pdcfrontendui/components/SearchBar';
import { currentLanguage } from '../currentLanguage';
import ids from '../testing/ids';

interface Props {
  value: string;
  updateFilterByEmployee: (employeeName: string) => void;
  onClick: () => void;
}
const EmployeeFilterBar = ({
  value,
  updateFilterByEmployee,
  onClick,
}: Props) => (
  <div className="employee-filter-container">
    <SearchBar
      value={value}
      placeholder={currentLanguage.search}
      onChange={updateFilterByEmployee}
      id={ids.App.filter}
      className="employee-filter"
    />
    <Button variant={ButtonStyle.GhostOnDark} onClick={onClick}>
      {currentLanguage.Cancel}
    </Button>
  </div>
);
export default EmployeeFilterBar;
