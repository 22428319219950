import type { WebResponseResult } from '@pdcfrontendui/staffplan';

// We prefer having paramsToJSON be a generic method that returns an instance's properties excluding the ones from WebRequest.
// Otherwise we would have to override it in every subclass.
const ignored = {
	id: true,
	jsonrpc: true,
	webService: true,
	method: true,
	params: true, // Properties of params are added with a spread in paramsToJSON
	noUserValidation: true,
	noAuditAction: true,
	paramsToJSON: true,
	resultFromJSON: true,
	toJSON: true,
	language: false, // Added right before fetching
	ApplicationId: false, // Added right before fetching
} as const satisfies Record<keyof WebRequest<unknown>, boolean>;

// TODO CHHI: Use identical class directly from @pdcfrontendui/staffplan once MyPlan builds with Vite
export abstract class WebRequest<ResultType> {
	public readonly jsonrpc = '2.0';
	public webService = 'unknownService';
	public method = 'unknownMethod';
	public noUserValidation?: boolean;
	public noAuditAction?: boolean;

	public id = 'unknownId'; // Overwritten right before fetching
	public language: string | null = null; // Overwritten right before fetching
	public params: { auditActionName?: string; auditActionNumber?: number } =
		{}; // Overwritten right before fetching
	ApplicationId?: number; // Overwritten right before fetching

	// Ugly TypeScript, but it works. Luckily we're testng this.
	public paramsToJSON() {
		return Object.fromEntries(
			Object.entries(this).filter(
				([key]) => !ignored[key as keyof typeof ignored]
			)
		) as any;
	}

	// Build fails unless return type is explicit
	public toJSON(): {
		id: string;
		jsonrpc: string;
		method: string;
		params: ReturnType<WebRequest<unknown>['paramsToJSON']> &
			WebRequest<unknown>['params'];
	} {
		return {
			id: this.id,
			jsonrpc: this.jsonrpc,
			method: this.method,
			params: { ...this.params, ...this.paramsToJSON() },
		};
	}

	public abstract resultFromJSON(
		jsonResult: WebResponseResult<ResultType>
	): ResultType;
}
