import './Login.scss';

import {
  Button,
  ButtonIconPosition,
  ButtonStyle,
  IconSize,
  Icons,
  InlineNotification,
  LoadingButton,
  NotificationType,
  Spinner,
} from '@pdcfrontendui/components';
import ModalWrapper, {
  ModalType,
  ModalWrapperProps,
} from '../Modal/ModalWrapper';
import React, { useEffect } from 'react';

import { GetVersionModel } from '../api/model';
import LanguageSelectContainer from '../LanguageSelect/LanguageSelectContainer';
import LoginUsernamePasswordForm from './LoginUsernamePasswordForm';
import { LogonTypeEnum } from '../api/enumLib_api';
import { OpenIdConfig } from './OpenId';
import { currentLanguage } from '../currentLanguage';
import ids from '../testing/ids';

export type LoginProps = Record<string, never>;

export type StateFromProps = {
  errorMessage: string;
  version: GetVersionModel | null;
  openModals: Array<ModalWrapperProps>;
  modalLoading: boolean;
  loginType?: LogonTypeEnum;
  openId: OpenIdConfig;
  attemptingLogin: boolean;
  loadingGetSettings: boolean;
  loadingGetVersion: boolean;
};

export interface DispatchFromProps {
  attemptLogin: (username: string, password: string) => void;
  attemptOpenIdLogin: (settings: OpenIdConfig, errorMessage: string) => void;
  attemptGetVersion: () => void;
  attemptGetGlobalSettings: () => void;
  openModal: (modal: ModalWrapperProps) => void;
  closeModal: () => void;
}

export type LoginValues = {
  username: string;
  password: string;
};

const initialValues: LoginValues = {
  username: '',
  password: '',
};

type Props = StateFromProps & DispatchFromProps;

const Login = ({
  attemptLogin,
  attemptOpenIdLogin,
  attemptGetVersion,
  attemptGetGlobalSettings,
  loginType,
  openId,
  errorMessage,
  openModals,
  openModal,
  closeModal,
  version,
  modalLoading,
  attemptingLogin,
  loadingGetSettings,
  loadingGetVersion,
}: Props) => {
  const loading = attemptingLogin || loadingGetSettings || loadingGetVersion;
  useEffect(() => {
    attemptGetVersion();
    attemptGetGlobalSettings();
  }, []);

  // RATJ: this is a little hack for PDC-shell for IOS with big screens - html defines the color of webView background
  // And since our login screen is not white like the rest of the app, we add a class to set background color of html
  useEffect(() => {
    const html = document.documentElement;
    html.classList.add('isLoginScreen');
    return () => {
      html.classList.remove('isLoginScreen');
    };
  });

  const getLoginTypeEnumFromString = (
    loginTypeString: string
  ): LogonTypeEnum => {
    // The casing of loginTypeString may not match the casing of LogonTypeEnum.
    const loginTypeLowerCase = loginTypeString.toLowerCase();
    const value = Object.keys(LogonTypeEnum).find(
      (key) => key.toLowerCase() === loginTypeLowerCase
    );
    // Hack to get enum value from a string

    if (!value)
      throw new Error(`Login Type "${loginTypeLowerCase}" not found.`);

    return LogonTypeEnum[value as keyof typeof LogonTypeEnum];
  };

  const loginTypeEnum =
    typeof loginType === 'string'
      ? getLoginTypeEnumFromString(loginType)
      : loginType;

  // Returns an object responsible for splitting a word by the first
  // capital letter (first letter excluded). So we can color part
  // of the portal name blue.
  const portalName = (() => {
    const match = currentLanguage.teamPlan.match(/([A-Za-z][a-z]+?)([A-Z].+)/);

    if (!match || match[1] === '') {
      return { first: currentLanguage.teamPlan, second: undefined };
    }
    return { first: match[1], second: match[2] };
  })();

  const renderModal = () => {
    let modalContent: ModalWrapperProps;

    if (openModals.length > 0) {
      modalContent = openModals[openModals.length - 1] as ModalWrapperProps;
    } else {
      modalContent = {
        shouldShow: false,
        modalType: null,
      };
    }

    return (
      <ModalWrapper
        modalType={modalContent.modalType}
        modalBtn={modalContent.modalBtn}
        shouldShow={modalContent.shouldShow}
        content={modalContent.content}
        onClose={() => {
          closeModal();
        }}
        version={version}
        loading={modalLoading}
      />
    );
  };

  return (
    <div className="LoginNew">
      <div className="header">
        <div className="headerLeft">
          {window.pdc?.shell?.emit && (
            <Button
              variant={ButtonStyle.Ghost}
              icon={Icons.ChevronLeft}
              iconPosition={ButtonIconPosition.Left}
              onClick={() => window.pdc.shell.emit('show-picker')}
              id={ids.Login.changeOrgButton}
            >
              {currentLanguage.ChangeOrganization}
            </Button>
          )}
        </div>
        <div className="headerRight">
          <div className="login-language-select">
            <LanguageSelectContainer />
          </div>
          <Icons.Info
            className="questionButton"
            onClick={() => {
              openModal({
                modalType: ModalType.info,
              });
            }}
            size={IconSize.XSmall}
            id={ids.Login.InfoButton}
          />
        </div>
      </div>
      <div className="content">
        <div className="errorMessages">
          {errorMessage && (
            <InlineNotification
              type={NotificationType.Error}
              visible={!!errorMessage}
            >
              <div className="errorItem">{errorMessage}</div>
            </InlineNotification>
          )}
        </div>
        <div className="login-wrapper">
          <div className="title">
            {currentLanguage.loginTitleWelcome}
            <div className="title-image">
              {portalName.first && (
                <span className="portal-name portal-name-first">
                  {portalName.first}
                </span>
              )}
              {portalName.second && (
                <span className="portal-name portal-name-second">
                  {portalName.second}
                </span>
              )}
            </div>
          </div>
          <div className="login-box">
            {loadingGetSettings ? (
              <div className="loading">
                {currentLanguage.LoginLoadingData}
                <Spinner className="spinner" size={IconSize.XLarge} />
              </div>
            ) : loginTypeEnum === LogonTypeEnum.openId ? (
              <LoadingButton
                className="openIdButton"
                id={ids.Login.loginOpenIdButton}
                loading={loading}
                onClick={() => {
                  attemptOpenIdLogin(openId, errorMessage);
                }}
              >
                {currentLanguage.login}
              </LoadingButton>
            ) : (
              <LoginUsernamePasswordForm
                initialValues={initialValues}
                onSubmitUsernamePass={(values: LoginValues) => {
                  attemptLogin(values.username, values.password);
                }}
                showErrors={false}
                loading={loading}
                currentLanguage={currentLanguage}
              />
            )}
          </div>
        </div>
        <div style={{ gridRow: 3, height: '16px' }}></div>
      </div>
      {renderModal()}
    </div>
  );
};

export default Login;
