import './Calendar.scss';

import {
  Calendar,
  CalendarDayContents,
  CalendarSelectionType,
  HolidayStyle,
  IconSize,
  Icons,
} from '@pdcfrontendui/components';
import React, { memo, useMemo } from 'react';
import { a, easings, useTransition } from '@react-spring/web';

import { Holiday } from '../api/Calendar_api';
import classNames from 'classnames';
import { currentLanguage } from '../currentLanguage';

export type CalendarWrapperProps = {
  currentDate: Date;
  monthDay: Date;
  selectedDate: Date; // perhaps change to value, because it can be either a single date or an array
  onDayClick: (date: Date) => void;
  onMonthClick: (date: Date) => void;
  selectionType?: CalendarSelectionType;
  holidays?: Holiday[];
  largeLayout?: boolean;
  shouldShowCalendar: boolean;
  shiftStatuses: {
    actionRequired: string[];
    offered: string[];
  };
};

const transitionProps = {
  config: {
    duration: 400,
    easing: easings.easeInOutQuint,
  },
  from: { maxHeight: 0, opacity: 0 },
  enter: { maxHeight: 440, opacity: 1 },
  leave: { maxHeight: 0, opacity: 0 },
};

const CalendarWrapper = ({
  monthDay,
  currentDate,
  onDayClick,
  onMonthClick,
  selectionType = 'day',
  selectedDate,
  holidays,
  shiftStatuses,
  shouldShowCalendar,
  largeLayout = false,
}: CalendarWrapperProps) => {
  const calendarTransition = useTransition(shouldShowCalendar, {
    ...transitionProps,
  });
  const _holidays = useMemo(
    () => holidays?.map((holiday) => new Date(holiday.Date)),
    [holidays]
  );

  const dayContents = useMemo(() => {
    const contents: CalendarDayContents = {};
    const bottom: React.ReactNode[] = [];
    shiftStatuses.actionRequired.forEach((entry) => {
      bottom.push(
        <div className="bullets">
          <div className="Bullet alert">
            <Icons.ExclamationFill size={IconSize.XXXSmall} />
          </div>
        </div>
      );
      contents[entry] = {
        top: [],
        bottom,
      };
    });

    return contents;
  }, [shiftStatuses.actionRequired]);

  return calendarTransition(
    (style, show) =>
      show && (
        <a.div className="CalendarAnimating" style={style}>
          <Calendar
            className={classNames('pdc-calendar', { ['large']: largeLayout })}
            holidayStyle={HolidayStyle.red}
            activeDate={monthDay}
            currentDate={currentDate}
            selectedDate={selectedDate}
            isDesktop={largeLayout}
            onDayClick={onDayClick}
            onMonthClick={onMonthClick}
            dayContents={dayContents}
            selectionType={selectionType}
            holidays={_holidays}
            weekdayTranslations={currentLanguage.WeekdayInitials_Array}
          />
        </a.div>
      )
  );
};

export default memo(CalendarWrapper);

