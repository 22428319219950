import { useLayoutEffect } from 'react';
import { ROOT_ID } from '../constants';
import ids from '../testing/ids';

/**
 * Accessibility hook.
 * Hides the root element using the `aria-hidden` attribute. Use whenever a modal is open.
 * @param deps - If any of the deps are true, the root element will be hidden.
 */
export function useHideRoot(...deps: boolean[]) {
  const anyTrue = deps.some((dep) => dep);

  useLayoutEffect(() => {
    const root = document.getElementById(ROOT_ID);
    const editShiftModal = document.getElementById(ids.Modal.editShiftModal);
    if (anyTrue) {
      root?.setAttribute('aria-hidden', 'true');
      editShiftModal?.setAttribute('aria-hidden', 'true');
    } else {
      root?.removeAttribute('aria-hidden');
      editShiftModal?.removeAttribute('aria-hidden');
    }
  }, [anyTrue]);
}
