import React, { useCallback, useEffect } from 'react';
import { Header, IconSize, Icons } from '@pdcfrontendui/components';
import { EmployeeMap, ShiftMap, TeamMap } from './api/model';
import { localeFormat } from './util/dates';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  TeamViewMode,
  getSiteRoutes,
  useTeamId,
  useTeamRouteParams,
} from './routes';
import ErrorBoundaryContainer from './sharedComponents/ErrorBoundaryContainer';
import FindSubstituteViewContainer from './FindSubstituteView/FindSubstituteViewContainer';
import ListViewContainer from './ListView/ListViewContainer';
import ShiftViewContainer from './ShiftView/ShiftViewContainer';
import { currentLanguage } from './currentLanguage';
import { connect } from './rootReducer';
import { setShowingRuleViolationInfo } from './appActions';
import EditShiftForm from './ListView/CreateShift';
import { EDITED_SHIFT_ID } from './constants';
import {
  EditedShift,
  ShiftDraft,
  shiftDraftToTeamShift,
  isCreatedShift,
  isEditedShift,
  isNewlyCreatedShift,
} from './ListView/CreateShift/EditedShift';
import { TeamShift, TeamShiftDef } from './api/TeamPlan_api';
import {
  attemptEditShift,
  setConfirmDeleteModal,
  setShiftDraft,
} from './ShiftView/ShiftViewActions';
import { NO_TEAMS_LOADED } from './ChooseTeam/ChooseTeamReducer';
import { TeamShiftStatusEnum } from './api/enumLib_api';
import { resetSubstituteStore } from './FindSubstituteView/FindSubstituteViewActions';

type StateFromProps = {
  screensizeBig: boolean;
  employeeMap: Record<string, EmployeeMap>;
  shifts: Record<string, ShiftMap>;
  teams: TeamMap;
  currentDate: Date;
  shiftDraft: ShiftDraft | null;
  shiftDefMap: Record<string, TeamShiftDef>;
  editShiftLoading: boolean;
};

type DispatchFromProps = {
  setShiftDraft: (shift: ShiftDraft | null) => void;
  showRuleViolationInfo: () => void;
  editShift: (
    teamId: string,
    editedShift: EditedShift
  ) => Promise<TeamShift | undefined>;
  setConfirmDeleteModal: (shown: boolean) => void;
  resetSubstituteStore: () => void;
};

function Team({
  screensizeBig,
  employeeMap,
  shifts,
  showRuleViolationInfo,
  currentDate,
  shiftDraft,
  setShiftDraft,
  shiftDefMap,
  teams,
  editShift,
  editShiftLoading,
  setConfirmDeleteModal,
  resetSubstituteStore,
}: StateFromProps & DispatchFromProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const teamId = useTeamId();
  const { personId, shiftId = '', mode, tab, search } = useTeamRouteParams();

  const onEditOrCreate = useCallback(
    (updatedShiftDraft: ShiftDraft) => {
      // Flow for create shift + find substitute is very similar to edit shift + find substitute.
      if (isCreatedShift(updatedShiftDraft)) {
        setShiftDraft(updatedShiftDraft);
        navigate(
          getSiteRoutes().team(teamId, {
            tab,
            shiftId: EDITED_SHIFT_ID,
            mode: TeamViewMode.FindSubstitute,
          })
        );
      } else if (isEditedShift(updatedShiftDraft)) {
        // Edit + find substitute
        if (
          updatedShiftDraft.originalShift.status ===
          TeamShiftStatusEnum.actionRequired
        ) {
          setShiftDraft(updatedShiftDraft);
          navigate(
            getSiteRoutes().team(teamId, {
              tab,
              personId: updatedShiftDraft.originalShift.personId,
              shiftId: updatedShiftDraft.originalShift.id,
              mode: TeamViewMode.FindSubstitute,
            })
          );
        } else {
          void editShift(teamId, updatedShiftDraft).then((updatedShift) => {
            if (updatedShift) {
              setShiftDraft(null);
              navigate(
                getSiteRoutes().team(teamId, {
                  tab,
                  personId: updatedShiftDraft.originalShift.personId,
                  shiftId: updatedShift.id,
                })
              );
            }
          });
        }
      }
    },
    [editShift, navigate, setShiftDraft, teamId, tab]
  );

  useEffect(() => resetSubstituteStore, [resetSubstituteStore, shiftId]);

  // Reroute if state is not compatible with the route
  if (!teamId || (teams !== NO_TEAMS_LOADED && !teams[teamId])) {
    return <Navigate to={getSiteRoutes().teams} replace />;
  }
  const teamHasPerson = !!employeeMap[teamId]?.[personId ?? ''];
  const isEditingShift = shiftDraft && shiftId === EDITED_SHIFT_ID;
  const shift =
    isEditingShift && (isCreatedShift(shiftDraft) || isEditedShift(shiftDraft))
      ? shiftDraftToTeamShift(shiftDraft)
      : shifts[teamId]?.[shiftId];
  // This handles cases where we have been through the flow of find substitute and successfully found a substitute, but then try to navigate back.
  if (
    mode === TeamViewMode.FindSubstitute &&
    shift &&
    shift.status !== TeamShiftStatusEnum.actionRequired &&
    shift.status !== TeamShiftStatusEnum.substitute
  ) {
    return (
      <Navigate
        to={getSiteRoutes().team(teamId, {
          personId: shift.personId,
          shiftId,
          tab,
        })}
        replace
      />
    );
  }

  const findingSubstitute = mode === TeamViewMode.FindSubstitute;
  // If we are finding a substitute, and we have selected a possible employee, then the personId of selected shift does not match the personId in the url
  const assigningSubstitute =
    findingSubstitute &&
    !!shift &&
    personId !== undefined &&
    shift.personId !== personId;

  const goBack = () => {
    if (findingSubstitute) {
      if (assigningSubstitute) {
        // We have selected a substitute, and by going back we want to be at the list of substitutes
        navigate(
          getSiteRoutes().team(teamId, {
            tab,
            search,
            personId: shift.personId,
            shiftId,
            mode: TeamViewMode.FindSubstitute,
          })
        );
      } else {
        // We are at the list of substitutes, and expect to go back to the shift view
        navigate(
          getSiteRoutes().team(teamId, {
            tab,
            search,
            personId: assigningSubstitute && shift ? shift.personId : personId,
            shiftId,
            // If shiftDraft exists, then we are currently doing edit and assign substitute, so we return to the edit form
            mode: shiftDraft ? TeamViewMode.CreateOrEdit : undefined,
          })
        );
      }
    } else {
      if (screensizeBig) {
        navigate(
          getSiteRoutes().team(teamId, { tab, search, personId, shiftId })
        );
      } else {
        // From selected shift view (mobile), go back to list view
        navigate(getSiteRoutes().team(teamId, { tab, search }));
      }
    }
  };

  const isShiftSingleView =
    !screensizeBig &&
    (teamHasPerson ||
      (mode === TeamViewMode.FindSubstitute && shiftId === EDITED_SHIFT_ID)) &&
    !!shift;
  const listViewClassName = screensizeBig ? 'splitpane-left' : 'splitpane-one';
  const shiftViewClassName = isShiftSingleView
    ? 'splitpane-one'
    : 'splitpane-right';
  const shiftViewTitle = shift
    ? localeFormat(shift.period.from, false, currentLanguage.languageCode)
    : '';

  const createShift = (
    <EditShiftForm
      show={mode === TeamViewMode.CreateOrEdit && !!shiftDraft}
      isMobile={!screensizeBig}
      teamId={teamId}
      goBack={goBack}
      today={currentDate}
      className={screensizeBig ? 'splitpane-right' : ''}
      shiftDraft={shiftDraft}
      setShiftDraft={onEditOrCreate}
      shiftDefMap={shiftDefMap}
      title={
        isCreatedShift(shiftDraft) || isNewlyCreatedShift(shiftDraft)
          ? currentLanguage.CreateNew
          : currentLanguage.Edit
      }
      shiftMap={shifts[teamId] ?? null}
      editShiftLoading={editShiftLoading}
      openConfirmDeleteModal={() => setConfirmDeleteModal(true)}
    />
  );
  return (
    <div className="splitpane">
      {!isShiftSingleView && (
        <div className={listViewClassName}>
          <ErrorBoundaryContainer>
            <ListViewContainer
              key={teamId} // Run cleanup in ListView when changing team
              teamId={teamId}
            />
          </ErrorBoundaryContainer>
        </div>
      )}
      {isShiftSingleView || screensizeBig ? (
        <div className={shiftViewClassName}>
          <div
            className="container"
            aria-hidden={mode === TeamViewMode.CreateOrEdit}
          >
            <Header centerize>
              {screensizeBig && <Header.Spacer />}
              {(isShiftSingleView || findingSubstitute) && (
                <Header.Left>
                  <Icons.ChevronLeft
                    size={IconSize.XSmall}
                    onClick={goBack}
                    ariaLabel={currentLanguage.Back}
                  />
                </Header.Left>
              )}
              <Header.Title>{shiftViewTitle}</Header.Title>
              {findingSubstitute && !assigningSubstitute && (
                <Header.Right>
                  <Icons.Info
                    size={IconSize.XSmall}
                    onClick={showRuleViolationInfo}
                  />
                </Header.Right>
              )}
            </Header>
            {!!shift?.personId && shiftId && (
              <ErrorBoundaryContainer>
                {findingSubstitute && !assigningSubstitute ? (
                  <FindSubstituteViewContainer shiftId={shiftId} />
                ) : (
                  <ShiftViewContainer
                    key={personId ?? shift.personId}
                    teamId={teamId}
                    personId={personId ?? shift.personId}
                    shiftId={shiftId}
                    location={location}
                  />
                )}
              </ErrorBoundaryContainer>
            )}
          </div>
          {createShift}
        </div>
      ) : (
        createShift
      )}
    </div>
  );
}

export const TeamContainer = connect<StateFromProps, DispatchFromProps>(
  (store) => ({
    teams: store.chooseTeamReducer.teams,
    screensizeBig: store.appReducer.screensizeBig,
    employeeMap: store.listViewReducer.employeeMap,
    shifts: store.listViewReducer.shiftMap,
    currentDate: store.appReducer.globalSettings.useDateTime,
    shiftDraft: store.shiftViewReducer.shiftDraft,
    shiftDefMap: store.appReducer.shiftDefMap,
    editShiftLoading: store.shiftViewReducer.editShiftLoading,
  }),
  (dispatch) => ({
    showRuleViolationInfo: () => {
      dispatch(setShowingRuleViolationInfo(true));
    },
    setShiftDraft: (shift) => {
      dispatch(setShiftDraft(shift));
    },
    editShift: async (teamId, editedShift) => {
      return await dispatch(attemptEditShift(teamId, editedShift));
    },
    setConfirmDeleteModal: (shown) => {
      dispatch(setConfirmDeleteModal(shown));
    },
    resetSubstituteStore: () => {
      dispatch(resetSubstituteStore());
    },
  })
)(Team);
