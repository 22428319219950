import EmptyState from './EmptyState/EmptyState';
import React from 'react';
import { currentLanguage } from '../currentLanguage';
import { localeFormatLongDay } from '../util/dates';

function NoChangesShiftsImage() {
  return (
    <svg
      width="223px"
      height="203px"
      viewBox="0 0 223 203"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.9786, 0)"></g>
        <g transform="translate(0.9786, 0)">
          <rect x="11.0213608" y="0" width="203" height="203"></rect>
          <path
            d="M165.232813,56.2670374 C183.922128,66.3768575 206.985537,71.24906 210.69689,82.8205408 C214.408244,94.5138267 198.767541,113.150001 193.067963,131.42076 C187.368384,149.691519 191.742479,167.596863 184.452321,177.341268 C177.162163,187.207478 158.207752,189.034554 142.434501,187.207478 C126.661249,185.502207 113.93661,180.26459 95.114747,181.117225 C76.4254325,181.848056 51.6388949,188.669139 34.2750637,182.700691 C17.0437809,176.732243 7.10265613,157.852459 10.4163644,140.79975 C13.7300726,123.868846 30.2986138,108.521409 38.7817069,89.3980141 C47.3973483,70.2746195 48.06009,47.2534629 59.8568913,34.2203213 C71.6536927,21.1871798 94.717102,18.0202482 113.406417,24.2323063 C132.095731,30.4443644 146.410951,46.0354123 165.232813,56.2670374 Z"
            fill="#DFF5F5"
            fillRule="nonzero"
            transform="translate(110.5214, 104.5) rotate(-7.3085) translate(-110.5214, -104.5)"
          ></path>
          <ellipse
            fill="#387D7D"
            cx="112.042292"
            cy="118.95469"
            rx="5.6224479"
            ry="5.73178516"
          ></ellipse>
          <path
            d="M170.64064,66.2490861 L170.64064,62.1175977 L178.001363,53.4993901 C179.209591,52.0939118 180.103453,51.0977212 180.682948,50.5030957 C180.079213,50.5417078 179.283827,50.564875 178.297549,50.5803198 L171.362547,50.6189319 L171.362547,46.2248816 L187.599771,46.2248816 L187.599771,49.977972 L180.091333,58.8046846 L177.446866,61.7237549 C178.889165,61.6388084 179.783026,61.5924739 180.128451,61.5924739 L188.173206,61.5924739 L188.173206,66.2490861 L170.64064,66.2490861 Z"
            fill="#387D7D"
            fillRule="nonzero"
          ></path>
          <path
            d="M148.844333,42.0045313 L148.844333,35.4490419 L159.870358,21.7744134 C161.680228,19.5443216 163.019192,17.9636522 163.887249,17.0201518 C162.982881,17.0814181 161.79143,17.1181779 160.314031,17.1426844 L149.925716,17.2039506 L149.925716,10.2318506 L174.248331,10.2318506 L174.248331,16.1869308 L163.001036,30.192397 L159.039746,34.824126 C161.200244,34.6893403 162.539207,34.6158208 163.056637,34.6158208 L175.10731,34.6158208 L175.10731,42.0045313 L148.844333,42.0045313 Z"
            fill="#387D7D"
            fillRule="nonzero"
          ></path>
          <path
            d="M101.721467,96.1074009 C100.298866,99.8831135 96.7077661,102.566045 92.5056394,102.566045 C88.3011672,102.566045 84.7077214,99.8783311 83.2874659,96.0990317"
            stroke="#387D7D"
            strokeWidth="2.33231503"
          ></path>
          <path
            d="M140.797117,96.1074009 C139.374516,99.8831135 135.783416,102.566045 131.581289,102.566045 C127.376817,102.566045 123.783371,99.8783311 122.363116,96.0990317"
            stroke="#387D7D"
            strokeWidth="2.33231503"
          ></path>
          <path
            d="M110.945726,47.2109761 C111.200915,47.1916194 111.463489,47.2696142 111.67296,47.4495484 L131.886772,70.9640177 C133.00417,72.2648431 134.410227,73.2864029 135.99268,73.9471344 L164.467899,85.8365665 C164.722721,85.9429639 164.910293,86.1425756 165.00756,86.3792935 C165.104827,86.6160113 165.111789,86.8898353 165.005392,87.1446573 L148.888214,113.635506 C147.99635,115.100191 147.459285,116.75311 147.319897,118.46229 L144.811701,149.217869 C144.789255,149.493097 144.657376,149.733172 144.462301,149.898828 C144.267227,150.064485 144.008956,150.155723 143.733727,150.133277 L113.558951,142.991052 C111.890351,142.595453 110.15237,142.595453 108.48377,142.991052 L78.4584017,150.109613 C78.1897076,150.173316 77.92063,150.122079 77.7028,149.987743 C77.48497,149.853406 77.3183877,149.63597 77.2546844,149.367276 L74.7228251,118.46229 C74.5834371,116.75311 74.0463713,115.100191 73.1545078,113.635506 L57.106005,87.2794396 C56.9623886,87.0435818 56.9279685,86.7718405 56.9884171,86.5231595 C57.0488656,86.2744786 57.2041828,86.048858 57.4400406,85.9052416 L86.0500414,73.9471344 C87.6324948,73.2864029 89.0385513,72.2648431 90.1559492,70.9640177 L110.262798,47.5565122 C110.442732,47.3470408 110.690537,47.2303327 110.945726,47.2109761 Z"
            stroke="#387D7D"
            strokeWidth="10"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default function NoChangesShifts({
  className,
  from,
  to,
}: {
  className?: string;
  from: Date;
  to: Date;
}) {
  return (
    <EmptyState
      className={className}
      image={NoChangesShiftsImage}
      heading={currentLanguage.NoChanges}
      description={currentLanguage.NoChangesHaveBeenMade_2(
        localeFormatLongDay(from, false, currentLanguage.languageCode),
        localeFormatLongDay(to, false, currentLanguage.languageCode)
      )}
    />
  );
}
