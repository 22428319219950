import {
  Button,
  ButtonStyle,
  InlineNotification,
  LoadingButton,
  MobileSlideIn,
  NotificationType,
  ReadOnlyField,
  SelectInput,
  TimeInput,
} from '@pdcfrontendui/components';
import React, { useMemo, useRef, useState } from 'react';
import { dateFormats, dayKey } from '@pdcfrontendui/utils';
import { localStorageSet, useLocalStorage } from '../../util/localStorage';

import {
  CreatedShift,
  UpdateShiftNotifications,
  EditedShift,
  ShiftDraft,
  ShiftDraftType,
  isCreatedShift,
  isEditedShift,
  calculateModifiedDutylines,
  isEditedShiftWithKnownDef,
  isNewlyCreatedShift,
  updateShiftDraftDutylines,
} from './EditedShift';
import Header from '@pdcfrontendui/components/Header';
import Icons from '@pdcfrontendui/components/Icons';
import { SearchableSelect } from '@pdcfrontendui/components';
import { LocalStorageKey } from '../../util/LocalStorageKey';
import { TeamActivity, TeamShiftDef } from '../../api/TeamPlan_api';
import classNames from 'classnames';
import { currentLanguage } from '../../currentLanguage';
import scss from './EditShiftForm.module.scss';
import getApi from '../../getApi';
import ButtonContainer, {
  Align,
} from '@pdcfrontendui/components/ButtonContainer';
import { useEditShift } from './useEditShift';
import { ShiftMap } from '../../api/model';
import { ConfirmEditShiftDialog } from './ConfirmEditShiftDialog';
import { TeamShiftStatusEnum } from '../../api/enumLib_api';
import ids from '../../testing/ids';
import { formatActivityOrDutylineTime } from '../../util/dates';
import { ModalNotification } from '../../components/ModalNotification';

export default function EditShiftForm({
  isMobile,
  show,
  goBack,
  today,
  className,
  title,
  setShiftDraft,
  shiftDraft,
  shiftDefMap,
  shiftMap,
  teamId,
  editShiftLoading,
  openConfirmDeleteModal,
}: {
  isMobile: boolean;
  show: boolean;
  goBack: () => void;
  today: Date;
  className?: string;
  title: string;
  setShiftDraft: (shiftDraft: ShiftDraft) => void;
  shiftDraft: ShiftDraft | null;
  shiftDefMap: Record<string, TeamShiftDef>;
  shiftMap: ShiftMap | null;
  teamId: string;
  editShiftLoading: boolean;
  openConfirmDeleteModal: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const toHoursRef = useRef<HTMLInputElement>(null!);

  const [confirmData, setConfirmData] = useState<{
    editedShift: EditedShift;
    deletedActivities: TeamActivity[] | null;
    fetchActivitiesDeletedFailed: boolean;
  } | null>(null);

  const shiftTypeSuggestionsMap = useLocalStorage(
    LocalStorageKey.shiftTypeSuggestions
  );

  const originalEmployee = isEditedShift(shiftDraft)
    ? shiftDraft.originalEmployee
    : null;

  /**
   * List of other planned shifts for the original employee (excluding the edited one).
   * Used for overlapping shift validation.
   */
  const otherPlannedEmployeeShifts = useMemo(() => {
    if (originalEmployee && isEditedShift(shiftDraft)) {
      return Object.values(shiftMap ?? {}).filter(
        (shift) =>
          shift.status === TeamShiftStatusEnum.planned &&
          shift.personId === originalEmployee.id &&
          shift.id !== shiftDraft.originalShift.id
      );
    }
    return [];
  }, [shiftDraft, shiftMap, originalEmployee]);

  const editShiftNotifications = useMemo((): UpdateShiftNotifications => {
    const draft = confirmData?.editedShift ?? shiftDraft;
    if (draft?.type === ShiftDraftType.Edited) {
      return {
        modifiedDutylines: isEditedShiftWithKnownDef(draft)
          ? calculateModifiedDutylines(
              draft.originalShift.dutyLinesOriginal.length
                ? draft.originalShift.dutyLinesOriginal
                : draft.originalShift.dutyLines,
              // Do a lookup, since type might have changed.
              shiftDefMap[draft.originalShift.shiftDefId] ?? draft.def
            )
          : null,
        activitiesDeleted: confirmData?.deletedActivities ?? null,
        deviatingWorkplace: draft.originalShift.deviatingPayerUiLabel,
        unknownShiftDef: !draft.def ? draft.originalShift.label : null,
        fetchActivitiesDeletedFailed: false,
      };
    }
    return {
      deviatingWorkplace: null,
      activitiesDeleted: null,
      modifiedDutylines: null,
      unknownShiftDef: null,
      fetchActivitiesDeletedFailed: false,
    };
  }, [
    shiftDraft,
    confirmData?.deletedActivities,
    confirmData?.editedShift,
    shiftDefMap,
  ]);

  const onSubmit = async (updatedDraft: CreatedShift | EditedShift) => {
    // If we don't have the shift def, we don't know how to update the shift.
    if (
      isCreatedShift(updatedDraft) ||
      isEditedShiftWithKnownDef(updatedDraft)
    ) {
      try {
        updatedDraft = await updateShiftDraftDutylines(updatedDraft);
      } catch (e) {
        return; // Assume that a rejected promise means that the action is impossible
      }
    }
    if (isCreatedShift(updatedDraft)) {
      setShiftDraft(updatedDraft);
    } else {
      let fetchActivitiesDeletedFailed = false;
      const currentActivities = updatedDraft.originalShift.activities;
      // If API call fails, use the current activities as a fallback, and note that they might be deleted.
      // We don't ever expect it to fail.
      const removedRegistrationInfo = currentActivities.length
        ? await getApi()
            .getRemovedRegistrationInfo(
              teamId,
              updatedDraft.originalShift.id,
              false,
              {
                from: updatedDraft.from,
                to: updatedDraft.to,
              }
            )
            .catch(() => {
              fetchActivitiesDeletedFailed = true;
              return currentActivities;
            })
        : null;
      if (removedRegistrationInfo?.length) {
        setConfirmData({
          editedShift: updatedDraft,
          deletedActivities: removedRegistrationInfo,
          fetchActivitiesDeletedFailed,
        });
      } else if (
        editShiftNotifications.unknownShiftDef ||
        editShiftNotifications.deviatingWorkplace ||
        (updatedDraft.originalShift.dutyLines.length > 1 &&
          !!editShiftNotifications.modifiedDutylines?.dutylines.length) ||
        editShiftNotifications.modifiedDutylines?.typeHasChanged
      ) {
        setConfirmData({
          editedShift: updatedDraft,
          deletedActivities: null,
          fetchActivitiesDeletedFailed: false,
        });
      } else {
        setShiftDraft(updatedDraft);
      }
    }
  };

  const {
    fromHours,
    fromMinutes,
    setFromHours,
    setFromMinutes,
    setToHours,
    setToMinutes,
    shiftDefId,
    toHours,
    toMinutes,
    setExtendBy,
    isOverlapping,
    startDate,
    endDate,
    isValid,
    setShiftTypeKey,
    submitForm,
    setStartDate,
    extendByOptions,
    shiftOptions,
    shouldShowEndDateSelect,
    showEndDateSelect,
    startDateOptions,
    timeEditable,
    canSelectEndDate,
    extendNumDays,
    isSubmitting,
    fromHasError,
    toHasError,
    errorMessage,
    typeEditable,
  } = useEditShift(
    today,
    shiftDefMap,
    shiftDraft,
    onSubmit,
    teamId,
    shiftTypeSuggestionsMap,
    (map) => localStorageSet(LocalStorageKey.shiftTypeSuggestions, map),
    otherPlannedEmployeeShifts,
    originalEmployee
  );

  const loading = isSubmitting || editShiftLoading;

  const submitDisabled =
    !isValid || !fromHours || !fromMinutes || !toHours || !toMinutes;

  const backText =
    typeEditable || !isMobile ? currentLanguage.Cancel : undefined;
  const submitText =
    !shiftDraft || isCreatedShift(shiftDraft) || isNewlyCreatedShift(shiftDraft)
      ? currentLanguage.Next
      : isEditedShift(shiftDraft) && !typeEditable
      ? currentLanguage.findSubstitute
      : currentLanguage.Save;

  // Only show as required if a new shift is being created.
  // Logic being that the fields would be prefilled if editing an existing shift.
  const required =
    isCreatedShift(shiftDraft) || isNewlyCreatedShift(shiftDraft);

  return (
    <>
      <MobileSlideIn
        isMobile={isMobile}
        className={classNames(scss.comp, className)}
        ref={ref}
        bottom={50}
        id={ids.Modal.editShiftModal}
        absolute
      >
        {show && (
          <>
            <Header centerize className={scss.header}>
              {!isMobile && <Header.Spacer />}
              {isMobile && (
                <Header.Left>
                  {backText ? (
                    <Button onClick={goBack} variant={ButtonStyle.GhostOnDark}>
                      {backText}
                    </Button>
                  ) : (
                    <Icons.ChevronLeft onClick={goBack} />
                  )}
                </Header.Left>
              )}
              <Header.Title bold={isMobile}>{title}</Header.Title>
              {isMobile && (
                <Header.Right>
                  <LoadingButton
                    loading={loading}
                    onClick={() => void submitForm()}
                    variant={ButtonStyle.GhostOnDark}
                    disabled={submitDisabled}
                  >
                    {submitText}
                  </LoadingButton>
                </Header.Right>
              )}
            </Header>
            <div className={scss.body} ref={bodyRef}>
              {!!editShiftNotifications.modifiedDutylines?.dutylines.length &&
                isEditedShiftWithKnownDef(shiftDraft) && (
                  <ModalNotification
                    type={
                      editShiftNotifications.modifiedDutylines.typeHasChanged
                        ? NotificationType.Caution
                        : NotificationType.Info
                    }
                    className={scss.notification}
                    title={
                      editShiftNotifications.modifiedDutylines.typeHasChanged
                        ? currentLanguage.ThisShiftHasBeenModified
                        : currentLanguage.ShiftHoursHaveBeenModified
                    }
                    body={
                      editShiftNotifications.modifiedDutylines.typeHasChanged
                        ? currentLanguage.PleaseNoteTheFollowingDuties
                        : currentLanguage.StartOrEndTimeHasBeenChanged
                    }
                    items={
                      editShiftNotifications.modifiedDutylines.typeHasChanged
                        ? editShiftNotifications.modifiedDutylines.dutylines.map(
                            (duty) =>
                              `${formatActivityOrDutylineTime(duty)} ${
                                duty.salarysort
                              }: ${duty.salarysortText}`
                          )
                        : undefined
                    }
                  />
                )}
              {editShiftNotifications.deviatingWorkplace && (
                <ModalNotification
                  type={NotificationType.Caution}
                  className={scss.notification}
                  title={currentLanguage.DeviatingWorkplace}
                  body={currentLanguage.DeviatingWorkplacePleaseNote_1(
                    editShiftNotifications.deviatingWorkplace
                  )}
                />
              )}
              {editShiftNotifications.unknownShiftDef && (
                <ModalNotification
                  type={NotificationType.Info}
                  className={scss.notification}
                  title={currentLanguage.ShiftTypeNotAvailable}
                  body={currentLanguage.ShiftTypeNotAvailableExplanation}
                />
              )}
              <div
                className={classNames(
                  scss.form,
                  !typeEditable && scss.bordered
                )}
              >
                {errorMessage &&
                  (typeof errorMessage === 'string' ? (
                    <InlineNotification
                      type={NotificationType.Error}
                      className={scss.error}
                    >
                      {errorMessage}
                    </InlineNotification>
                  ) : (
                    <ModalNotification
                      type={NotificationType.Error}
                      body={errorMessage.title}
                      items={errorMessage.items}
                    />
                  ))}
                {!typeEditable && originalEmployee && (
                  <div
                    className={scss.originalEmployee}
                  >{`${currentLanguage.OriginalBelongingTo} ${originalEmployee.name}`}</div>
                )}
                <SelectInput
                  required={required}
                  label={currentLanguage.Date}
                  options={startDateOptions}
                  value={dayKey(startDate)}
                  onChange={(e) => {
                    void setStartDate(new Date(e.target.value));
                  }}
                />
                {isEditedShift(shiftDraft) &&
                shiftDraft.originalShift.status ===
                  TeamShiftStatusEnum.actionRequired ? (
                  <ReadOnlyField
                    label={currentLanguage.Type}
                    value={shiftDraft.originalShift.label}
                    ghost
                  />
                ) : (
                  <SearchableSelect
                    containedIn={bodyRef}
                    required={required}
                    label={currentLanguage.Type}
                    options={shiftOptions}
                    value={shiftDefId}
                    noSearchResultsText={currentLanguage.NoShiftMatch}
                    placeholder={currentLanguage.ChoosePredefined}
                    expandAriaLabel={currentLanguage.SelectShiftType}
                    onChange={(key) => void setShiftTypeKey(key)}
                    disabled={!typeEditable}
                  />
                )}
                <div className={scss.fromTo}>
                  <TimeInput
                    required={required}
                    hours={fromHours}
                    label={currentLanguage.From}
                    minutes={fromMinutes}
                    onHoursChange={(value) => void setFromHours(value)}
                    onMinutesChange={(value) => void setFromMinutes(value)}
                    focusOnComplete={toHoursRef}
                    disabled={!timeEditable}
                    ghost={!timeEditable}
                    appearDisabled={false}
                    hasError={fromHasError}
                    hoursLabel={currentLanguage.FromHours}
                    minutesLabel={currentLanguage.FromMinutes}
                  />
                  <TimeInput
                    required={required}
                    hours={toHours}
                    label={currentLanguage.To}
                    minutes={toMinutes}
                    onHoursChange={(value) => void setToHours(value)}
                    onMinutesChange={(value) => void setToMinutes(value)}
                    hoursRef={toHoursRef}
                    disabled={!timeEditable}
                    ghost={!timeEditable}
                    appearDisabled={false}
                    hasError={toHasError}
                    hoursLabel={currentLanguage.ToHours}
                    minutesLabel={currentLanguage.ToMinutes}
                  />
                  {canSelectEndDate ? (
                    !shouldShowEndDateSelect ? (
                      <Button
                        className={scss.showEndDate}
                        onClick={showEndDateSelect}
                        variant={ButtonStyle.Ghost}
                      >
                        {currentLanguage.AddEndDate}
                      </Button>
                    ) : (
                      <SelectInput
                        required={required}
                        label={currentLanguage.EndDate}
                        className={scss.selectEndDate}
                        options={extendByOptions}
                        value={extendNumDays.toString()}
                        onChange={(e) => void setExtendBy(e.target.value)}
                        hasError={toHasError}
                      />
                    )
                  ) : (
                    isOverlapping && (
                      <ReadOnlyField
                        value={dateFormats.$31DOT_okt(endDate)}
                        label={currentLanguage.EndDate}
                        ghost
                      />
                    )
                  )}
                </div>
                {/* Only for existing shifts.
                If status is `actionRequired` deleting corresponds to `accept without substitute` which has a button elsewhere. */}
                {isEditedShift(shiftDraft) &&
                  shiftDraft.originalShift.status !==
                    TeamShiftStatusEnum.actionRequired && (
                    <Button
                      variant={ButtonStyle.Ghost}
                      onClick={openConfirmDeleteModal}
                      danger
                      icon={Icons.Delete}
                    >
                      {currentLanguage.Delete}
                    </Button>
                  )}
              </div>
              {!isMobile && (
                <ButtonContainer className={scss.buttons} align={Align.Right}>
                  <Button onClick={goBack} variant={ButtonStyle.Secondary}>
                    {backText}
                  </Button>
                  <LoadingButton
                    loading={loading}
                    onClick={() => void submitForm()}
                    variant={ButtonStyle.Primary}
                    disabled={submitDisabled}
                  >
                    {submitText}
                  </LoadingButton>
                </ButtonContainer>
              )}
            </div>
          </>
        )}
      </MobileSlideIn>
      <ConfirmEditShiftDialog
        loading={editShiftLoading}
        shown={!!confirmData}
        cancel={() => setConfirmData(null)}
        notifications={editShiftNotifications}
        save={() => {
          if (confirmData) {
            setShiftDraft(confirmData.editedShift);
            setConfirmData(null);
          }
        }}
        shiftDefChanged={
          isEditedShift(shiftDraft) &&
          shiftDefId !== shiftDraft.originalShift.shiftDefId
        }
      />
    </>
  );
}
