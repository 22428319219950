import {
  localStorageGet,
  localStorageRemove,
  localStorageSet,
} from '../util/localStorage';

import { AppAction } from '../appActions';
import { ChooseTeamAction } from '../ChooseTeam/ChooseTeamAction';
import CryptoJS from 'crypto-js';
import { FindSubstituteViewAction } from '../FindSubstituteView/FindSubstituteViewActions';
import { ListViewAction } from '../ListView/ListViewActions';
import { LocalStorageKey } from '../util/LocalStorageKey';
import { LoginAction } from '../Login/LoginAction';
import { Middleware } from 'redux';
import { SettingAction } from '../Settings/SettingsActions';
import { ShiftViewAction } from '../ShiftView/ShiftViewActions';
import { ThunkDispatch } from '../types';
import { TrackingObject } from '../api/Tracking_api';
import getApi from '../getApi';
import { getCurrentSiteRoute } from '../util/router';
import platform from 'platform';
import { ActionType } from '../ActionType';

export const trackingMiddleware: Middleware =
  (_store) =>
  (next: ThunkDispatch) =>
  (
    action:
      | AppAction
      | LoginAction
      | SettingAction
      | ListViewAction
      | ChooseTeamAction
      | ShiftViewAction
      | FindSubstituteViewAction
  ) => {
    trackAction(action);
    return next(action);
  };

function trackAction(
  action:
    | AppAction
    | LoginAction
    | SettingAction
    | ListViewAction
    | ChooseTeamAction
    | ShiftViewAction
    | FindSubstituteViewAction
) {
  const currentTrackingObj = getCurrentTrackingObj();
  calculateTrackingObj(currentTrackingObj, action, (err, newTrackingObj) => {
    if (!err) {
      saveTrackingObj(newTrackingObj);
    }
  });
}

// TODO: This method should return a promise instead of using a callback
export function calculateTrackingObj(
  trackingObj: TrackingObject | null,
  action:
    | AppAction
    | LoginAction
    | SettingAction
    | ListViewAction
    | ChooseTeamAction
    | ShiftViewAction
    | FindSubstituteViewAction,
  cb: (err: unknown, trackingObj: TrackingObject | null) => void
): void {
  switch (action.type) {
    case 'LOGOUT':
      if (trackingObj) {
        sendTrackingData(trackingObj)
          .then(() => {
            localStorageRemove(LocalStorageKey.userTracking);
            cb(null, null);
          })
          .catch((err) => {
            // The tracking object is removed from localStorage to avoid repeated
            // failure in case the error is caused by invalid data.
            localStorageRemove(LocalStorageKey.userTracking);
            cb(err, trackingObj);
          });
      }
      break;
    case 'LOGIN_SUCCESS':
      if (trackingObj) {
        sendTrackingData(trackingObj)
          .then(() => {
            cb(null, getNewTrackingObj(action.token));
          })
          .catch((err) => {
            cb(err, getNewTrackingObj(action.token));
          });
      } else {
        cb(null, getNewTrackingObj(action.token));
      }
      break;
    case ActionType.LOCATION_CHANGE:
      if (trackingObj) {
        trackingObj.navigations.push({
          viewName:
            getCurrentSiteRoute(action.payload.pathname) || 'defaultView',
          activities: [],
          timeStamp: new Date(),
        });
      }
      cb(null, trackingObj);
      break;
    default:
      if (trackingObj) {
        let auxDate = null;
        if (action.type === 'CHANGE_DATE') {
          auxDate = action.date && action.date;
        } else if (
          action.type ===
          ('CHANGE_PERIOD' || 'ADD_LOADING_PERIOD' || 'REMOVE_LOADING_PERIOD')
        ) {
          auxDate = action.period && action.period.from;
        }
        trackingObj.navigations[
          trackingObj.navigations.length - 1
        ]?.activities.push({
          name: action.type,
          timeStamp: new Date(),
          auxDate,
        });
      }
      cb(null, trackingObj);
  }
}

function getInitialTrackingObj(): TrackingObject {
  return {
    userHash: '',
    sessionStart: new Date(),
    viewPort: { height: 0, width: 0 },
    device: '',
    navigations: [],
  };
}

function getCurrentTrackingObj(): TrackingObject {
  return localStorageGet(LocalStorageKey.userTracking) as TrackingObject;
}

export function getNewTrackingObj(userToken: string): TrackingObject {
  let trackingObj = getInitialTrackingObj();
  trackingObj.sessionStart = new Date();
  trackingObj.device = platform.description ?? 'Unknown device';
  trackingObj.userHash = userToken && CryptoJS.SHA256(userToken).toString();
  trackingObj.navigations.push({
    viewName: 'INITIAL',
    activities: [],
    timeStamp: new Date(),
  });
  return trackingObj;
}

function saveTrackingObj(trackingObj: TrackingObject | null) {
  if (trackingObj) {
    localStorageSet(LocalStorageKey.userTracking, trackingObj);
  }
}

function sendTrackingData(trackingObj: TrackingObject) {
  trackingObj.viewPort = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  return getApi().reportTracking(trackingObj);
}

