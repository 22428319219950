//
// The methods in this file create model objects from the Data objects that
// are returned from the server using the classes in generatedApi.ts.
//

import * as api from './TeamPlan_api';

import { ClientInfo } from './NotificationLog_api';

export type GetVersionModel = api.SystemInformation & {
  appVersion: string;
  serviceUrl: string;
};

export type TeamMap = Record<string, api.Team>;

export type EmployeeMap = Record<string, api.Person>;

export type ShiftMap = Record<string, api.TeamShift>;

export type DayShiftMap = {
  planned: Record<string, api.TeamShift[]>;
  pending: Record<string, api.TeamShift[]>;
  changes: Record<string, api.TeamShift[]>;
  hasMessageLookup: Record<string, true>;
  hasUnreadLookup: Record<string, true>;
};

export type NotificationLogObj = {
  groupOn: string;
  exceptionName: string;
  exceptionMsg: string;
  userComment: string | null;
  clientInfo: ClientInfo;
};

export function toIdMap<T extends { id: string | number }>(list: T[]) {
  const map: Record<string, T> = {};
  for (const item of list) {
    map[item.id] = item;
  }
  return map;
}

export function getTeamRoster(roster: api.TeamRoster) {
  return {
    employeeMap: toIdMap(roster.personList),
    shiftMap: toIdMap(roster.teamShiftList),
    teamId: roster.teamId,
    recordType: roster.recordType,
  };
}

export const getCallInData = (callInData: api.CallIn[]) => {
  const orderedCallInData = callInData.sort(
    (a, b) => Number(a.priority) - Number(b.priority)
  );
  return orderedCallInData;
};
