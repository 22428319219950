import { ApiError, ValidationError } from '@pdcfrontendui/staffplan';

/**
 * We use this because, by default, you may not index into an object with a string. This is a TypeScript limitation.
 * We could get around this by inferring the type using generics and then using the keyof operator, but that would be a lot of work, and would kind of go against the idea of validating.
 */
export type StringObj = Record<string, unknown>;

type EnumObj = Record<string | number, string | number>;

export function nullishError(typeName: string, key: string) {
	throw new Error(`Nothing provided for the ${typeName} property ${key}`);
}

function assertError(expected: string, value: unknown, prop?: string) {
	throw new ApiError(
		`Expected ${expected}, got ${typeof value}${
			prop ? ` in property "${prop}"` : ''
		}}.`
	);
}

function assertString(value: unknown, prop?: string): asserts value is string {
	if (typeof value !== 'string') {
		assertError('string', value, prop);
	}
}

function assertNumber(value: unknown, prop?: string): asserts value is number {
	if (typeof value !== 'number') {
		assertError('number', value, prop);
	}
}

function assertStringOrNumber(
	value: unknown,
	prop?: string
): asserts value is string | number {
	if (typeof value !== 'string' && typeof value !== 'number') {
		assertError('string or number', value, prop);
	}
}

function assertBoolean(
	value: unknown,
	prop?: string
): asserts value is boolean {
	if (typeof value !== 'boolean') {
		assertError('boolean', value, prop);
	}
}

export function assertObject(
	value: unknown,
	prop?: string
): asserts value is StringObj {
	if (typeof value !== 'object' || value === null || Array.isArray(value)) {
		assertError('object', value, prop);
	}
}

function assertIsInEnumValues<T extends string | number>(
	value: unknown,
	enumValues: T[]
): asserts value is T {
	if (!enumValues.includes(value as T)) {
		throw new ApiError(
			`Invalid value "${String(
				value
			)}" for enum. Legal values are ${enumValues
				.map(String)
				.join(', ')}.`
		);
	}
}

export function isNullish(value: unknown): value is null | undefined {
	return value === null || value === undefined;
}

// Basic validators
export function getNullable<T>(
	jsonObj: StringObj,
	prop: string,
	getter: (jsonObj: StringObj, prop: string) => T
): T | null {
	const value: unknown = jsonObj[prop];
	if (isNullish(value)) {
		return null;
	}
	return getter(jsonObj, prop);
}

export function getEnumFromJSON<T extends EnumObj>(
	value: unknown,
	enumObj: T
): Exclude<T[keyof T], string> {
	assertStringOrNumber(value);
	assertIsInEnumValues(value, Object.values(enumObj));
	// Because of logic in MyPlan currently, we always convert to numeric enum values.
	// TODO CHHI: We prefer this to be string values instead, for readability. Redo MyPlan logic so that enum values are always arbitrary.
	if (typeof value === 'string') {
		value = enumObj[value];
	}
	return value as Exclude<T[keyof T], string>;
}

export function getEnum<T extends string | number>(
	jsonObj: StringObj,
	prop: string,
	enumObj: EnumObj
): T {
	let value: unknown = jsonObj[prop];
	if (!Object.values(enumObj).includes(value as T)) {
		throw new ValidationError(
			jsonObj,
			prop,
			Object.values(enumObj).join(', ')
		);
	}
	if (typeof value === 'string') {
		value = enumObj[value] as T;
	}
	return value as T;
}

export function getNullableEnum<T extends string | number>(
	jsonObj: StringObj,
	prop: string,
	enumObj: EnumObj
): T | null {
	const value: unknown = jsonObj[prop];
	if (isNullish(value)) {
		return null;
	}
	return getEnum<T>(jsonObj, prop, enumObj);
}

export function getArray<T>(
	jsonObj: StringObj,
	prop: string,
	getter: (obj: any) => T
): T[] {
	const array: unknown = jsonObj[prop];
	if (!Array.isArray(array)) {
		throw new ValidationError(jsonObj, prop, Array);
	}
	return array.map(getter);
}

export function getNullableArray<T>(
	jsonObj: StringObj,
	prop: string,
	getter: (obj: any) => T
): T[] | null {
	const array: unknown = jsonObj[prop];
	if (isNullish(array)) return null;
	return getArray(jsonObj, prop, getter);
}

export function getEnumArray<T>(
	jsonObj: StringObj,
	prop: string,
	enumObj: EnumObj
): T[] {
	const array: unknown = jsonObj[prop];
	if (!Array.isArray(array)) {
		throw new ValidationError(jsonObj, prop, Array);
	}
	const values = Object.values(enumObj) as T[];
	return array.map((value) => {
		if (!values.includes(value as T)) {
			throw new ValidationError(jsonObj, prop, values.join(', '));
		}
		if (typeof value === 'string') {
			value = enumObj[value];
		}
		return value as T;
	});
}

export function getNullableEnumArray<T>(
	jsonObj: StringObj,
	prop: string,
	enumObj: EnumObj
): T[] | null {
	const array: unknown = jsonObj[prop];
	if (isNullish(array)) return null;
	return getEnumArray(jsonObj, prop, enumObj);
}

export function getArrayArray<T>(
	jsonObj: StringObj,
	prop: string,
	getter: (obj: any) => T
): T[][] {
	const array: unknown = jsonObj[prop];
	if (!Array.isArray(array)) {
		throw new ValidationError(jsonObj, prop, Array);
	}
	return array.map((jsonArray) => {
		if (!Array.isArray(jsonArray)) {
			throw new ValidationError(jsonObj, prop, Array);
		}
		return jsonArray.map(getter);
	});
}

export function getNullableArrayArray<T>(
	jsonObj: StringObj,
	prop: string,
	getter: (obj: any) => T
): T[][] | null {
	const array: unknown = jsonObj[prop];
	if (isNullish(array)) return null;
	return getArrayArray(jsonObj, prop, getter);
}

export function getEnumArrayArray<T>(
	jsonObj: StringObj,
	prop: string,
	enumObj: EnumObj
): T[][] {
	const array: unknown = jsonObj[prop];
	if (!Array.isArray(array)) {
		throw new ValidationError(jsonObj, prop, Array);
	}
	const values = Object.values(enumObj) as T[];
	return array.map((jsonArray) => {
		if (!Array.isArray(jsonArray)) {
			throw new ValidationError(jsonObj, prop, Array);
		}
		return jsonArray.map((value) => {
			if (!values.includes(value as T)) {
				throw new ValidationError(jsonObj, prop, values.join(', '));
			}
			if (typeof value === 'string') {
				value = enumObj[value];
			}
			return value as T;
		})
	});
}

export function getNullableEnumArrayArray<T>(
	jsonObj: StringObj,
	prop: string,
	enumObj: EnumObj
): T[][] | null {
	const array: unknown = jsonObj[prop];
	if (isNullish(array)) return null;
	return getEnumArrayArray(jsonObj, prop, enumObj);
}

export function getInt(jsonObj: StringObj, prop: string): number {
	const value: unknown = jsonObj[prop];
	if (typeof value !== 'number') {
		throw new ValidationError(jsonObj, prop, Number);
	}
	if (isFinite(value) && Math.floor(value) === value) {
		return value;
	}
	throw new ValidationError(jsonObj, prop, 'integer');
}

export function getIntFromJSON(value: unknown): number {
	assertNumber(value);
	if (isFinite(value) && Math.floor(value) === value) {
		return value;
	}
	throw new ApiError(`integer value expected, got value "${value}"`);
}

export function getReal(jsonObj: StringObj, prop: string): number {
	const value: unknown = jsonObj[prop];
	if (typeof value !== 'number') {
		throw new ValidationError(jsonObj, prop, Number);
	}
	if (isFinite(value)) {
		return value;
	}
	throw new ValidationError(jsonObj, prop, 'real');
}

export function getRealFromJSON(value: unknown): number {
	if (typeof value === 'number' && isFinite(value)) {
		return value;
	}
	throw new ApiError(
		`Real number expected, got type = "${typeof value}", value = "${String(
			value
		)}"`
	);
}

export function getBoolean(jsonObj: StringObj, prop: string): boolean {
	const value: unknown = jsonObj[prop];
	if (typeof value !== 'boolean') {
		throw new ValidationError(jsonObj, prop, 'boolean');
	}
	return value;
}

export function getBooleanFromJSON(value: unknown): boolean {
	assertBoolean(value);
	return value;
}

export function getString(jsonObj: StringObj, prop: string): string {
	const value: unknown = jsonObj[prop];
	if (typeof value !== 'string') {
		throw new ValidationError(jsonObj, prop, 'string');
	}
	return value;
}

export function getStringFromJSON(value: unknown): string {
	assertString(value);
	return value;
}

export function getDate(jsonObj: StringObj, prop: string): Date {
	const strDate = getString(jsonObj, prop);
	const value = new Date(strDate);
	if (isFinite(value.getTime())) {
		return value;
	}
	throw new ValidationError(jsonObj, prop, Date);
}

export function getDateFromJSON(strDate: unknown): Date {
	assertString(strDate);
	const value = new Date(strDate);
	if (isFinite(value.getTime())) {
		return value;
	}
	throw new ApiError(`Could not instantiate date with argument "${strDate}"`);
}

// 6 domain-types that are used in the API's, but not generated by EpDef
export type DomainWsIntervalType = {
	from: Date;
	to: Date;
};

// export type int = number;
// export type real = number;
export type DomainWsVariantObject = any;
export type DomainWsVariantAvailability = DomainWsVariantObject; // TODO remove once Amila fixes the type
export type DomainJsonObject = any;
export type DomainWspdclasttrans = string;
export type DomainWscommitid = string;
export type DomainWscommitidlist = DomainWscommitid[];

export function getDomainJsonObject(jsonObj: unknown, prop: string): any {
	assertObject(jsonObj, prop);
	const value: unknown = jsonObj[prop];
	if (isNullish(value)) {
		throw new ApiError(`Nothing passed to mandatory property "${prop}"`);
	}
	return value;
}

export function getDomainJsonObjectFromJSON(value: any): any {
	if (value == null) {
		throw new ApiError(`Nothing passed to mandatory property`);
	}
	return value;
}

export function getDomainWsVariantObject(jsonObj: any, prop: string): any {
	return getDomainJsonObject(jsonObj, prop);
}

export function getDomainWsVariantObjectFromJSON(value: any): any {
	return getDomainWsVariantObjectFromJSON(value);
}

// TODO_LABO remove once Amila fixes the type
export function getDomainWsVariantAvailability(
	jsonObj: any,
	prop: string
): DomainWsVariantAvailability {
	return getDomainWsVariantObject(jsonObj, prop);
}

export function getDomainWsVariantAvailabilityFromJSON(
	jsonObj: any,
	prop: string
): DomainWsVariantAvailability {
	return getDomainWsVariantObjectFromJSON(jsonObj);
}
// END TODO

function isUnparsedDomainWsIntervalType(
	value: unknown
): value is { from: string; to: string } {
	return (
		typeof value === 'object' &&
		value !== null &&
		'from' in value &&
		'to' in value &&
		typeof value.from === 'string' &&
		typeof value.to === 'string'
	);
}

function parseDomainWsIntervalType(value: {
	from: string;
	to: string;
}): DomainWsIntervalType {
	return {
		from: getDateFromJSON(value.from),
		to: getDateFromJSON(value.to),
	};
}

export function getDomainWsIntervalType(
	jsonObj: unknown,
	prop: string
): DomainWsIntervalType {
	assertObject(jsonObj, prop);
	const value: unknown = jsonObj[prop];
	if (!isUnparsedDomainWsIntervalType(value)) {
		throw new ApiError(
			`DomainWsIntervalType not provided in property ${prop}.`
		);
	}
	return parseDomainWsIntervalType(value);
}

export function getDomainWsIntervalTypeFromJSON(
	value: unknown
): DomainWsIntervalType {
	if (!isUnparsedDomainWsIntervalType(value)) {
		throw new ApiError(`DomainWsIntervalType not provided.`);
	}
	return parseDomainWsIntervalType(value);
}

export function getDomainWspdclasttrans(
	jsonObj: StringObj,
	prop: string
): DomainWspdclasttrans {
	return getString(jsonObj, prop);
}

export function getDomainWscommitid(
	jsonObj: StringObj,
	prop: string
): DomainWscommitid {
	return getString(jsonObj, prop);
}

export function getDomainWscommitidFromJSON(value: unknown): DomainWscommitid {
	assertString(value);
	return value;
}
