import './Duties.scss';

import React from 'react';

import { formatActivityOrDutylineTime } from '../util/dates';
import { TeamDuty } from '../api/TeamPlan_api';
import { currentLanguage } from '../currentLanguage';

export function Duties({ dutyLines }: { dutyLines: TeamDuty[] }) {
  return (
    <>
      <div className="detail-title">{currentLanguage.Duties}</div>
      <div className="duty-list">
        {dutyLines.map((line, index) => {
          const timed = line.start !== null && line.end !== null;
          // If only line.start is defined, it denotes a duration.
          // If only line.end is defined, it denotes a number of something.
          const value = timed
            ? formatActivityOrDutylineTime(line)
            : line.start ?? line.end ?? '';
          return (
            <React.Fragment key={index}>
              <div className={timed ? undefined : 'duty-value'}>{value}</div>
              <div>{`${line.salarysort}: ${line.salarysortText}`}</div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}
