import { DayMessage } from '../api/Message_api';
import { MsgVisibilityOldEnum } from '../api/enumLib_api';

export type ChatKey = string & { __type: 'ChatKey' };
// Length is at least 1
export type DayMessageMap = Record<ChatKey, DayMessage[] & { 0: DayMessage }>;

export function getChatKey(
  personId: number,
  date: Date,
  Visibility: MsgVisibilityOldEnum
): ChatKey {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}:${personId}:${
    Visibility === MsgVisibilityOldEnum.planner ? 'private' : 'public'
  }` as ChatKey;
}
