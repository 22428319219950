import {
  CounterVariant,
  IconSize,
  Icons,
  Spinner,
  SwipeAction,
  SwipeableListItem,
} from '@pdcfrontendui/components';
import { useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import colorVarMap from '@pdcfrontendui/components/colors/colors';
import { scss } from './MenuLists';
import { MenuItemLoadedType } from './MenuReducer';
import ids from '../testing/ids';
import { currentLanguage } from '../currentLanguage';

type Props = {
  isOpen: boolean;
  teamId: string;
  isActive: boolean;
  isLoaded: boolean;
  isFollowed: boolean;
  isNotificationDisabled: boolean;
  label: string;
  unreadMessageCount: number;
  actionsRequiredCount: number;
  groupLabel: string;
  menuItemLoading: MenuItemLoadedType;
  onOpen: () => void;
  onClose: () => void;
  onItemClick: () => void;
  onDownloadClick: () => void;
  onFollowClick: () => void;
  onNotificationClick: () => void;
};

enum LoadingState {
  NotLoaded,
  Loading,
  Loaded,
}

const TouchMenuItem = ({
  isOpen,
  teamId,
  isActive,
  isLoaded,
  isFollowed,
  isNotificationDisabled,
  label,
  groupLabel,
  unreadMessageCount,
  actionsRequiredCount,
  onOpen,
  onClose,
  onItemClick,
  onDownloadClick,
  onFollowClick,
  onNotificationClick,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const preventItemClick = useRef(false);

  useEffect(() => {
    if (isLoaded) setIsLoading(false);
  }, [isLoaded]);

  const loadingState = isLoaded
    ? LoadingState.Loaded
    : isLoading
    ? LoadingState.Loading
    : LoadingState.NotLoaded;

  const swipeActions = useMemo(() => {
    const actions: SwipeAction[] = [];

    if (isFollowed) {
      actions.push({
        icon: isNotificationDisabled ? 'NotificationOn' : 'NotificationOff',
        onClick: () => onNotificationClick(),
        color: isNotificationDisabled
          ? colorVarMap.colorSecondaryLighter
          : colorVarMap.colorNeutralLighter,
      });
    }

    actions.push({
      icon: isFollowed ? 'Unfollow' : 'Follow',
      onClick: () => onFollowClick(),
      color: isFollowed
        ? colorVarMap.colorErrorLighter
        : colorVarMap.colorPrimaryLight,
    });

    return actions;
  }, [isFollowed, onFollowClick]);

  return (
    <SwipeableListItem
      isOpen={isOpen}
      onOpen={() => onOpen()}
      onClose={() => onClose()}
      onClick={() => {
        if (preventItemClick.current) {
          preventItemClick.current = false;
        } else {
          onItemClick();
        }
      }}
      swipeActions={swipeActions}
      className={classNames({
        active: isActive,
      })}
      buttonId={`${ids.Menu.sideMenuTeam}-${teamId}`}
    >
      <div
        className={classNames(scss.teamContents)}
        // TODO: This aria-label should be on the parent button element
        aria-label={`${currentLanguage.SelectThePersonGroup} ${label} ${groupLabel}`}
      >
        <div>
          <div className={scss.teamLabel}>{label}</div>
          <div className={scss.teamOrg}>{groupLabel}</div>
        </div>
        <div className={scss.iconList}>
          {loadingState === LoadingState.Loading && (
            <Spinner className={scss.spinner} />
          )}
          {loadingState === LoadingState.Loaded && (
            <div className={scss.nonClickableIcons}>
              {isFollowed && isNotificationDisabled && (
                <Icons.NotificationOff
                  style={{
                    marginRight:
                      actionsRequiredCount > 0 || unreadMessageCount > 0
                        ? '8px'
                        : 0,
                  }}
                  color={colorVarMap.colorNeutralDarkest}
                />
              )}
              {actionsRequiredCount > 0 && (
                <Icons.Unresolved
                  className={scss.unresolved}
                  count={actionsRequiredCount}
                  size={IconSize.XSmall}
                  counterVariant={CounterVariant.Danger}
                />
              )}
              {unreadMessageCount > 0 && (
                <Icons.Chat
                  className={scss.chat}
                  size={IconSize.XSmall}
                  count={unreadMessageCount}
                />
              )}
            </div>
          )}
          {loadingState === LoadingState.NotLoaded && (
            <Icons.Download
              className={scss.download}
              onClick={() => {
                preventItemClick.current = true;
                setIsLoading(true);
                onDownloadClick();
              }}
              size={IconSize.XSmall}
            />
          )}
        </div>
      </div>
    </SwipeableListItem>
  );
};

export default TouchMenuItem;
