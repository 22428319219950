import {
  Modal,
  Button,
  ButtonStyle,
  LoadingButton,
  InlineNotification,
  NotificationType,
  IconSize,
} from '@pdcfrontendui/components';
import React, { useMemo } from 'react';
import { currentLanguage } from '../currentLanguage';
import { Person, TeamActivity, TeamShift } from '../api/TeamPlan_api';
import { formatActivityOrDutylineTime } from '../util/dates';
import scss from './ConfirmDeleteModal.module.scss';
import { useHideRoot } from '../hooks/useHideRoot';
import { TeamShiftStatusEnum } from '../api/enumLib_api';
import {
  ModalNotification,
  TransparentNotification,
} from '../components/ModalNotification';
import { ModifiedDutylines } from '../ListView/CreateShift/EditedShift';
import { format } from 'date-fns';
import { langToLocale } from '../util/language';

type Props = {
  shown: boolean;
  shift: TeamShift;
  loading: boolean;
  onCancel: () => void;
  onOk: (shiftTimeLabel: string) => void;
  deletedActivities: TeamActivity[] | null;
  coudNotFetchDeletedActivities: boolean;
  employee?: Person;
  modifiedDutylines: ModifiedDutylines | null;
};

const ConfirmDeleteModal = ({
  shown,
  shift,
  loading,
  onCancel,
  onOk,
  deletedActivities,
  coudNotFetchDeletedActivities,
  employee,
  modifiedDutylines,
}: Props) => {
  const shiftTimeLabel = useMemo(() => {
    return format(
      shift.period.from,
      `dddd ${currentLanguage.languageCode === 'Da' ? '[den] ' : ' '}Do MMMM`,
      {
        locale: langToLocale(currentLanguage),
      }
    );
  }, [shift.period.from]);

  useHideRoot(shown);

  return (
    <Modal shown={shown} className={scss.ConfirmDeleteModal}>
      <Modal.Header className={scss.header}>
        {currentLanguage.ConfirmRemoveShiftTitle}
      </Modal.Header>
      <Modal.Icon
        iconKey="ExclamationFill"
        className={scss.icon}
        size={IconSize.XSmall}
      />
      <Modal.Body>
        <div>{`${currentLanguage.ConfirmRemoveShiftBody_3(
          employee?.name ?? currentLanguage.employeeDefiniteArticle,
          shift.label,
          shiftTimeLabel
        )}?`}</div>
        {shift.deviatingPayerUiLabel && (
          <InlineNotification
            type={NotificationType.Error}
            className={scss.notification}
          >
            <div>{currentLanguage.DeviatingPayerNotificationTitle}</div>
            <div>
              {currentLanguage.DeviatingPayerNotificationBody_1(
                shift.deviatingPayerUiLabel
              )}
            </div>
          </InlineNotification>
        )}
        {!!modifiedDutylines?.dutylines.length && (
          <ModalNotification
            type={
              modifiedDutylines.typeHasChanged
                ? NotificationType.Error
                : NotificationType.Caution
            }
            className={scss.notification}
            title={
              modifiedDutylines.dutylines.length > 1
                ? currentLanguage.ModifiedDuties
                : currentLanguage.ModifiedDuty
            }
            body={currentLanguage.WillBeDeletedWithTheShift}
            items={modifiedDutylines.dutylines.map(
              (line) =>
                `${formatActivityOrDutylineTime(line)} ${line.salarysort}: ${
                  line.salarysortText
                }`
            )}
          />
        )}
        {deletedActivities?.length ? (
          <TransparentNotification
            className={scss.notification}
            title={currentLanguage.ActivitiesWillBeDeleted}
            body={currentLanguage.ActivitiesWillBeDeletedPleaseNote}
            items={deletedActivities.map(
              (activity) =>
                `${formatActivityOrDutylineTime(activity)} ${activity.label}`
            )}
          />
        ) : (
          // Very edge case
          coudNotFetchDeletedActivities && (
            <TransparentNotification
              className={scss.notification}
              title={currentLanguage.ActivitiesMightBeDeleted}
              body={currentLanguage.ActivitiesMightBeDeletedPleaseNote}
              items={shift.activities.map(
                (activity) =>
                  `${formatActivityOrDutylineTime(activity)} ${activity.label}`
              )}
            />
          )
        )}
        {(shift.status === TeamShiftStatusEnum.offered ||
          shift.status === TeamShiftStatusEnum.offerActionRequired) && (
          <ModalNotification
            className={scss.notification}
            title={currentLanguage.OfferWillBeCancelled}
            body={currentLanguage.OfferWillBeCancelledPleaseNote}
            type={NotificationType.Error}
          />
        )}
      </Modal.Body>
      <Modal.Buttons>
        <Button variant={ButtonStyle.Secondary} danger onClick={onCancel}>
          {currentLanguage.Cancel}
        </Button>
        <LoadingButton
          variant={ButtonStyle.Primary}
          danger
          onClick={() => onOk(shiftTimeLabel)}
          loading={loading}
        >
          {currentLanguage.Delete}
        </LoadingButton>
      </Modal.Buttons>
    </Modal>
  );
};

export default ConfirmDeleteModal;
