import { Form, Formik, FormikProps } from 'formik';
import { FormError, Icons, LoadingButton } from '@pdcfrontendui/components';
import React, { useEffect, useRef } from 'react';

import FormikTextInput from '../components/Formik/FormikTextInput';
import { Language } from '../generatedLanguages';
import { LoginValues } from './Login';
import ids from '../testing/ids';
import { loginValidationSchema } from './LoginValidations';

type Props = {
  initialValues: LoginValues;
  onSubmitUsernamePass: (values: LoginValues) => void;
  showErrors: boolean;
  loading: boolean;
  currentLanguage: Language;
};

const LoginUsernamePasswordForm = ({
  initialValues,
  onSubmitUsernamePass,
  showErrors,
  loading,
  currentLanguage,
}: Props) => {
  const usernameFieldRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const formikRef = useRef<FormikProps<LoginValues>>(null);

  useEffect(() => {
    if (initialValues.username) {
      passwordFieldRef.current?.focus();
    } else {
      usernameFieldRef.current?.focus();
    }
  }, [initialValues]);

  useEffect(() => {
    if (formikRef.current) {
      void formikRef.current.validateForm();
    }
  }, [currentLanguage]);

  const usernameAndPasswordRef = useRef<HTMLDivElement>(null);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmitUsernamePass}
      validationSchema={loginValidationSchema}
      innerRef={formikRef}
    >
      {(form) => {
        return (
          <Form>
            <div className="inputsWrapper">
              <div ref={usernameAndPasswordRef}>
                <FormikTextInput<typeof initialValues>
                  id={ids.Login.username}
                  icon={Icons.User}
                  label={currentLanguage.userName}
                  name="username"
                  className="TextInput"
                  hasError={
                    (!!form.touched.username && !!form.errors.username) ||
                    showErrors
                  }
                  inputRef={usernameFieldRef}
                />
                <FormError
                  fieldName="username"
                  errors={form.errors}
                  touched={form.touched}
                  className="formError"
                  reserveSpace={true}
                />

                <FormikTextInput<typeof initialValues>
                  id={ids.Login.password}
                  icon={Icons.Lock}
                  label={currentLanguage.password}
                  name={'password'}
                  type="password"
                  hasError={
                    (!!form.touched.password && !!form.errors.password) ||
                    showErrors
                  }
                  className="TextInput"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      // Remove focus from the password field to avoid triggering the
                      // "required" error messages on the new password fields.
                      e.currentTarget.blur();
                      void form.submitForm();
                    }
                  }}
                  inputRef={passwordFieldRef}
                />
                <FormError
                  fieldName="password"
                  errors={form.errors}
                  touched={form.touched}
                  className="formError"
                  reserveSpace={true}
                />
              </div>
            </div>
            <LoadingButton
              id={ids.Login.loginUsernamePasswordButton}
              onClick={() => void form.submitForm()}
              type="button"
              className="loginButton"
              loading={loading}
            >
              {currentLanguage.login}
            </LoadingButton>
          </Form>
        );
      }}
    </Formik>
  );
};
export default LoginUsernamePasswordForm;
