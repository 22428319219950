import { objectKeys } from '@pdcfrontendui/utils';

export interface OpenIdConfig {
  acrValues?: string;
  authorizeUrl: string;
  clientId: string;
  grantType?: string;
  responseType?: string;
  redirectUri: string;
  resource?: string;
  scope?: string;
}

function generateClientRequestId() {
  return '6a189b81-8423-461e-8000-' + Date.now().toString().slice(0, 12);
}

type OpenIdParamsObj = {
  client_id: string;
  response_type: string;
  redirect_uri: string;
  'client-request-id': string;
  resource: string | undefined;
  scope: string | undefined;
  acr_values: string | undefined;
  prompt: false | string;
};

function urlEncodeParameters(paramsObj: OpenIdParamsObj) {
  const result: string[] = [];
  objectKeys(paramsObj).forEach((param) => {
    if (paramsObj[param]) {
      result.push(param + '=' + encodeURIComponent(paramsObj[param] ?? ''));
    }
  });
  return result.join('&');
}

export function generateOpenIdAuthorizationEndpoint(
  conf: OpenIdConfig,
  hasError: boolean
): string {
  const authUrl = conf.authorizeUrl.replace(/[?/]+$/, '');
  const paramsObj: OpenIdParamsObj = {
    client_id: conf.clientId,
    response_type: conf.responseType || 'code',
    redirect_uri: conf.redirectUri,
    'client-request-id': generateClientRequestId(),
    resource: conf.resource,
    scope: conf.scope,
    acr_values: conf.acrValues,
    prompt: hasError && 'login',
  };

  return `${authUrl}?${urlEncodeParameters(paramsObj)}`;
}
