import { dateToString, localeFormat } from '../util/dates';

import React, { type MutableRefObject } from 'react';
import classNames from 'classnames';
import { currentLanguage } from '../currentLanguage';
import dateFns from 'date-fns';
import scss from './DayLabel.module.scss';

function DayLabel({
  date,
  currentDate,
  daysMap,
  daysMapInverse,
}: {
  date: Date;
  currentDate: Date;
  daysMap?: WeakMap<HTMLDivElement, Date>;
  daysMapInverse?: MutableRefObject<Record<string, HTMLDivElement>>;
}) {
  const isCurrentDate = dateFns.isSameDay(date, currentDate);
  const dayKey = dateToString(date);
  return (
    <div
      className={classNames(scss.comp, {
        [scss.currentDate ?? '']: isCurrentDate,
      })}
      ref={(elem) => {
        if (elem) {
          daysMap?.set(elem, date);
          if (daysMapInverse) {
            daysMapInverse.current[dayKey] = elem;
          }
        }
      }}
    >
      {localeFormat(date, false, currentLanguage.languageCode)}
    </div>
  );
}

export default DayLabel;
