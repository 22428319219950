import * as Yup from 'yup';

import { currentLanguage } from '../currentLanguage';

export const loginValidationSchema = () => {
  return Yup.object().shape({
    username: Yup.string()
      .required(currentLanguage.userNameIsRequired)
      .test({
        test: (value) =>
          value && !value.startsWith(' ') && !value.endsWith(' '),
        message: currentLanguage.userNameNoBlackSpacesAtStartOrEnd,
      }),
    password: Yup.string().required(currentLanguage.passwordIsRequired),
  });
};
