import * as redux from 'redux';

import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import rootReducer, { Store } from './rootReducer';

import { composeWithDevTools } from '@redux-devtools/extension';
import { persistAndInit } from './middleware/persistAndInit';
import thunkMiddleware from 'redux-thunk';
import { trackingMiddleware } from './middleware/tracking';

// Since logic has been set up to encapsulate store initialization,
// this is currently the only way to be able to read store state other than passing props.
// TODO (CHHI): This is a hack, and logic inside index.tsx should be reworked.
const __store = {
  getState: () => undefined,
  dispatch: () => undefined,
} as unknown as Pick<redux.Store<Store>, 'getState' | 'dispatch'>;
export { __store as store };

export function configureStore(initStore: Store): redux.Store<Store> {
  const store = createStore(
    rootReducer,
    initStore,
    composeWithDevTools(
      applyMiddleware(thunkMiddleware, persistAndInit, trackingMiddleware)
    )
  );
  __store.getState = () => store.getState();
  __store.dispatch = store.dispatch.bind(store);
  return store;
}
