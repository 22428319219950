import {
  ContextMenu,
  CounterVariant,
  IconSize,
  Icons,
  MenuPosition,
  Spinner,
} from '@pdcfrontendui/components';
import { useEffect, useMemo, useState } from 'react';

import { MenuItem } from '@pdcfrontendui/components/ContextMenu/ContextMenu';
import classNames from 'classnames';
import { currentLanguage } from '../currentLanguage';
import { scss } from './MenuLists';
import colorVarMap from '@pdcfrontendui/components/colors/colors';
import { MenuItemLoadedType } from './MenuReducer';
import ids from '../testing/ids';

type Props = {
  teamId: string;
  isActive: boolean;
  isLoaded: boolean;
  isFollowed: boolean;
  isNotificationDisabled: boolean;
  label: string;
  unreadMessageCount: number;
  actionsRequiredCount: number;
  groupLabel: string;
  menuItemLoading: MenuItemLoadedType;
  onItemClick: () => void;
  onDownloadClick: () => void;
  onFollowClick: () => void;
  onNotificationClick: () => void;
};

enum LoadingState {
  NotLoaded,
  Loading,
  Loaded,
}

const NonTouchMenuItem = ({
  teamId,
  isActive,
  isLoaded,
  isFollowed,
  isNotificationDisabled,
  label,
  groupLabel,
  unreadMessageCount,
  actionsRequiredCount,
  menuItemLoading,
  onItemClick,
  onDownloadClick,
  onFollowClick,
  onNotificationClick,
}: Props) => {
  const loadingState = isLoaded
    ? LoadingState.Loaded
    : menuItemLoading[teamId]?.download ?? false
    ? LoadingState.Loading
    : LoadingState.NotLoaded;

  const handleDownloadClick = () => {
    onDownloadClick();
  };

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [];

    if (!isLoaded) {
      items.push({
        icon: 'Download',
        label: currentLanguage.FetchData,
        onClick: () => {
          handleDownloadClick();
        },
      });
    }

    if (isFollowed) {
      items.push({
        icon: isNotificationDisabled ? 'NotificationOn' : 'NotificationOff',
        label: isNotificationDisabled
          ? currentLanguage.NotificationsOn
          : currentLanguage.NotificationsOff,
        onClick: () => {
          onNotificationClick();
        },
        loading: menuItemLoading[teamId]?.notification ?? false,
      });
    }

    items.push({
      icon: isFollowed ? 'Unfollow' : 'Follow',
      label: isFollowed
        ? currentLanguage.UnfollowGroup
        : currentLanguage.FollowGroup,
      onClick: () => {
        onFollowClick();
      },
      loading: menuItemLoading[teamId]?.favoriteStatus ?? false,
    });

    return items;
  }, [isFollowed, isLoaded, isNotificationDisabled]);

  return (
    <div
      className={classNames(scss.teamContents, {
        active: isActive,
      })}
    >
      <button
        className={scss.teamButton}
        id={`${ids.Menu.sideMenuTeam}-${teamId}`}
        onClick={() => onItemClick()}
        aria-label={`${currentLanguage.SelectThePersonGroup} ${label} ${groupLabel}`}
      >
        <div className={scss.teamLabel}>{label}</div>
        <div className={scss.teamOrg}>{groupLabel}</div>
      </button>
      <div className={scss.iconList}>
        {loadingState === LoadingState.Loading && (
          <Spinner className={scss.spinner} />
        )}
        {loadingState === LoadingState.Loaded && (
          <div className={scss.nonClickableIcons}>
            {isFollowed && isNotificationDisabled && (
              <Icons.NotificationOff
                style={{
                  marginRight:
                    actionsRequiredCount > 0 || unreadMessageCount > 0
                      ? '8px'
                      : 0,
                }}
                color={colorVarMap.colorNeutralDarkest}
              />
            )}
            {actionsRequiredCount > 0 && (
              <Icons.Unresolved
                className={scss.unresolved}
                count={actionsRequiredCount}
                size={IconSize.XSmall}
                counterVariant={CounterVariant.Danger}
              />
            )}
            {unreadMessageCount > 0 && (
              <Icons.Chat
                className={scss.chat}
                size={IconSize.XSmall}
                count={unreadMessageCount}
              />
            )}
          </div>
        )}
        <ContextMenu
          className={classNames(scss.contextMenu, {
            [scss.visible]:
              !isFollowed && loadingState === LoadingState.NotLoaded,
          })}
          menuItems={menuItems}
          position={MenuPosition.BottomLeft}
          stopPropagation={true}
        >
          <Icons.Ellipsis className={scss.menuButton} />
        </ContextMenu>
      </div>
    </div>
  );
};

export default NonTouchMenuItem;
