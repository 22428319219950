import { useEffect, useLayoutEffect } from 'react';

const add = (query: string) => {
  document.querySelector(query)?.classList.add('_parallax');
};
const remove = (query: string) => {
  document.querySelector(query)?.classList.remove('_parallax');
};

/**
 *
 * Add a parallax effect to the app container if the argument is true
 * Only use case, currently, is the MobileSlideIn component that is mounted via createPortal to the document body
 */
export default function useParallax(
  shouldParallax: boolean,
  query = '#react-container > .splitpane'
) {
  useLayoutEffect(() => {
    if (shouldParallax) {
      add(query);
    } else {
      remove(query);
    }
  }, [shouldParallax, query]);

  // Cleanup in case the component unmounts before condition turns false
  // Not inside the useLayoutEffect because we don't want this to be called on every render
  useEffect(() => () => remove(query), [query]);
}
