﻿/* Fully Generated from generatedWEBSERVICE_Api.ts */

//===============================================================
//  Auto Generated Code for NotificationLog
//===============================================================
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-useless-constructor */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-extraneous-class */

/* This file contains, in this order:
 *   1. Data value objects
 *   2. Classes defining jsonRpc methods returning data value objects
 */

import { type WebResponseResult } from '@pdcfrontendui/staffplan';
import { WebRequest } from './WebRequest';
import {
  WebTypeViewField,
  getWebTypeViewFieldFromJSON,
  getWebTypeViewField,
  WebField,
  getWebFieldFromJSON,
  getWebField,
  WebRecord,
  getWebRecordFromJSON,
  getWebRecord,
  WebList,
  getWebListFromJSON,
  getWebList,
  WebElement,
  getWebElement,
  LogonSettings,
  getLogonSettingsFromJSON,
  getLogonSettings,
  WebSortConfig,
  getWebSortConfigFromJSON,
  getWebSortConfig,
  WebFieldConfig,
  getWebFieldConfigFromJSON,
  getWebFieldConfig,
  WebListConfig,
  getWebListConfigFromJSON,
  getWebListConfig,
  WebRecordType,
  getWebRecordTypeFromJSON,
  getWebRecordType,
  WsRefPerson,
  getWsRefPerson,
  WsRefOrgunit,
  getWsRefOrgunit,
  WsRefOrgunitByInfo,
  getWsRefOrgunitByInfo,
  WsRefShiftCounter,
  getWsRefShiftCounter,
  WsRefShifttype,
  getWsRefShifttype,
  WsRefTaskDef,
  getWsRefTaskDef,
  WsRefTask,
  WsRefAccount,
  WsRefProject,
  WsRefDimensionItem,
  getWsRefAccount,
  getWsRefProject,
  getWsRefDimensionItem,
  getWsRefTask,
} from './Common_api';

import * as Enums from './enumLib_api'
import {
  assertObject,
  getNullable,
  getEnumFromJSON,
  getEnum,
  getNullableEnum,
  getEnumArray,
  getNullableEnumArray,
  getArray,
  getNullableArray,
  getEnumArrayArray,
  getNullableEnumArrayArray,
  getArrayArray,
  getNullableArrayArray,
  getInt,
  getIntFromJSON,
  getReal,
  getRealFromJSON,
  getBoolean,
  getBooleanFromJSON,
  getString,
  getStringFromJSON,
  getDate,
  getDateFromJSON,
  isNullish,
  DomainWsIntervalType,
  DomainWsVariantObject,
  DomainWsVariantAvailability,
  DomainJsonObject,
  DomainWspdclasttrans,
  DomainWscommitid,
  getDomainJsonObject,
  getDomainJsonObjectFromJSON,
  getDomainWsVariantObject,
  getDomainWsVariantObjectFromJSON,
  getDomainWsVariantAvailability,
  getDomainWsVariantAvailabilityFromJSON,
  getDomainWsIntervalType,
  getDomainWsIntervalTypeFromJSON,
  getDomainWspdclasttrans,
  getDomainWscommitid,
  getDomainWscommitidFromJSON,
  nullishError,
  type StringObj,
} from './validators';

////////////////// 1. Data value objects /////////////////////////
// These are the actual data value objects used in typescript

// Information about the application and evironment where the exception occurred (corresponds to the C# class PdcLogSysInfo)
//  Responsible PM
export class ClientInfo {
  [key: string]: unknown;
  constructor(
    public browserAgent: string,
    public platform: string,
    public browserCodeName: string,
    public applicationId: Enums.ApplicationEnum,
    public version: string,
    public extra: string) {}
}

export function getClientInfoFromJSON(jsonObj: StringObj): ClientInfo {
  return new ClientInfo(
    /* 80; Browser information */
      getString(jsonObj, 'browserAgent'),
    /* 80; Android or IOS or ... with version number */
      getString(jsonObj, 'platform'),
    /* 80; Firefox, Chrome.... */
      getString(jsonObj, 'browserCodeName'),

      getEnum(jsonObj, 'applicationId', Enums.ApplicationEnum),
    /* 80 */
      getString(jsonObj, 'version'),
    /* 80; Free text */
      getString(jsonObj, 'extra')
  );
}

export function getClientInfo(jsonObj: StringObj, prop: string): ClientInfo {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('ClientInfo', prop);
  assertObject(val);
  return getClientInfoFromJSON(val);
}

// A line in a Prolog-like stacktrace
//  Responsible PM
export class LineInfo {
  [key: string]: unknown;
  constructor(
    public file: string,
    public line: number) {}
}

export function getLineInfoFromJSON(jsonObj: StringObj): LineInfo {
  return new LineInfo(
    /* 80 */
      getString(jsonObj, 'file'),

      getInt(jsonObj, 'line')
  );
}

export function getLineInfo(jsonObj: StringObj, prop: string): LineInfo {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('LineInfo', prop);
  assertObject(val);
  return getLineInfoFromJSON(val);
}


////////////////// 2. Classes defining jsonRpc methods returning data value objects /////////////////////////
/*
    These classes are responsible for deserialize and serialize from and to toJSON
    into the respectfull objects needed.
    AND
    Are responsible for knowing exact method and url endpoint to call with json RPC
*/

/**     % Send an exception to the notification log, with an explicit groupon argument
    % Prefer this logging method in javascript and typescript, where the stacktrace is a bad mechanism for grouping exceptions
Responsible: LABO */
export class Log2 extends WebRequest<boolean> {
  public webService = 'notificationLog' as const;
  public method = 'Log2' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public groupOn: string,
    public exceptionName: string,
    public exceptionMsg: string,
    public userComment: string,
    public clientInfo: ClientInfo,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<boolean>): boolean {
    return getBooleanFromJSON(json.result);
  }
}

/**     % Send an exception to the notification log, and group the exception as if it occurred in a Prolog program
    % Prefer this logging method if the stacktrace is a good mechanism for grouping exceptions
Responsible: PM */
export class Log extends WebRequest<boolean> {
  public webService = 'notificationLog' as const;
  public method = 'Log' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public exceptionName: string,
    public exceptionMsg: string,
    public stacktrace: LineInfo[],
    public userComment: string,
    public clientInfo: ClientInfo,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<boolean>): boolean {
    return getBooleanFromJSON(json.result);
  }
}

