import { EmployeeMap } from '../api/model';

import DayLabel from './DayLabel';
import ListViewItem from './ListViewItem';
import React, { type MutableRefObject, Fragment } from 'react';
import { currentLanguage } from '../currentLanguage';
import { dateMath } from '@pdcfrontendui/utils';
import { Tab } from '../routes';
import { TeamShift } from '../api/TeamPlan_api';
import scss from './ListView.module.scss';

export default function ListViewItems({
  shiftMap,
  currentDate,
  employeeMap,
  teamId,
  selectShift,
  selectedShiftId,
  selectedTab,
  hideSelectedShiftMarker,
  hasMessageLookup,
  hasUnreadLookup,
  daysMap,
  daysMapInverse,
}: {
  shiftMap: Record<string, TeamShift[]>;
  currentDate: Date;
  employeeMap: EmployeeMap;
  teamId: string;
  selectShift: (teamId: string, shiftId: string, personId: number) => void;
  hideSelectedShiftMarker?: boolean;
  selectedShiftId?: string;
  hasMessageLookup: Record<string, true>;
  hasUnreadLookup: Record<string, true>;
  daysMap?: WeakMap<HTMLDivElement, Date>;
  daysMapInverse?: MutableRefObject<Record<string, HTMLDivElement>>;
  selectedTab: Tab;
}) {
  const noVisibleShifts = Object.values(shiftMap).every(
    (list) => list.length === 0
  );
  return noVisibleShifts ? (
    <div className={scss.noShiftsFound}>
      <br />
      {currentLanguage.noShiftsToShow}
    </div>
  ) : (
    <>
      {Object.keys(shiftMap)
        .sort()
        .map((key) => {
          const shifts = shiftMap[key];
          const date = dateMath.floorDay(new Date(key));

          return (
            <Fragment key={key}>
              <DayLabel
                date={date}
                currentDate={currentDate}
                daysMap={daysMap}
                daysMapInverse={daysMapInverse}
              />
              {shifts?.map((item) => (
                <ListViewItem
                  id={item.id}
                  key={item.id}
                  employeeName={employeeMap[item.personId]?.name ?? ''}
                  status={item.status}
                  numSwapSuggestions={item.numSwapSuggestions}
                  period={item.period}
                  shiftInformationLabel={
                    item.label +
                    (item.responsibilities.length > 0
                      ? `, ${item.responsibilities
                          .map((responsibility) => responsibility)
                          .join(', ')}`
                      : '')
                  }
                  selectShift={() => {
                    selectShift(teamId, item.id, item.personId);
                  }}
                  hasMessage={hasMessageLookup[item.id]}
                  unreadMessage={hasUnreadLookup[item.id]}
                  isSelected={
                    item.id === selectedShiftId && !hideSelectedShiftMarker
                  }
                  date={date}
                  selectedTab={selectedTab}
                  deviatingPayerUiLabel={item.deviatingPayerUiLabel}
                />
              ))}
            </Fragment>
          );
        })}
    </>
  );
}
