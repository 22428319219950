import './EmployeeItem.scss';

import Icons, { IconSize } from '@pdcfrontendui/components/Icons';

import { currentLanguage } from '../currentLanguage';
import { makeKeyDown } from '@pdcfrontendui/utils';
import { EmployeeIcon } from './EmployeeIcon';

export type StateFromProps = {
  id: number;
  name: string;
  status: string;

  priority: number;
  isLoading?: boolean;
};

export type DispatchFromProps = {
  onItemClick: () => void;
};

export default function EmployeeItem({
  id,
  name,
  status,
  priority,
  subText,
  isLoading,
  onItemClick,
  isFictive,
}: {
  id: number;
  name: string;
  priority?: number | null;
  status?: string;
  subText?: string;
  isLoading?: boolean;
  onItemClick: () => void;
  isFictive?: boolean;
}) {
  if (isFictive) {
    status = currentLanguage.AvailableFictivePerson;
  }

  const handleClick = () => (isLoading ? undefined : onItemClick());

  return (
    <div
      className={'employeeitem'}
      key={`substitute-${id.toString()}`}
      id={id.toString()}
      onClick={handleClick}
      onKeyDown={makeKeyDown(handleClick)}
      role="button"
      tabIndex={0}
      aria-disabled={isLoading}
      aria-label={name}
    >
      <EmployeeIcon className="icon" isFictive={isFictive} />
      <div className={'name'}>{name}</div>

      {status && (
        <div className="status">
          {typeof priority === 'number' && priority > 0 && !isFictive
            ? `${Math.floor(priority)}: ${status}`
            : status}
        </div>
      )}

      {subText && <div className="sub-text">{subText}</div>}

      <Icons.ChevronRight className="arrow-icon" size={IconSize.XXXSmall} />
    </div>
  );
}

