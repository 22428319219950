import ChatThread from '@pdcfrontendui/components/ChatThread';
import { type Message } from '@pdcfrontendui/components/ChatMessage';
import Header from '@pdcfrontendui/components/Header';
import Icons, { IconSize } from '@pdcfrontendui/components/Icons';
import MobileSlideIn from '@pdcfrontendui/components/MobileSlideIn';
import React from 'react';
import { useIsDualView } from '@pdcfrontendui/hooks';
import { currentLanguage } from '../currentLanguage';
import scss from './Chat.module.scss';
import useParallax from '../hooks/useParallax';
import ids from '../testing/ids';

/**
 * @param title - title of the chat
 * @param messages - messages of the chat, set to null to close the chat
 * @param goBack - function to go back to the previous page. It should set messages to null.
 */
export default function Chat({
  title,
  messages,
  sendMessage,
  goBack,
  markMessagesAsRead,
}: {
  title: string;
  messages: Message[] | null;
  sendMessage: (message: string) => void;
  goBack: () => void;
  markMessagesAsRead: () => void;
}) {
  const isMobile = !useIsDualView();
  useParallax(isMobile && !!messages);
  return (
    <MobileSlideIn
      className={scss.comp}
      isMobile={isMobile}
      id={ids.ShiftView.chatThread}
      bottom={50}
      absolute
    >
      {messages && (
        <>
          <Header centerize>
            {!isMobile && <Header.Spacer />}
            <Header.Left>
              <Icons.ChevronLeft
                onClick={goBack}
                id={ids.ShiftView.closeChat}
                size={IconSize.XSmall}
              />
            </Header.Left>
            <Header.Title bold={isMobile}>{title}</Header.Title>
          </Header>
          <ChatThread
            messages={messages}
            sendMessage={sendMessage}
            placeholder={currentLanguage.WriteMessage}
            markAllAsRead={markMessagesAsRead}
            inputId={ids.ShiftView.messageInput}
            sendButtonId={ids.ShiftView.sendMessage}
            maxLength={800}
            maxLengthError={currentLanguage.MaxLengthError}
          />
        </>
      )}
    </MobileSlideIn>
  );
}
