/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { currentLanguage, LanguageCode } from '../currentLanguage';
import da from 'date-fns/locale/da';
import sv from 'date-fns/locale/sv';
import en from 'date-fns/locale/en';
import { Language } from '../generatedLanguages';

export function langCodeToLocale(
  langCode: LanguageCode | (string & NonNullable<unknown>)
): object {
  switch (langCode) {
    case 'Da':
      return da;
    case 'Se':
      return sv;
    default:
      return en;
  }
}

export function langToLocale(language: Language): object {
  return langCodeToLocale(language.languageCode);
}

/**
 *
 * @example
 * formatList(['a', 'b', 'c']) // 'a, b and c'
 * formatList(['a', 'b']) // 'a and b'
 * formatList(['a']) // 'a'
 */
export function formatList(items: string[]): string {
  switch (items.length) {
    case 0:
      return '';
    case 1:
      return items[0]!;
    case 2:
      return `${items[0]!} ${currentLanguage.and} ${items[1]!}`;
    default:
      return `${items.slice(0, -1).join(', ')} ${currentLanguage.and} ${items[
        items.length - 1
      ]!}`;
  }
}

/**
 * @example
 * genitive('John', 'Da') // "Johns"
 * genitive('John', 'En') // "John's"
 * genitive('John', 'Se') // "Johns"
 * genitive('Hans', 'Da') // "Hans'"
 * genitive('Hans', 'En') // "Hans'"
 * genitive('Hans', 'Se') // "Hans'"
 */
export function genitive(
  name: string,
  languageCode: LanguageCode | (string & NonNullable<unknown>)
): string {
  if (name.endsWith('s')) {
    return `${name}'`;
  }
  switch (languageCode) {
    case 'Da':
      return `${name}s`;
    case 'Se':
      return `${name}s`;
    default:
      return `${name}'s`;
  }
}
