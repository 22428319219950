import { ActionType } from '../ActionType';
import { Dispatch } from 'redux';
import { PushNotificationData } from './PushNotification';

export type PushNotificationAction =
  | {
      type: ActionType.PUSHNOTIFICATION_REGISTER_SUCCESS;
      device: string;
    }
  | {
      type: ActionType.PUSHNOTIFICATION_REGISTER_ERROR;
      errorMessage: string;
    }
  | {
      type: ActionType.PUSHNOTIFICATION_RECEIVED;
      data: PushNotificationData;
    }
  | {
      type: ActionType.PUSHNOTIFICATION_ERROR;
      errorMessage: string;
    };

export const pushNotificationRegisterSuccess = (
  device: string
): PushNotificationAction => ({
  type: ActionType.PUSHNOTIFICATION_REGISTER_SUCCESS,
  device,
});

export const pushNotificationReceived = (
  data: PushNotificationData
): PushNotificationAction => ({
  type: ActionType.PUSHNOTIFICATION_RECEIVED,
  data,
});

export const pushNotificationRegisterError = (
  errorMessage: string
): PushNotificationAction => ({
  type: ActionType.PUSHNOTIFICATION_REGISTER_ERROR,
  errorMessage,
});

export type IOSNotification = {
  aps: {
    alert:
      | {
          title: string;
          body: string;
        }
      | string;
    badge: number;
    sound: string;
  };
  messageType: string;
};

export type AndroidNotification = {
  messageType: string;
  id: string;
  ttl: number;
  body: string;
  from: number;
  sound: string;
  title?: string;
  sent_time: number;
  collapse_key: string;
  show_notification: boolean;
};

export function handlePushNotificationReceived(
  data: AndroidNotification | IOSNotification
) {
  return (dispatch: Dispatch) => {
    const notification = ExtractNotification(data);
    dispatch(pushNotificationReceived(notification));
    // TODO Use modal when we know what to tell the user
    // dispatch(
    //   openModal({
    //     content: notification.message,
    //     modalType: ModalType.notification,
    //     modalBtn: {
    //       confirmButtonText: currentLanguage.reload,
    //       confirmButtonStyle: ButtonStyleEnum.PRIMARY,
    //       onModalConfirm: () => {
    //         dispatch(closeModal());
    //         if (typeof window.pdc.shell.emit !== 'function') {
    //           throw new Error('Need window.pdc.shell.emit');
    //         }
    //         window.pdc.shell.emit('push-set-badge', 0);
    //       },
    //     },
    //   })
    // );
  };
}

export const pushNotificationError = (
  errorMessage: string
): PushNotificationAction => ({
  type: ActionType.PUSHNOTIFICATION_ERROR,
  errorMessage,
});

function isAndroidNotification(
  data: AndroidNotification | IOSNotification
): data is AndroidNotification {
  return !!(data as AndroidNotification).body;
}

const ExtractNotification = (
  data: AndroidNotification | IOSNotification
): PushNotificationData => {
  if (isAndroidNotification(data)) {
    const notification = { message: data.body, title: data.title ?? '' };
    return notification;
  } else {
    if (typeof data.aps.alert === 'string') {
      return { message: data.aps.alert, title: '' };
    } else {
      return {
        message: data.aps.alert.body,
        title: data.aps.alert.title,
      };
    }
  }
};
