import { ConfirmModal, ConfirmModalProps } from '@pdcfrontendui/components';

import ErrorBoundaryContainer from '../../sharedComponents/ErrorBoundaryContainer';
import type { Optional } from '@pdcfrontendui/types';
import { connect } from '../../rootReducer';
import { hideConfirmDialog } from '../../appActions';
import ids from '../../testing/ids';

export type ConfirmDialogProps = Optional<
  Omit<ConfirmModalProps, 'buttonOkId' | 'buttonCancelId'>,
  'variant' | 'onCancel'
>;

const ConfirmDialog = connect(
  (store) => store.appReducer.confirmDialog,
  (dispatch) => ({
    hideConfirmDialog: () => {
      dispatch(hideConfirmDialog());
    },
  })
)((props) => {
  return (
    <ErrorBoundaryContainer>
      <ConfirmModal
        {...props}
        buttonCancelText={props.buttonCancelText ?? ''}
        buttonOkText={props.buttonOkText ?? ''}
        onCancel={() => {
          props.onCancel?.();
          props.hideConfirmDialog();
        }}
        onOk={() => {
          props.onOk();
          props.hideConfirmDialog();
        }}
        buttonCancelId={ids.Modal.dialogCancel}
        buttonOkId={ids.Modal.dialogConfirm}
      />
    </ErrorBoundaryContainer>
  );
});

export default ConfirmDialog;
