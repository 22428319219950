﻿/* Fully Generated from generatedWEBSERVICE_Api.ts */

//===============================================================
//  Auto Generated Code for Tracking
//===============================================================
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-useless-constructor */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-extraneous-class */

/* This file contains, in this order:
 *   1. Data value objects
 *   2. Classes defining jsonRpc methods returning data value objects
 */

import { type WebResponseResult } from '@pdcfrontendui/staffplan';
import { WebRequest } from './WebRequest';
import {
  WebTypeViewField,
  getWebTypeViewFieldFromJSON,
  getWebTypeViewField,
  WebField,
  getWebFieldFromJSON,
  getWebField,
  WebRecord,
  getWebRecordFromJSON,
  getWebRecord,
  WebList,
  getWebListFromJSON,
  getWebList,
  WebElement,
  getWebElement,
  LogonSettings,
  getLogonSettingsFromJSON,
  getLogonSettings,
  WebSortConfig,
  getWebSortConfigFromJSON,
  getWebSortConfig,
  WebFieldConfig,
  getWebFieldConfigFromJSON,
  getWebFieldConfig,
  WebListConfig,
  getWebListConfigFromJSON,
  getWebListConfig,
  WebRecordType,
  getWebRecordTypeFromJSON,
  getWebRecordType,
  WsRefPerson,
  getWsRefPerson,
  WsRefOrgunit,
  getWsRefOrgunit,
  WsRefOrgunitByInfo,
  getWsRefOrgunitByInfo,
  WsRefShiftCounter,
  getWsRefShiftCounter,
  WsRefShifttype,
  getWsRefShifttype,
  WsRefTaskDef,
  getWsRefTaskDef,
  WsRefTask,
  WsRefAccount,
  WsRefProject,
  WsRefDimensionItem,
  getWsRefAccount,
  getWsRefProject,
  getWsRefDimensionItem,
  getWsRefTask,
} from './Common_api';

import * as Enums from './enumLib_api'
import {
  assertObject,
  getNullable,
  getEnumFromJSON,
  getEnum,
  getNullableEnum,
  getEnumArray,
  getNullableEnumArray,
  getArray,
  getNullableArray,
  getEnumArrayArray,
  getNullableEnumArrayArray,
  getArrayArray,
  getNullableArrayArray,
  getInt,
  getIntFromJSON,
  getReal,
  getRealFromJSON,
  getBoolean,
  getBooleanFromJSON,
  getString,
  getStringFromJSON,
  getDate,
  getDateFromJSON,
  isNullish,
  DomainWsIntervalType,
  DomainWsVariantObject,
  DomainWsVariantAvailability,
  DomainJsonObject,
  DomainWspdclasttrans,
  DomainWscommitid,
  getDomainJsonObject,
  getDomainJsonObjectFromJSON,
  getDomainWsVariantObject,
  getDomainWsVariantObjectFromJSON,
  getDomainWsVariantAvailability,
  getDomainWsVariantAvailabilityFromJSON,
  getDomainWsIntervalType,
  getDomainWsIntervalTypeFromJSON,
  getDomainWspdclasttrans,
  getDomainWscommitid,
  getDomainWscommitidFromJSON,
  nullishError,
  type StringObj,
} from './validators';

////////////////// 1. Data value objects /////////////////////////
// These are the actual data value objects used in typescript

// Tracking viewport object
//  Responsible ASHA
export class Viewport {
  [key: string]: unknown;
  constructor(
    public width: number,
    public height: number) {}
}

export function getViewportFromJSON(jsonObj: StringObj): Viewport {
  return new Viewport(

      getInt(jsonObj, 'width'),

      getInt(jsonObj, 'height')
  );
}

export function getViewport(jsonObj: StringObj, prop: string): Viewport {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('Viewport', prop);
  assertObject(val);
  return getViewportFromJSON(val);
}

// Tracking activity object
//  Responsible ASHA
export class TrackingActivity {
  [key: string]: unknown;
  constructor(
    public name: string,
    public timeStamp: Date,
    public auxDate: Date | null = null) {}
}

export function getTrackingActivityFromJSON(jsonObj: StringObj): TrackingActivity {
  return new TrackingActivity(
    /* 80 */
      getString(jsonObj, 'name'),

      getDate(jsonObj, 'timeStamp'),

      getNullable(jsonObj, 'auxDate', getDate)
  );
}

export function getTrackingActivity(jsonObj: StringObj, prop: string): TrackingActivity {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TrackingActivity', prop);
  assertObject(val);
  return getTrackingActivityFromJSON(val);
}

// Tracking navigation object
//  Responsible ASHA
export class Navigation {
  [key: string]: unknown;
  constructor(
    public viewName: string,
    public timeStamp: Date,
    public activities: TrackingActivity[]) {}
}

export function getNavigationFromJSON(jsonObj: StringObj): Navigation {
  return new Navigation(
    /* 80 */
      getString(jsonObj, 'viewName'),

      getDate(jsonObj, 'timeStamp'),

    getArray(jsonObj, 'activities', getTrackingActivityFromJSON)
  );
}

export function getNavigation(jsonObj: StringObj, prop: string): Navigation {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('Navigation', prop);
  assertObject(val);
  return getNavigationFromJSON(val);
}

// Tracking object object
//  Responsible ASHA
export class TrackingObject {
  [key: string]: unknown;
  constructor(
    public userHash: string,
    public sessionStart: Date,
    public viewPort: Viewport,
    public device: string,
    public navigations: Navigation[]) {}
}

export function getTrackingObjectFromJSON(jsonObj: StringObj): TrackingObject {
  return new TrackingObject(
    /* 80 */
      getString(jsonObj, 'userHash'),

      getDate(jsonObj, 'sessionStart'),

      getViewport(jsonObj, 'viewPort'),
    /* 80 */
      getString(jsonObj, 'device'),

    getArray(jsonObj, 'navigations', getNavigationFromJSON)
  );
}

export function getTrackingObject(jsonObj: StringObj, prop: string): TrackingObject {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('TrackingObject', prop);
  assertObject(val);
  return getTrackingObjectFromJSON(val);
}


////////////////// 2. Classes defining jsonRpc methods returning data value objects /////////////////////////
/*
    These classes are responsible for deserialize and serialize from and to toJSON
    into the respectfull objects needed.
    AND
    Are responsible for knowing exact method and url endpoint to call with json RPC
*/

/**     % report a enduser app tracking
Responsible: ASHA */
export class ReportTracking extends WebRequest<boolean> {
  public webService = 'tracking' as const;
  public method = 'ReportTracking' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public tracking: TrackingObject,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<boolean>): boolean {
    return getBooleanFromJSON(json.result);
  }
}

