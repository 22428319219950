import './Item.scss';

import React from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { makeKeyDown } from '@pdcfrontendui/utils';

type ItemProps = {
  onClick?: () => void;
  className?: string;
  selected?: boolean;
  id?: string;
  children?: ReactNode;
  ariaLabel?: string;
};

const Item = ({
  className,
  selected,
  id,
  onClick,
  children,
  ariaLabel,
}: ItemProps) => (
  <div
    className={classNames('item-component', className, {
      selected,
    })}
    id={id}
    onClick={() => onClick?.()}
    onKeyDown={makeKeyDown(onClick)}
    tabIndex={onClick ? 0 : undefined}
    role={onClick ? 'button' : undefined}
    aria-selected={selected}
    aria-label={ariaLabel}
  >
    {children}
  </div>
);

export default Item;

