import { DayMessageMap, getChatKey } from '../util/getChatKey';
import { MsgVisibilityOldEnum, UserLoginTypeEnum } from '../api/enumLib_api';
import { messageIsFromMe, shouldShowAsUnread } from '../Chat/chatUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DayMessage } from '../api/Message_api';
import type { Message } from '@pdcfrontendui/components';
import { currentLanguage } from '../currentLanguage';
import { store } from '../storeConfiguration';
import { uuid } from '../util/uuid';

function messageArraysDiffer(a: Message[], b: Message[]) {
  // length should be sufficient to check if the arrays differ, but we also check the latest message to be sure
  return (
    a.length !== b.length || a[a.length - 1]?.text !== b[b.length - 1]?.text
  );
}

function useMessages(
  filteredMessages: Message[],
  personId: number,
  internal: boolean,
  date: Date,
  useToggle: boolean,
  callPostMessage: (
    personId: number,
    messageText: string,
    isInternalMessage: boolean,
    date: Date
  ) => void
) {
  // null means the messages are hidden (chat not shown)
  const [messages, setMessages] = useState<Message[] | null>(
    useToggle ? null : filteredMessages
  );

  const toggleShowMessages = useCallback(() => {
    setMessages((messages) => (messages ? null : filteredMessages));
  }, [filteredMessages]);

  const sendMessage = (text: string) => {
    callPostMessage(personId, text, internal, date);
    // Add message to the list so as to not get a delay in the UI after sending a message
    setMessages((messages) =>
      messages
        ? [
            ...messages,
            {
              fromMe: true,
              // sender.name is not shown when fromMe is true, but it is required by the typing
              sender: { key: uuid(), name: currentLanguage.MeAlt },
              text,
              timestamp:
                store.getState().appReducer.globalSettings.useDateTime ??
                new Date(),
              privateText: internal
                ? currentLanguage.InternalMessage
                : undefined,
            },
          ]
        : null
    );
  };

  // Update messages when they are different (if an error occurs, for example)
  useEffect(() => {
    setMessages((messages) =>
      messages && messageArraysDiffer(messages, filteredMessages)
        ? filteredMessages
        : messages
    );
  }, [filteredMessages]);

  return { messages, toggleShowMessages, sendMessage };
}

function pushMessage(
  userId: number,
  messages: Message[],
  unreadIds: number[],
  dayMessage: DayMessage
) {
  // Convert the api typing to match the message typing from the component library.
  messages.push({
    fromMe: messageIsFromMe(dayMessage, userId),
    text: dayMessage.MessageText,
    timestamp: dayMessage.SentWhen,
    sender: {
      key: `${dayMessage.FromKey.UserId}`,
      name:
        dayMessage.FromKey.UserType === UserLoginTypeEnum.planner
          ? `${dayMessage.FromName} (${currentLanguage.Planner.toLowerCase()})`
          : dayMessage.FromName,
    },
    privateText:
      dayMessage.Visibility === MsgVisibilityOldEnum.planner
        ? currentLanguage.InternalMessage
        : undefined,
  });
  if (shouldShowAsUnread(dayMessage, userId)) {
    unreadIds.push(dayMessage.MessageId);
  }
}

export default function useNotesAndMessages(
  userId: number,
  dayMessageMap: DayMessageMap,
  currentDate: Date,
  personId: number,
  useToggle: boolean,
  callMarkMessagesAsRead: (messageIds: number[]) => void,
  callPostMessage: (
    personId: number,
    messageText: string,
    isInternalMessage: boolean,
    date: Date
  ) => void,
  Visibility?: MsgVisibilityOldEnum
) {
  const dayMessages = useMemo(() => {
    const messages =
      dayMessageMap[
        getChatKey(personId, currentDate, MsgVisibilityOldEnum.employee)
      ];
    const privateMessages =
      dayMessageMap[
        getChatKey(personId, currentDate, MsgVisibilityOldEnum.planner)
      ];

    const allMessages = (messages ?? ([] as DayMessage[])).concat(
      privateMessages ?? []
    );

    // Message timestamps only have 1 minute precision, so we sort by id to get the correct order (since ids are ordered)
    return [...allMessages].sort((a, b) => a.MessageId - b.MessageId);
  }, [dayMessageMap, personId, currentDate]);

  const data = useMemo(() => {
    const messages: Message[] = [];
    const internalMessages: Message[] = [];
    // Unread id arrays used for marking as read. So they need to only contain ids that can be mark as read.
    const unreadIds: number[] = [];
    const internalUnreadIds: number[] = [];

    for (const dayMessage of dayMessages) {
      // If Visibility is MsgVisibilityOldEnum.planner, the message is internal
      if (dayMessage.Visibility === MsgVisibilityOldEnum.planner) {
        pushMessage(userId, internalMessages, internalUnreadIds, dayMessage);
      } else {
        pushMessage(userId, messages, unreadIds, dayMessage);
      }
    }

    const hasUnreadMessages = unreadIds.length > 0;
    const hasUnreadInternalMessages = internalUnreadIds.length > 0;

    const markMessagesAsRead = () => {
      if (hasUnreadMessages) {
        callMarkMessagesAsRead(unreadIds);
      }
    };
    const markInternalMessagesAsRead = () => {
      if (hasUnreadInternalMessages) {
        callMarkMessagesAsRead(internalUnreadIds);
      }
    };
    return {
      messages,
      markMessagesAsRead,
      hasUnreadMessages,

      internalMessages,
      markInternalMessagesAsRead,
      hasUnreadInternalMessages,
    };
  }, [dayMessages, userId, callMarkMessagesAsRead]);

  const { messages, toggleShowMessages, sendMessage } = useMessages(
    Visibility === MsgVisibilityOldEnum.planner
      ? data.internalMessages
      : data.messages,
    personId,
    Visibility === MsgVisibilityOldEnum.planner,
    currentDate,
    useToggle,
    callPostMessage
  );

  const {
    messages: internalMessages,
    toggleShowMessages: toggleShowInternalMessages,
    sendMessage: sendInternalMessage,
  } = useMessages(
    data.internalMessages,
    personId,
    true,
    currentDate,
    useToggle,
    callPostMessage
  );

  return {
    messages,
    numMessages: data.messages.length,
    hasUnreadMessages: data.hasUnreadMessages,
    markMessagesAsRead: data.markMessagesAsRead,
    toggleShowMessages,
    sendMessage,

    internalMessages,
    numInternalMessages: data.internalMessages.length,
    hasUnreadInternalMessages: data.hasUnreadInternalMessages,
    markInternalMessagesAsRead: data.markInternalMessagesAsRead,
    toggleShowInternalMessages,
    sendInternalMessage,
  };
}
