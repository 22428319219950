import './Card.scss';

import Icons, { IconSize } from '@pdcfrontendui/components/Icons';

import Badge from './Badge';
import Pill from './Pill';
import React from 'react';

type CardProps = {
  label: string;
  badge: number;
  newBadge: boolean;
  id?: string;
  children?: React.ReactNode;
  onClick?: () => void;
};
const Card = ({ children, id, label, badge, newBadge, onClick }: CardProps) => {
  return (
    <div
      className="card"
      id={id}
      onClick={onClick}
      role={onClick && 'button'}
      tabIndex={onClick && 0}
    >
      <div>
        {children}
        {label}
        <Badge amount={badge} className="silent" />
        {newBadge && <Pill />}
      </div>
      <Icons.ChevronRight size={IconSize.XXXSmall} />
    </div>
  );
};
export default Card;
