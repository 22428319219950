﻿/* Fully Generated from generatedWEBSERVICE_Api.ts */

//===============================================================
//  Auto Generated Code for Calendar
//===============================================================
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-useless-constructor */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-extraneous-class */

/* This file contains, in this order:
 *   1. Data value objects
 *   2. Classes defining jsonRpc methods returning data value objects
 */

import { type WebResponseResult } from '@pdcfrontendui/staffplan';
import { WebRequest } from './WebRequest';
import {
  WebTypeViewField,
  getWebTypeViewFieldFromJSON,
  getWebTypeViewField,
  WebField,
  getWebFieldFromJSON,
  getWebField,
  WebRecord,
  getWebRecordFromJSON,
  getWebRecord,
  WebList,
  getWebListFromJSON,
  getWebList,
  WebElement,
  getWebElement,
  LogonSettings,
  getLogonSettingsFromJSON,
  getLogonSettings,
  WebSortConfig,
  getWebSortConfigFromJSON,
  getWebSortConfig,
  WebFieldConfig,
  getWebFieldConfigFromJSON,
  getWebFieldConfig,
  WebListConfig,
  getWebListConfigFromJSON,
  getWebListConfig,
  WebRecordType,
  getWebRecordTypeFromJSON,
  getWebRecordType,
  WsRefPerson,
  getWsRefPerson,
  WsRefOrgunit,
  getWsRefOrgunit,
  WsRefOrgunitByInfo,
  getWsRefOrgunitByInfo,
  WsRefShiftCounter,
  getWsRefShiftCounter,
  WsRefShifttype,
  getWsRefShifttype,
  WsRefTaskDef,
  getWsRefTaskDef,
  WsRefTask,
  WsRefAccount,
  WsRefProject,
  WsRefDimensionItem,
  getWsRefAccount,
  getWsRefProject,
  getWsRefDimensionItem,
  getWsRefTask,
} from './Common_api';

import * as Enums from './enumLib_api'
import {
  assertObject,
  getNullable,
  getEnumFromJSON,
  getEnum,
  getNullableEnum,
  getEnumArray,
  getNullableEnumArray,
  getArray,
  getNullableArray,
  getEnumArrayArray,
  getNullableEnumArrayArray,
  getArrayArray,
  getNullableArrayArray,
  getInt,
  getIntFromJSON,
  getReal,
  getRealFromJSON,
  getBoolean,
  getBooleanFromJSON,
  getString,
  getStringFromJSON,
  getDate,
  getDateFromJSON,
  isNullish,
  DomainWsIntervalType,
  DomainWsVariantObject,
  DomainWsVariantAvailability,
  DomainJsonObject,
  DomainWspdclasttrans,
  DomainWscommitid,
  getDomainJsonObject,
  getDomainJsonObjectFromJSON,
  getDomainWsVariantObject,
  getDomainWsVariantObjectFromJSON,
  getDomainWsVariantAvailability,
  getDomainWsVariantAvailabilityFromJSON,
  getDomainWsIntervalType,
  getDomainWsIntervalTypeFromJSON,
  getDomainWspdclasttrans,
  getDomainWscommitid,
  getDomainWscommitidFromJSON,
  nullishError,
  type StringObj,
} from './validators';

////////////////// 1. Data value objects /////////////////////////
// These are the actual data value objects used in typescript

// COMMENT IS MISSING!!
//  Responsible ERLE
export class Holiday {
  [key: string]: unknown;
  constructor(
    public Date: Date,
    public Label: string,
    public Type: number) {}
}

export function getHolidayFromJSON(jsonObj: StringObj): Holiday {
  return new Holiday(

      getDate(jsonObj, 'Date'),
    /* 80 */
      getString(jsonObj, 'Label'),

      getInt(jsonObj, 'Type')
  );
}

export function getHoliday(jsonObj: StringObj, prop: string): Holiday {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('Holiday', prop);
  assertObject(val);
  return getHolidayFromJSON(val);
}

// COMMENT IS MISSING!!
//  Responsible ERLE
export class HolidayResponse {
  [key: string]: unknown;
  constructor(
    public Holidays: Holiday[]) {}
}

export function getHolidayResponseFromJSON(jsonObj: StringObj): HolidayResponse {
  return new HolidayResponse(

    getArray(jsonObj, 'Holidays', getHolidayFromJSON)
  );
}

export function getHolidayResponse(jsonObj: StringObj, prop: string): HolidayResponse {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('HolidayResponse', prop);
  assertObject(val);
  return getHolidayResponseFromJSON(val);
}


////////////////// 2. Classes defining jsonRpc methods returning data value objects /////////////////////////
/*
    These classes are responsible for deserialize and serialize from and to toJSON
    into the respectfull objects needed.
    AND
    Are responsible for knowing exact method and url endpoint to call with json RPC
*/

/**     % Returns the list of holidays (not scoped in time)
Responsible: ERLE */
export class GetHolidays extends WebRequest<HolidayResponse> {
  public webService = 'calendar' as const;
  public method = 'GetHolidays' as const;
  public noUserValidation = true as const;
  public noAuditAction = true as const;
  constructor(
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<HolidayResponse>): HolidayResponse {
    return getHolidayResponseFromJSON(json.result);
  }
}

