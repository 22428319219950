import {
  ExchangeCandidateFilterEnum,
  LogonTypeEnum,
  SwapIsUsedEnum,
  UseDateTimeTypeEnum,
} from './api/enumLib_api';
import { TeamShiftDef, UserSettings } from './api/TeamPlan_api';

import { ActionType } from './ActionType';
import { AppAction } from './appActions';
import { CommonAction } from './commonAction';
import { ConfirmDialogProps } from './components/ConfirmDialog/ConfirmDialog';
import type { DayMessageMap } from './util/getChatKey';
import { GetVersionModel, toIdMap } from './api/model';
import { Holiday } from './api/Calendar_api';
import { InfoDialogProps } from './components/InfoDialog/InfoDialog';
import { LocalStorageKey } from './util/LocalStorageKey';
import { ModalWrapperProps } from './Modal/ModalWrapper';
import { PushNotificationAction } from './api/PushNotificationActions';
import { Reducer } from 'redux';
import { ToastType } from '@pdcfrontendui/components/Toast/Toast';
import { dateMath } from '@pdcfrontendui/utils';
import { localStorageGet } from './util/localStorage';
import { GlobalSettings } from './api/Common_api';

export type AppReducer = {
  screensizeBig: boolean;
  shouldShowCalendar: boolean;
  shouldShowSearchBar: boolean;
  shouldShowMenu: boolean;
  modalLoading: boolean;
  /**
   * activeDate is always 00:00:00 for a given day
   */
  activeDate: Date;
  holidays: Holiday[];
  version: GetVersionModel | null;
  globalSettings: GlobalSettings;
  userSettings: UserSettings;
  openModals: ModalWrapperProps[];
  errorMessage?: string;
  dayMessageMap: DayMessageMap;
  unreadCountMap: Record<string, number>;
  loadingGetVersion: boolean;
  loadingGetSettings: boolean;
  toast: ToastType | null;
  confirmDialog: ConfirmDialogProps;
  infoDialog: InfoDialogProps;
  errorModal: { error: any; shown: boolean };
  showingRuleViolationInfo: boolean;
  /**
   * Key is TeamShiftDef.id
   * We don't need to scope this to a team, since all team shift def ids are unique
   */
  shiftDefMap: Record<string, TeamShiftDef>;
};

const initialDate = dateMath.floorDay(new Date());

const initialState: AppReducer = {
  screensizeBig: false,
  shouldShowCalendar: false,
  shouldShowSearchBar: false,
  shouldShowMenu: !localStorageGet(LocalStorageKey.currentTeam),
  modalLoading: false,
  activeDate: initialDate,
  holidays: [],
  version: null,
  globalSettings: {
    useDateTime: initialDate,
    useDateTimeType: UseDateTimeTypeEnum.unfrozen,
    idleLogoutSeconds: 300,
    logonMethod: LogonTypeEnum.staffWebUser,
    swapIsUsed: SwapIsUsedEnum.no,
    defaultWorkSalarysort: 'ERROR',
    openIdAuthorizationEndpoint: null,
    openIdClientId: null,
    openIdRedirectUriEmployee: null,
    openIdRedirectUriPlanner: null,
    openIdScopeEmployee: null,
    openIdScopePlanner: null,
    openIdAcrValuesEmployee: null,
    openIdAcrValuesPlanner: null,
    openIdResponseType: null,
    openIdResource: null,
    openIdLogoutEndpoint: null,
    possibleCandidateFilters: [],
  },
  userSettings: {
    defaultExchangeCandidateFilter: ExchangeCandidateFilterEnum.classic,
  },
  openModals: [],
  dayMessageMap: {},
  unreadCountMap: {},
  loadingGetSettings: false,
  loadingGetVersion: false,
  toast: null,
  confirmDialog: {
    buttonCancelText: '',
    buttonOkText: '',
    message: '',
    onCancel: undefined,
    onOk: () => undefined,
    shown: false,
    iconKey: undefined,
    padding: undefined,
    title: '',
    variant: undefined,
  },
  infoDialog: {
    buttonText: '',
    message: '',
    onOk: () => undefined,
    shown: false,
    buttonId: undefined,
    padding: undefined,
    title: undefined,
    variant: undefined,
    buttonStyle: undefined,
  },
  errorModal: {
    shown: false,
    error: null,
  },
  showingRuleViolationInfo: false,
  shiftDefMap: {},
};

const appReducer: Reducer<AppReducer> = (
  state: AppReducer = initialState,
  action: AppAction | PushNotificationAction | CommonAction
) => {
  switch (action.type) {
    case ActionType.INIT:
      return {
        ...initialState,
        globalSettings: state.globalSettings,
        version: state.version,
        shouldShowMenu: !localStorageGet(LocalStorageKey.currentTeam),
        screensizeBig: state.screensizeBig,
      };
    case ActionType.TOGGLE_SCREEN_SMALL: {
      return {
        ...state,
        screensizeBig: false,
      };
    }
    case ActionType.TOGGLE_SCREEN_BIG:
      return {
        ...state,
        screensizeBig: true,
      };

    case ActionType.CHANGE_DATE: {
      return {
        ...state,
        activeDate: action.date,
      };
    }
    case ActionType.TOGGLE_CALENDAR:
      return {
        ...state,
        shouldShowCalendar: !state.shouldShowCalendar,
      };
    case ActionType.TOGGLE_SEARCHBAR:
      return {
        ...state,
        shouldShowSearchBar: !state.shouldShowSearchBar,
      };
    case ActionType.TOGGLE_MENU:
      return {
        ...state,
        shouldShowMenu: !state.shouldShowMenu,
      };
    case ActionType.SET_MODALLOADING:
      return {
        ...state,
        modalLoading: action.modalLoading,
      };
    case ActionType.OPEN_MODAL: {
      const openModals = [...state.openModals];
      openModals.push(action.modal);
      return {
        ...state,
        openModals,
      };
    }
    case ActionType.CLOSE_MODAL: {
      const openModals = [...state.openModals];
      openModals.pop();
      return {
        ...state,
        openModals,
      };
    }
    case ActionType.GETVERSION_REQUEST:
      return { ...state, loadingGetVersion: true };
    case ActionType.GETVERSION_SUCCESS:
      return {
        ...state,
        version: action.getVersionResponse,
        loadingGetVersion: false,
      };
    case ActionType.GETVERSION_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loadingGetVersion: false,
      };
    case ActionType.SET_VERSION:
      return {
        ...state,
        version: action.version,
        loadingGetVersion: false,
      };
    case ActionType.GETGLOBALSETTINGS_REQUEST:
      return { ...state, loadingGetSettings: true };
    case ActionType.GETGLOBALSETTINGS_SUCCESS: {
      const useDateTime =
        action.globalSettings.useDateTime ?? dateMath.floorDay(new Date());
      return {
        ...state,
        globalSettings: {
          ...action.globalSettings,
          useDateTime,
          useDateTimeType:
            action.globalSettings.useDateTimeType ??
            UseDateTimeTypeEnum.frozenTime,
        },
        activeDate: dateMath.floorDay(useDateTime), // set activeDate to the useDateTime whenever get settings is called
        loadingGetSettings: false,
      };
    }
    case ActionType.GETGLOBALSETTINGS_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loadingGetSettings: false,
      };
    case ActionType.GETUSERSETTINGS_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case ActionType.GETUSERSETTINGS_SUCCESS:
      return {
        ...state,
        userSettings: {
          ...action.userSettings,
        },
      };
    // Push notifications
    case ActionType.PUSHNOTIFICATION_REGISTER_SUCCESS:
      return {
        ...state,
      };
    case ActionType.PUSHNOTIFICATION_REGISTER_ERROR:
      return {
        ...state,
      };
    case ActionType.PUSHNOTIFICATION_RECEIVED:
      return {
        ...state,
      };
    case ActionType.PUSHNOTIFICATION_ERROR:
      return {
        ...state,
      };
    case ActionType.GETMESSAGES_SUCCESS:
      return {
        ...state,
        dayMessageMap: {
          ...state.dayMessageMap,
          ...action.dayMessageMap,
        },
      };
    case ActionType.GETUNREADMESSAGES_SUCCESS:
      return {
        ...state,
        unreadCountMap: { ...state.unreadCountMap, ...action.unreadCountMap },
      };
    case ActionType.POSTMESSAGE_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case ActionType.GOT_HOLIDAYS: {
      return { ...state, holidays: action.holidays };
    }
    case ActionType.QUEUE_TOAST: {
      return { ...state, toast: action.toast };
    }
    case ActionType.SHOW_CONFIRM_DIALOG:
      return {
        ...state,
        confirmDialog: { ...action.payload, shown: true },
        infoDialog: { ...state.infoDialog, shown: false },
      };
    case ActionType.HIDE_CONFIRM_DIALOG:
      return {
        ...state,
        confirmDialog: { ...state.confirmDialog, shown: false },
      };
    case ActionType.SHOW_INFO_DIALOG:
      return {
        ...state,
        infoDialog: { ...action.payload, shown: true },
        confirmDialog: { ...state.confirmDialog, shown: false },
      };
    case ActionType.HIDE_INFO_DIALOG:
      return {
        ...state,
        infoDialog: { ...state.infoDialog, shown: false },
      };
    case ActionType.SHOW_ERROR_MODAL:
      return {
        ...state,
        errorModal: { error: action.error, shown: true },
      };
    case ActionType.HIDE_ERROR_MODAL:
      return {
        ...state,
        errorModal: { ...state.errorModal, shown: false },
      };
    case ActionType.SET_SHIFTDEFMAP:
      return {
        ...state,
        shiftDefMap: toIdMap(action.data),
      };
    case ActionType.SET_SHOWING_RULE_VIOLATION_INFO:
      return {
        ...state,
        showingRuleViolationInfo: action.payload,
      };
  }
  return state;
};

export default appReducer;

