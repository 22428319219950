import { combineReducers } from 'redux';
import appReducer, { AppReducer } from './AppReducer';
import chooseTeamReducer, {
  ChooseTeamReducer,
} from './ChooseTeam/ChooseTeamReducer';
import findSubstituteViewReducer, {
  FindSubstituteViewReducer,
} from './FindSubstituteView/FindSubstituteViewReducer';
import listViewReducer, { ListViewReducer } from './ListView/ListViewReducer';
import loginReducer, { LoginReducer } from './Login/LoginReducer';
import menuReducer, { MenuReducer } from './Menu/MenuReducer';
import settingsReducer, { SettingsReducer } from './Settings/SettingsReducer';
import shiftViewReducer, {
  ShiftViewReducer,
} from './ShiftView/ShiftViewReducer';

import { Prettify } from '@pdcfrontendui/types';
import React from 'react';
import type { ThunkDispatch } from 'redux-thunk';
import { connect as _connect } from 'react-redux';

/**
 * Typed connect function that allows you to connect a component to the redux store.
 */
export const connect = <
  StateToProps extends object,
  DispatchToProps extends object = object,
  OwnProps extends object = object
>(
  mapStateToProps: (
    state: Store,
    ownPropsAndRouterState: Prettify<OwnProps>
  ) => StateToProps,
  mapDispatchToProps: (
    dispatch: ThunkDispatch<Store, void, any>,
    ownProps: Prettify<OwnProps>
  ) => DispatchToProps
) => {
  return <
    W extends (
      props: Prettify<StateToProps & DispatchToProps & OwnProps>
    ) => React.ReactNode
  >(
    Component: W
  ) => {
    return (
      _connect(mapStateToProps, mapDispatchToProps) as unknown as (
        component: W
      ) => (props: OwnProps) => ReturnType<W>
    )(Component);
  };
};

export type Store = {
  loginReducer: LoginReducer;
  chooseTeamReducer: ChooseTeamReducer;
  settingsReducer: SettingsReducer;
  listViewReducer: ListViewReducer;
  shiftViewReducer: ShiftViewReducer;
  findSubstituteViewReducer: FindSubstituteViewReducer;
  appReducer: AppReducer;
  menuReducer: MenuReducer;
};

const rootReducer = combineReducers<Store>({
  chooseTeamReducer,
  findSubstituteViewReducer,
  listViewReducer,
  loginReducer,
  settingsReducer,
  shiftViewReducer,
  appReducer,
  menuReducer,
});

export default rootReducer;

