import './ModalWrapper.scss';

import * as React from 'react';

import Button, { ButtonStyle } from '@pdcfrontendui/components/Button';
import Modal, { ModalVariant } from '@pdcfrontendui/components/Modal';

import { Align } from '@pdcfrontendui/components/ButtonContainer';
import ErrorBoundaryContainer from '../sharedComponents/ErrorBoundaryContainer';
import { GetVersionModel } from '../api/model';
import Spinner from '@pdcfrontendui/components/Spinner';
import { currentLanguage } from '../currentLanguage';
import ids from '../testing/ids';

export type ModalWrapperProps = {
  version?: GetVersionModel | null;
  content?: string | React.ReactNode;
  shouldShow?: boolean;
  modalType: ModalType | null;
  modalBackText?: string;
  modalBtn?: {
    confirmButtonText: string;
    onModalConfirm: () => void;
  };
  loading?: boolean;
  disableBackOnLoad?: boolean;
};

export type DispatchFromProps = {
  onClose: () => void;
};
export enum ModalType {
  offer = 'offer',
  cancelOffer = 'cancelOffer',
  markAsHandled = 'markAsHandled',
  shiftAssignment = 'shiftAssignment',
  rejectShiftAssignment = 'rejectShiftAssignment',
  shiftExchange = 'shiftExchange',
  rejectShiftExchange = 'rejectShiftExchange',
  info = 'info',
  error = 'error',
  notification = 'notification',
  chatUnread = 'chatUnread',
  chatRead = 'chatRead',
}
const ModalWrapper = ({
  onClose,
  version,
  content,
  shouldShow,
  modalType,
  modalBtn,
  loading,
  disableBackOnLoad = false,
  modalBackText = currentLanguage.back,
}: DispatchFromProps & ModalWrapperProps) => {
  const getModalHeader = (type: ModalType | null) => {
    switch (type) {
      case ModalType.offer:
        return currentLanguage.ConfirmOfferOnShiftExchange;
      case ModalType.cancelOffer:
        return currentLanguage.ConfirmCancellationOfOffer;
      case ModalType.markAsHandled:
        return currentLanguage.ConfirmMarkAsHandled;
      case ModalType.shiftAssignment:
        return currentLanguage.ConfirmShiftAssignment;
      case ModalType.rejectShiftAssignment:
        return currentLanguage.RejectShiftAssignment;
      case ModalType.shiftExchange:
        return currentLanguage.ConfirmShiftExchange;
      case ModalType.rejectShiftExchange:
        return currentLanguage.RejectShiftExchange;
      case ModalType.info:
        return currentLanguage.appInformation;
      case ModalType.error:
        return currentLanguage.Error;
      case ModalType.notification:
        return currentLanguage.notification;
      case ModalType.chatUnread:
        return currentLanguage.HowManyUnread;
      case ModalType.chatRead:
        return currentLanguage.HowManyRead;
      default:
        return 'Unknown Modal';
    }
  };
  const getAppInformationContent = (): React.ReactNode => {
    if (!version) {
      return currentLanguage.noAppInformation;
    }
    const versionTableContent: React.ReactNode[] = [];
    for (const versionPropName of Object.keys(version)) {
      const versionText = version[versionPropName] || 'undefined';
      versionTableContent.push(
        <div className="row" key={versionPropName}>
          <div className="heading">
            {versionPropName.toString().charAt(0).toUpperCase() +
              versionPropName.slice(1)}
          </div>
          <div className="cell">{versionText.toString()}</div>
        </div>
      );
    }
    return <div className="version-table">{versionTableContent}</div>;
  };
  const getModalContent = (type: ModalType | null) => {
    switch (type) {
      case ModalType.offer:
        return currentLanguage.ConfirmOfferOnShiftExchangeContent;
      case ModalType.cancelOffer:
        return currentLanguage.ConfirmCancellationOfOfferContent;
      case ModalType.markAsHandled:
        return currentLanguage.ConfirmMarkAsHandledContent;
      case ModalType.info:
        return getAppInformationContent();
      case ModalType.error:
      case ModalType.notification:
      case ModalType.shiftAssignment:
      case ModalType.shiftExchange:
      case ModalType.rejectShiftAssignment:
      case ModalType.rejectShiftExchange:
        return content;
      default:
        return content;
        break;
    }
  };
  const variant =
    modalType === ModalType.cancelOffer || modalType === ModalType.error
      ? ModalVariant.Alert
      : ModalVariant.Dialog;
  const visible = typeof shouldShow === 'undefined' || shouldShow;
  return (
    <ErrorBoundaryContainer>
      <Modal shown={visible} variant={variant} className="modal">
        {variant === ModalVariant.Alert ? (
          <Modal.Icon className="modal-icon" iconKey="ExclamationFill" />
        ) : (
          <></>
        )}
        <Modal.Header>{getModalHeader(modalType)}</Modal.Header>
        <Modal.Body className="modal-body">
          {getModalContent(modalType)}
        </Modal.Body>
        <Modal.Buttons align={Align.Right}>
          <Button
            id={ids.Modal.ModalBackButton}
            onClick={() => {
              onClose();
            }}
            variant={ButtonStyle.Secondary}
            danger={variant === ModalVariant.Alert}
            disabled={disableBackOnLoad && loading}
          >
            {modalBackText}
          </Button>
          {modalBtn && loading ? (
            <Button
              id={ids.Modal.ModalConfirmButton}
              onClick={() => modalBtn.onModalConfirm()}
              variant={ButtonStyle.Primary}
              danger={variant === ModalVariant.Alert}
            >
              <Spinner />
            </Button>
          ) : modalBtn ? (
            <Button
              id={ids.Modal.ModalConfirmButton}
              onClick={() => modalBtn.onModalConfirm()}
              variant={ButtonStyle.Primary}
              danger={variant === ModalVariant.Alert}
            >
              {modalBtn.confirmButtonText || ''}
            </Button>
          ) : null}
        </Modal.Buttons>
      </Modal>
    </ErrorBoundaryContainer>
  );
};

export default ModalWrapper;

