import { ActionType } from './ActionType';

export type CommonAction =
  | {
      type: ActionType.INIT;
    }
  | {
      type: ActionType.RESUME_SESSION;
    };

export const init = (): CommonAction => ({
  type: ActionType.INIT,
});

export const resumeSession = (): CommonAction => ({
  type: ActionType.RESUME_SESSION,
});

