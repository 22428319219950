import { ErrorBoundary, ErrorBoundaryProps } from '../components';

import { Store } from '../rootReducer';
import { ThunkDispatch } from '../types';
import { attemptSendNotificationLog } from '../appActions';
import { connect } from 'react-redux';

const mapStateToProps = (_store: Store) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch): ErrorBoundaryProps => ({
  logError: (error: Error, errorInfo: React.ErrorInfo) => {
    dispatch(attemptSendNotificationLog(error, errorInfo));
  },
});

export default connect<{}, ErrorBoundaryProps, {}, Store>(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBoundary);
