import { CommonAction } from '../commonAction';
import { Reducer } from 'redux';
import { MenuAction } from './MenuActions';
import { ListViewAction } from '../ListView/ListViewActions';

export type MenuItemLoadedType = Record<
  string,
  { favoriteStatus: boolean; notification: boolean; download: boolean }
>;

export type MenuReducer = {
  menuItemLoading: MenuItemLoadedType;
};

const initialState: MenuReducer = {
  menuItemLoading: {},
};

const listViewReducer: Reducer<MenuReducer> = (
  state: MenuReducer = initialState,
  action: MenuAction | ListViewAction | CommonAction
) => {
  switch (action.type) {
    case 'SET_FAVORITE_STATUS':
      return {
        ...state,
        menuItemLoading: {
          ...state.menuItemLoading,
          [action.teamId]: {
            favoriteStatus: true,
            download: state.menuItemLoading[action.teamId]?.download ?? false,
            notification:
              state.menuItemLoading[action.teamId]?.notification ?? false,
          },
        },
      };

    case 'SET_FAVORITE_STATUS_SUCCESS':
    case 'SET_FAVORITE_STATUS_FAILURE':
      return {
        ...state,
        menuItemLoading: {
          ...state.menuItemLoading,
          [action.teamId]: {
            favoriteStatus: false,
            download: state.menuItemLoading[action.teamId]?.download ?? false,
            notification:
              state.menuItemLoading[action.teamId]?.notification ?? false,
          },
        },
      };

    case 'SET_NOTIFICATION_STATUS':
      return {
        ...state,
        menuItemLoading: {
          ...state.menuItemLoading,
          [action.teamId]: {
            favoriteStatus:
              state.menuItemLoading[action.teamId]?.favoriteStatus ?? false,
            download: state.menuItemLoading[action.teamId]?.download ?? false,
            notification: true,
          },
        },
      };

    case 'SET_NOTIFICATION_STATUS_SUCCESS':
    case 'SET_NOTIFICATION_STATUS_FAILURE':
      return {
        ...state,
        menuItemLoading: {
          ...state.menuItemLoading,
          [action.teamId]: {
            favoriteStatus:
              state.menuItemLoading[action.teamId]?.favoriteStatus ?? false,
            download: state.menuItemLoading[action.teamId]?.download ?? false,
            notification: false,
          },
        },
      };

    case 'LOADTEAMROSTER_REQUEST':
      return {
        ...state,
        menuItemLoading: {
          ...state.menuItemLoading,
          [action.id]: {
            favoriteStatus:
              state.menuItemLoading[action.id]?.favoriteStatus ?? false,
            download: true,
            notification:
              state.menuItemLoading[action.id]?.notification ?? false,
          },
        },
      };

    case 'LOADTEAMROSTER_SUCCESS':
      return {
        ...state,
        menuItemLoading: {
          ...state.menuItemLoading,
          [action.teamId]: {
            favoriteStatus:
              state.menuItemLoading[action.teamId]?.favoriteStatus ?? false,
            download: false,
            notification:
              state.menuItemLoading[action.teamId]?.notification ?? false,
          },
        },
      };

    case 'LOADTEAMROSTERMULTI_REQUEST':
      const newMenuItemLoading = { ...state.menuItemLoading };

      action.ids.forEach((id) => {
        newMenuItemLoading[id] = {
          favoriteStatus: state.menuItemLoading[id]?.favoriteStatus ?? false,
          download: true,
          notification: state.menuItemLoading[id]?.notification ?? false,
        };
      });

      return {
        ...state,
        menuItemLoading: newMenuItemLoading,
      };
  }

  return state;
};

export default listViewReducer;
