import './ConfirmOfferModal.scss';

import {
  Button,
  ButtonStyle,
  Modal,
  SelectInput,
  SelectOption,
} from '@pdcfrontendui/components';
import React, { ChangeEvent } from 'react';

import { ExchangeCandidateFilterEnum } from '../api/enumLib_api';
import { currentLanguage } from '../currentLanguage';

const scss = {
  Comp: 'ConfirmOfferModal',
  body: 'ConfirmOfferModalBody',
  candiateSelect: 'candidate-select',
  helperText: 'helper-text',
};

type Props = {
  open: boolean;
  selectedGroupCount: number;
  possibleCandidateFilters: ExchangeCandidateFilterEnum[];
  defaultExchangeCandidateFilter: ExchangeCandidateFilterEnum;
  selectedCandidateFilter: ExchangeCandidateFilterEnum | null;
  onCancel: () => void;
  onAccept: (candidateFilter: ExchangeCandidateFilterEnum) => void;
  updateSelectedCandidateFilter: (
    candidateFilter: ExchangeCandidateFilterEnum
  ) => void;
};

const ConfirmOfferModal = ({
  open,
  selectedGroupCount,
  possibleCandidateFilters,
  defaultExchangeCandidateFilter,
  selectedCandidateFilter,
  onCancel,
  onAccept,
  updateSelectedCandidateFilter,
}: Props) => {
  const selectOptions: SelectOption<ExchangeCandidateFilterEnum | 'none'>[] =
    possibleCandidateFilters.map((filter) => {
      const label = (() => {
        switch (filter) {
          case ExchangeCandidateFilterEnum.classic:
            return currentLanguage.exchangeCandidateFilter_classic;
          case ExchangeCandidateFilterEnum.callinEsque:
            return currentLanguage.exchangeCandidateFilter_callinEsque;
          default:
            return currentLanguage.exchangeCandidateFilter_noCallin;
        }
      })();

      return {
        key: filter,
        value: filter.toString(),
        label: label,
      };
    });

  return (
    <div className={scss.Comp}>
      <Modal shown={open}>
        <Modal.Header>
          {currentLanguage.ConfirmOfferOnShiftExchange}
        </Modal.Header>
        <Modal.Body className={scss.body}>
          {possibleCandidateFilters.length > 1 ? (
            selectedGroupCount === 1 ? (
              <div>
                {currentLanguage.ConfirmOfferOnShiftExchangeCandidateFilter}
              </div>
            ) : (
              <div>
                {currentLanguage.ConfirmOfferOnShiftExchangeCandidateFilter_1(
                  selectedGroupCount
                )}
              </div>
            )
          ) : selectedGroupCount === 1 ? (
            <div>{currentLanguage.ConfirmOfferOnShiftExchangeAllAvailable}</div>
          ) : (
            <div>
              {currentLanguage.ConfirmOfferOnShiftExchangeAllAvailable_1(
                selectedGroupCount
              )}
            </div>
          )}
          {possibleCandidateFilters.length > 1 && (
            <>
              <SelectInput<ExchangeCandidateFilterEnum | 'none'>
                className={scss.candiateSelect}
                label={currentLanguage.exchangeCandidateFilter}
                value={
                  ExchangeCandidateFilterEnum[
                    selectedCandidateFilter ?? defaultExchangeCandidateFilter
                  ]
                }
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  const candidateFilter: ExchangeCandidateFilterEnum =
                    ExchangeCandidateFilterEnum[
                      e.currentTarget
                        .value as keyof typeof ExchangeCandidateFilterEnum
                    ];
                  updateSelectedCandidateFilter(candidateFilter);
                }}
                options={[
                  {
                    disabled: true,
                    key: 'none',
                    value: 'none',
                    label: currentLanguage.FilterOutEmployeesWith,
                  },
                  ...selectOptions,
                ]}
              />
              <div className={scss.helperText}>
                {currentLanguage.ConfirmOfferExchangeCandidatesHelperText}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Buttons>
          <Button variant={ButtonStyle.Secondary} onClick={() => onCancel()}>
            <>{currentLanguage.Cancel}</>
          </Button>
          <Button
            onClick={() => {
              onAccept(
                selectedCandidateFilter ?? defaultExchangeCandidateFilter
              );
            }}
          >
            <>{currentLanguage.OfferShift}</>
          </Button>
        </Modal.Buttons>
      </Modal>
    </div>
  );
};

export default ConfirmOfferModal;
