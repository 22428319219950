import { dateFormats } from '@pdcfrontendui/utils';
import { currentLanguage } from '../currentLanguage';

/**
 * This is in its own file because Playwright acts funny if it imports an scss module.
 */
export function getListViewItemAriaLabel(employeeName: string, from: Date) {
  return `${
    currentLanguage.SelectTheShift
  } ${employeeName} ${dateFormats.$31DOT_okt_1999(from)} ${dateFormats.$23DOT59(
    from
  )}`;
}
