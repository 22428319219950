import classNames from 'classnames';
import React, { ReactNode } from 'react';

import './Section.scss';

type SectionProps = {
  title?: string;
  noInnerBottomPadding?: boolean;
  noInnerSidePadding?: boolean;
  noBottomMargin?: boolean;
  children?: ReactNode;
  className?: string;
};

const Section = ({
  title,
  noInnerBottomPadding,
  noInnerSidePadding,
  noBottomMargin,
  children,
  className,
}: SectionProps) => (
  <section
    className={classNames('section-component', className, {
      noinnerbottompadding: noInnerBottomPadding,
      noinnersidepadding: noInnerSidePadding,
      nobottommargin: noBottomMargin,
    })}
  >
    {title && <div className="section-title">{title}</div>}

    <div className="inner-section">{children}</div>
  </section>
);

export default Section;

