import './CalendarNavigation.scss';

import Icons, { IconSize } from '@pdcfrontendui/components/Icons';
import React, { memo } from 'react';

import ids from '../testing/ids';

const scss = { comp: 'CalendarNavigation' };

function CalendarNavigation({
  title,
  onPrevious,
  onNext,
}: {
  title: string;
  onPrevious: () => void;
  onNext: () => void;
}) {
  return (
    <div className={scss.comp}>
      <Icons.ChevronLeft
        size={IconSize.XSmall}
        onClick={onPrevious}
        id={ids.calendar.previous}
        className={ids.calendar.previous}
      />
      <div>{title}</div>
      <Icons.ChevronRight
        size={IconSize.XSmall}
        onClick={onNext}
        id={ids.calendar.next}
        className={ids.calendar.next}
      />
    </div>
  );
}
export default memo(CalendarNavigation);
