import {
  Button,
  ButtonStyle,
  LoadingButton,
  Modal,
  NotificationType,
} from '@pdcfrontendui/components';
import React from 'react';
import { currentLanguage } from '../../../currentLanguage';
import { formatActivityOrDutylineTime } from '../../../util/dates';
import { UpdateShiftNotifications } from '../EditedShift';
import { useHideRoot } from '../../../hooks/useHideRoot';
import { ModalNotification } from '../../../components/ModalNotification';
import scss from './ConfirmEditShiftDialog.module.scss';

export function ConfirmEditShiftDialog({
  notifications,
  shown,
  cancel,
  save,
  loading,
  shiftDefChanged,
}: {
  notifications: UpdateShiftNotifications;
  shown: boolean;
  cancel: () => void;
  save: () => void;
  loading: boolean;
  shiftDefChanged: boolean;
}) {
  useHideRoot(shown);
  return (
    <Modal shown={shown} className={scss.comp}>
      <Modal.Header>{currentLanguage.SaveChanges}</Modal.Header>
      <Modal.Body>
        <p>{currentLanguage.ConfirmSaveChanges}</p>
        {!!notifications.modifiedDutylines?.dutylines.length && (
          <ModalNotification
            className={scss.notification}
            title={
              shiftDefChanged
                ? notifications.modifiedDutylines.dutylines.length > 1
                  ? currentLanguage.ModifiedDuties
                  : currentLanguage.ModifiedDuty
                : undefined
            }
            body={
              shiftDefChanged
                ? currentLanguage.DeletedDueToShiftTypeChange
                : currentLanguage.DeviatingDutylinesWillBeAffectedExplanation
            }
            items={notifications.modifiedDutylines.dutylines.map(
              (line) =>
                `${formatActivityOrDutylineTime(line)} ${line.salarysort}: ${
                  line.salarysortText
                }`
            )}
            type={
              shiftDefChanged
                ? NotificationType.Error
                : notifications.modifiedDutylines.typeHasChanged
                ? NotificationType.Caution
                : NotificationType.Info
            }
          />
        )}
        {!!notifications.activitiesDeleted?.length && (
          <ModalNotification
            className={scss.notification}
            title={
              notifications.fetchActivitiesDeletedFailed
                ? currentLanguage.ActivitiesMightBeDeleted
                : currentLanguage.ActivitiesWillBeDeleted
            }
            body={
              notifications.fetchActivitiesDeletedFailed
                ? currentLanguage.ActivitiesMightBeDeletedPleaseNote
                : currentLanguage.ActivitiesWillBeDeletedPleaseNote
            }
            items={notifications.activitiesDeleted.map(
              (activity) =>
                `${formatActivityOrDutylineTime(activity)} ${activity.label}`
            )}
            type={NotificationType.Error}
          />
        )}
        {notifications.unknownShiftDef && (
          <ModalNotification
            className={scss.notification}
            body={currentLanguage.ShiftTypeNotAvailablePleaseNote_1(
              notifications.unknownShiftDef
            )}
            type={NotificationType.Info}
          />
        )}
      </Modal.Body>
      <Modal.Buttons>
        <Button variant={ButtonStyle.Secondary} onClick={cancel}>
          {currentLanguage.Cancel}
        </Button>
        <LoadingButton
          loading={loading}
          variant={ButtonStyle.Primary}
          onClick={save}
        >
          {currentLanguage.Save}
        </LoadingButton>
      </Modal.Buttons>
    </Modal>
  );
}
