import { Period, toDefaultLoadPeriod } from './util/dates';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EmployeeMap } from './api/model';
import { POLLING_INTERVAL_MS } from './constants';
import { useInterval } from '@pdcfrontendui/hooks';

/**
 * Effect-only component that checks for new messages and shift updates every {@link POLLING_INTERVAL_MS|30} seconds.
 */
export default function IntervalPolling({
  currentDate,
  callGetMessages,
  checkUnreadMessageCount,
  teamId,
  employees,
  loadShifts,
}: {
  currentDate: Date | null;
  callGetMessages: (
    personIds: number[],
    from: Date,
    to: Date,
    isPoll: boolean
  ) => void;
  checkUnreadMessageCount: (
    teamIds: string[],
    from: Date,
    to: Date,
    isPoll: boolean
  ) => void;
  teamId: string;
  employees: Record<string, EmployeeMap>;
  loadShifts: (teamId: string, requestPeriod: Period, isPoll: boolean) => void;
}) {
  // Doing a fair bit of memoization here to prevent unnecessary calls to getMessages.
  const [personIds, setPersonIds] = useState<number[]>([]);
  const getMessagesRef = useRef(callGetMessages);
  getMessagesRef.current = callGetMessages;
  const checkUnreadMessageCountRef = useRef(checkUnreadMessageCount);
  checkUnreadMessageCountRef.current = checkUnreadMessageCount;
  const loadShiftsRef = useRef(loadShifts);
  loadShiftsRef.current = loadShifts;

  useEffect(() => {
    const employeesForTeam = employees[teamId];
    if (employeesForTeam) {
      setPersonIds((personIds) => {
        const newPersonIds = Object.keys(employeesForTeam).map(Number);
        if (
          personIds.length === newPersonIds.length &&
          personIds.every((id) => employeesForTeam[id.toString()])
        ) {
          return personIds;
        }
        return newPersonIds;
      });
    }
  }, [employees, teamId]);

  // Periodically check for new messages for the selected team. Selecting a new team automatically starts checking messages for that team instead.
  usePoll(
    useCallback(
      (isPoll) => {
        if (currentDate && personIds.length) {
          const period = toDefaultLoadPeriod(currentDate);
          getMessagesRef.current(personIds, period.from, period.to, isPoll);
        }
      },
      [currentDate, personIds]
    )
  );

  usePoll(
    useCallback(
      (isPoll) => {
        if (teamId && currentDate) {
          const period = toDefaultLoadPeriod(currentDate);
          checkUnreadMessageCountRef.current(
            [teamId],
            period.from,
            period.to,
            isPoll
          );
        }
      },
      [currentDate, teamId]
    )
  );

  // Periodically check for new shifts for the selected team. Selecting a new team automatically starts checking shifts for that team instead.
  usePoll(
    useCallback(
      (isPoll) => {
        // TeamId is an empty string after login, and until a team is selected.
        if (teamId && currentDate) {
          loadShiftsRef.current(
            teamId,
            toDefaultLoadPeriod(currentDate),
            isPoll
          );
        }
      },
      [currentDate, teamId]
    )
  );

  return null;
}

/**
 * @param poller Should be properly memoized with useCallback.
 */
function usePoll(poller: (isPoll: boolean) => void) {
  // useEffect + useInterval since useInterval is not called immediately.
  useEffect(() => poller(false), [poller]);
  useInterval(() => poller(true), POLLING_INTERVAL_MS);
}
