﻿/* Fully Generated from generatedWEBSERVICE_Api.ts */

//===============================================================
//  Auto Generated Code for PushNotification
//===============================================================
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-useless-constructor */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-extraneous-class */

/* This file contains, in this order:
 *   1. Data value objects
 *   2. Classes defining jsonRpc methods returning data value objects
 */

import { type WebResponseResult } from '@pdcfrontendui/staffplan';
import { WebRequest } from './WebRequest';
import {
  WebTypeViewField,
  getWebTypeViewFieldFromJSON,
  getWebTypeViewField,
  WebField,
  getWebFieldFromJSON,
  getWebField,
  WebRecord,
  getWebRecordFromJSON,
  getWebRecord,
  WebList,
  getWebListFromJSON,
  getWebList,
  WebElement,
  getWebElement,
  LogonSettings,
  getLogonSettingsFromJSON,
  getLogonSettings,
  WebSortConfig,
  getWebSortConfigFromJSON,
  getWebSortConfig,
  WebFieldConfig,
  getWebFieldConfigFromJSON,
  getWebFieldConfig,
  WebListConfig,
  getWebListConfigFromJSON,
  getWebListConfig,
  WebRecordType,
  getWebRecordTypeFromJSON,
  getWebRecordType,
  WsRefPerson,
  getWsRefPerson,
  WsRefOrgunit,
  getWsRefOrgunit,
  WsRefOrgunitByInfo,
  getWsRefOrgunitByInfo,
  WsRefShiftCounter,
  getWsRefShiftCounter,
  WsRefShifttype,
  getWsRefShifttype,
  WsRefTaskDef,
  getWsRefTaskDef,
  WsRefTask,
  WsRefAccount,
  WsRefProject,
  WsRefDimensionItem,
  getWsRefAccount,
  getWsRefProject,
  getWsRefDimensionItem,
  getWsRefTask,
} from './Common_api';

import * as Enums from './enumLib_api'
import {
  assertObject,
  getNullable,
  getEnumFromJSON,
  getEnum,
  getNullableEnum,
  getEnumArray,
  getNullableEnumArray,
  getArray,
  getNullableArray,
  getEnumArrayArray,
  getNullableEnumArrayArray,
  getArrayArray,
  getNullableArrayArray,
  getInt,
  getIntFromJSON,
  getReal,
  getRealFromJSON,
  getBoolean,
  getBooleanFromJSON,
  getString,
  getStringFromJSON,
  getDate,
  getDateFromJSON,
  isNullish,
  DomainWsIntervalType,
  DomainWsVariantObject,
  DomainWsVariantAvailability,
  DomainJsonObject,
  DomainWspdclasttrans,
  DomainWscommitid,
  getDomainJsonObject,
  getDomainJsonObjectFromJSON,
  getDomainWsVariantObject,
  getDomainWsVariantObjectFromJSON,
  getDomainWsVariantAvailability,
  getDomainWsVariantAvailabilityFromJSON,
  getDomainWsIntervalType,
  getDomainWsIntervalTypeFromJSON,
  getDomainWspdclasttrans,
  getDomainWscommitid,
  getDomainWscommitidFromJSON,
  nullishError,
  type StringObj,
} from './validators';

////////////////// 1. Data value objects /////////////////////////
// These are the actual data value objects used in typescript


////////////////// 2. Classes defining jsonRpc methods returning data value objects /////////////////////////
/*
    These classes are responsible for deserialize and serialize from and to toJSON
    into the respectfull objects needed.
    AND
    Are responsible for knowing exact method and url endpoint to call with json RPC
*/

/**     % RegisterDevice. Old versions of this api did not take applicationId
Responsible: PM */
export class RegisterDevice extends WebRequest<boolean> {
  public webService = 'pushNotification' as const;
  public method = 'RegisterDevice' as const;
  public noUserValidation = false as const;
  public noAuditAction = true as const;
  constructor(
    public DeviceToken: string,
    public ServiceType: Enums.PushProviderTypeEnum,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<boolean>): boolean {
    return getBooleanFromJSON(json.result);
  }
}

/**     % Returns whether the staffuserid (specified in the JWT) wants push notifications
Responsible: PM */
export class GetAllowNotifications extends WebRequest<boolean> {
  public webService = 'pushNotification' as const;
  public method = 'GetAllowNotifications' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<boolean>): boolean {
    return getBooleanFromJSON(json.result);
  }
}

/**     % Set whether the staffuserid (specified in the JWT) wants push notifications
Responsible: PM */
export class SetAllowNotifications extends WebRequest<boolean> {
  public webService = 'pushNotification' as const;
  public method = 'SetAllowNotifications' as const;
  public noUserValidation = false as const;
  public noAuditAction = false as const;
  constructor(
    public AllowNotification: boolean,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<boolean>): boolean {
    return getBooleanFromJSON(json.result);
  }
}

