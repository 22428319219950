//
// Access to the api happens through getApi()
//

import { Api } from './api/Api';

let api: Api | null = null;
let mockedApi: Api | null = null;

// XXX This string corresponds to a specified view in the database, for now we
// only have the option `teamplan_shiftdetails`.  This view changes which
// feilds will be in the record.fieldlist on each shift recieved from the
// backend.
//
// Using option `teamplan_shiftdetails`, will just give a field called 'remark'.
// More could be added later if a specific customer wants specific details on
// their shifts.
const tc = {
  storedViewName: 'teamplan_shiftdetails',
};

function getBaseUrl() {
  const base = document.baseURI
    .toString()
    .replace(/#.*/, '')
    .replace(/\/+$/, '')
    .toLowerCase();

  // Default webservice URLs
  return `${base}/api`;
}

/**
 *
 * @example
 * const mock = mockApi({
 *  getThing: async () => ({ data: [] })
 * });
 *
 * beforeAll(() => {
 *  mock.begin();
 * });
 *
 * afterAll(() => {
 *  mock.restore();
 * });
 */
export function mockApi(mock: Partial<Omit<Api, 'fetch' | 'baseUrl'>>) {
  const original = getApi();
  return {
    begin: () => {
      const mocked = new Api(getBaseUrl(), tc.storedViewName);
      for (const key in mock) {
        (mocked as any)[key] = (mock as any)[key];
      }
      api = mocked;
    },
    restore: () => {
      api = original;
    },
  };
}

export default function getApi(): Api {
  if (api) {
    return api;
  }

  api = new Api(
    getBaseUrl(),
    // TODO It may be better to get this value from elsewhere
    tc.storedViewName
  );

  return api;
}
