import * as dateFns from 'date-fns';

import { EmployeeMap, ShiftMap } from '../api/model';
import {
  attemptSendNotificationLog,
  atteptGetUnreadMessagesCount,
} from '../appActions';

import { LocalStorageKey } from '../util/LocalStorageKey';
import { Period } from '../util/dates';
import { Store } from '../rootReducer';
import { TeamShift } from '../api/TeamPlan_api';
import { ThunkDispatch } from '../types';
import { WebRecordType } from '../api/Common_api';
import getApi from '../getApi';
import { localStorageSet } from '../util/localStorage';

export type ListViewAction =
  | {
      type: 'LOADTEAMROSTER_REQUEST';
      id: string;
    }
  | {
      type: 'LOADTEAMROSTERMULTI_REQUEST';
      ids: string[];
    }
  | {
      type: 'LOADTEAMROSTER_SUCCESS';
      shifts: ShiftMap;
      employees: EmployeeMap;
      recordType: WebRecordType;
      teamId: string;
    }
  | {
      type: 'LOADTEAMROSTER_FAILURE';
      errorMessage: string;
      teamId: string;
    }
  | {
      type: 'CHANGE_PERIOD';
      period: Period;
    }
  | {
      type: 'ADD_LOADING_PERIOD';
      period: Period;
    }
  | {
      type: 'REMOVE_LOADING_PERIOD';
      period: Period;
    }
  | {
      type: 'CHANGE_TEAM';
      teamId: string;
    }
  | {
      type: 'UPDATE_SHIFT';
      originalShiftId: string;
      shift: TeamShift;
      recordType: WebRecordType;
      teamId: string;
    }
  | {
      type: 'UPDATE_SHIFTS';
      shiftId: string;
      shifts: TeamShift[];
      recordType: WebRecordType;
      teamId: string;
    }
  | {
      type: 'SET_BACKLOADING';
      value: boolean;
    }
  | {
      type: 'GETEMPLOYEES_REQUEST';
    }
  | {
      type: 'GETEMPLOYEES_SUCCESS';
      teamId: string;
      getEmployeesResponse: EmployeeMap;
    }
  | {
      type: 'GETEMPLOYEES_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'UPDATE_DAYLABELSTICKY';
      date: Date;
    }
  | {
      type: 'UPDATE_SELECTEDSHIFT';
      selectedShiftId: string;
    };

export const changeTeam = (teamId: string): ListViewAction => ({
  type: 'CHANGE_TEAM',
  teamId,
});

export const changePeriod = (period: Period): ListViewAction => ({
  type: 'CHANGE_PERIOD',
  period,
});

export const addLoadingPeriod = (period: Period): ListViewAction => ({
  type: 'ADD_LOADING_PERIOD',
  period,
});

export const removeLoadingPeriod = (period: Period): ListViewAction => ({
  type: 'REMOVE_LOADING_PERIOD',
  period,
});

export const loadTeamRosterRequest = (id: string): ListViewAction => ({
  type: 'LOADTEAMROSTER_REQUEST',
  id,
});

export const loadMultiTeamRosterRequest = (ids: string[]): ListViewAction => ({
  type: 'LOADTEAMROSTERMULTI_REQUEST',
  ids,
});

export const loadTeamRosterSuccess = (
  shifts: ShiftMap,
  employees: EmployeeMap,
  recordType: WebRecordType,
  teamId: string
): ListViewAction => ({
  type: 'LOADTEAMROSTER_SUCCESS',
  shifts,
  employees,
  recordType,
  teamId,
});

export const loadTeamRosterFailure = (
  errorMessage: string,
  teamId: string
): ListViewAction => ({
  type: 'LOADTEAMROSTER_FAILURE',
  errorMessage,
  teamId,
});

export const updateShift = (
  originalShiftId: string,
  shift: TeamShift,
  recordType: WebRecordType,
  teamId: string
): ListViewAction => ({
  type: 'UPDATE_SHIFT',
  originalShiftId,
  shift,
  recordType,
  teamId,
});
export const updateShifts = (
  shiftId: string,
  shifts: TeamShift[],
  recordType: WebRecordType,
  teamId: string
): ListViewAction => ({
  type: 'UPDATE_SHIFTS',
  shiftId,
  shifts,
  recordType,
  teamId,
});

export const setBackLoading = (value: boolean): ListViewAction => ({
  type: 'SET_BACKLOADING',
  value,
});

export const getEmployeesRequest = (): ListViewAction => ({
  type: 'GETEMPLOYEES_REQUEST',
});

export const getEmployeesSuccess = (
  teamId: string,
  getEmployeesResponse: EmployeeMap
): ListViewAction => ({
  type: 'GETEMPLOYEES_SUCCESS',
  teamId,
  getEmployeesResponse,
});

export const getEmployeesFailure = (errorMessage: string): ListViewAction => ({
  type: 'GETEMPLOYEES_FAILURE',
  errorMessage,
});

export const updateDayLabelSticky = (date: Date): ListViewAction => ({
  type: 'UPDATE_DAYLABELSTICKY',
  date,
});

export const updateSelectedShift = (
  selectedShiftId: string
): ListViewAction => ({
  type: 'UPDATE_SELECTEDSHIFT',
  selectedShiftId,
});

export function attemptLoadTeamRoster(
  teamId: string,
  requestPeriod: Period,
  isBack = false,
  isPoll: boolean
) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    dispatch(addLoadingPeriod(requestPeriod));
    if (isBack) {
      dispatch(setBackLoading(true));
    }
    dispatch(loadTeamRosterRequest(teamId));
    return getApi()
      .getTeamRoster(teamId, requestPeriod, isPoll)
      .then((teamRoster) => {
        if (isBack) {
          dispatch(setBackLoading(false));
        }
        dispatch(
          loadTeamRosterSuccess(
            removeRemovedShifts(
              getState().listViewReducer.shiftMap[teamId] ?? {},
              teamRoster.shiftMap,
              requestPeriod
            ),
            teamRoster.employeeMap,
            teamRoster.recordType,
            teamId
          )
        );
        dispatch(removeLoadingPeriod(requestPeriod));
      })
      .catch((err) => {
        // Error might have happened because the currently selected team was not valid, so we select the first one if we have any teams loaded, and update local storage.
        // This could happen if another user logs on in the same browser, and the current user does not have access to the team that was selected.
        const firstLoadedTeamId =
          Object.keys(getState().chooseTeamReducer.teams)[0] ?? '';
        localStorageSet(LocalStorageKey.currentTeam, firstLoadedTeamId);
        dispatch(loadTeamRosterFailure(err.message, firstLoadedTeamId));
        dispatch(attemptSendNotificationLog(err));
      });
  };
}

export function attemptLoadTeamRosterMulti(
  teamIds: string[],
  requestPeriod: Period
) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    dispatch(loadMultiTeamRosterRequest(teamIds));

    // We don't have to load the current team, since that is already handled elsewhere
    const currentTeam = getState().listViewReducer.currentTeam;
    dispatch(
      atteptGetUnreadMessagesCount(
        teamIds,
        requestPeriod.from,
        requestPeriod.to,
        false
      )
    );
    teamIds
      .filter((teamId) => teamId !== currentTeam)
      .map((teamId) => {
        return getApi()
          .getTeamRoster(teamId, requestPeriod, false)
          .then((teamRoster) => {
            dispatch(
              loadTeamRosterSuccess(
                teamRoster.shiftMap,
                teamRoster.employeeMap,
                teamRoster.recordType,
                teamId
              )
            );
          });
      });
  };
}

function removeRemovedShifts(
  prevShifts: ShiftMap,
  newShifts: ShiftMap,
  requestPeriod: Period
) {
  const res: ShiftMap = { ...newShifts };
  Object.values(prevShifts).forEach((shift) => {
    if (
      dateFns.differenceInMilliseconds(shift.period.from, requestPeriod.to) >=
        0 ||
      dateFns.differenceInMilliseconds(shift.period.to, requestPeriod.from) <= 0
    ) {
      res[shift.id] = { ...shift };
    }
  });
  return res;
}

export function attemptGetEmployees(personIds: number[], teamId: string) {
  return (dispatch: ThunkDispatch, getState: () => Store) => {
    // kinda hacky way to make sure we only have one getEmployees call at a time
    if (!getState().listViewReducer.loadingEmployees) {
      dispatch(getEmployeesRequest());
      return getApi()
        .getEmployees(personIds, teamId)
        .then((getEmployeesMap) => {
          dispatch(getEmployeesSuccess(teamId, getEmployeesMap));
        })
        .catch((err) => {
          dispatch(getEmployeesFailure(err.message));
          dispatch(attemptSendNotificationLog(err));
        });
    }
  };
}
