import React from 'react';
import { useFormikContext } from 'formik';
import { TextInput, TextInputProps } from '@pdcfrontendui/components';

type Props<T> = Omit<TextInputProps, 'value' | 'onChange' | 'onBlur'> & {
  name: keyof T;
};

export const FormikTextInput = <T extends object>({
  name,
  ...props
}: Props<T>) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<T>();

  return (
    <TextInput
      value={values[name] as any}
      onChange={(e) => {
        setFieldValue(name as string, e.currentTarget.value, true);
      }}
      onBlur={() => {
        setFieldTouched(name as string, true, true);
      }}
      {...props}
    />
  );
};
export default FormikTextInput;
