import { MsgVisibilityOldEnum, UserLoginTypeEnum } from '../api/enumLib_api';

import { DayMessage } from '../api/Message_api';

export function shouldShowAsUnread(message: DayMessage, userId: number) {
  if (!message.ReadWhen) {
    if (messageIsFromMe(message, userId)) {
      return false;
    }
    // If it's an internal message, or a message from an employee
    if (
      message.Visibility === MsgVisibilityOldEnum.planner ||
      message.FromKey.UserType === UserLoginTypeEnum.employee
    ) {
      return true;
    }
  }
  return false;
}

export function hasUnreadMessages(messages: DayMessage[], userId: number) {
  const last = messages[messages.length - 1];
  return !!last && shouldShowAsUnread(last, userId);
}

export function messageIsFromMe(message: DayMessage, userId: number) {
  // Make sure that we include check for UserType, since otherwise we would compare userIds from different database tables.
  return (
    message.FromKey.UserType === UserLoginTypeEnum.planner &&
    message.FromKey.UserId === userId
  );
}
