import './SwapRadioAccordionItem.scss';

import * as api from '../api/TeamPlan_api';

import {
  Accordion,
  AccordionItem,
  RadioButton,
} from '@pdcfrontendui/components';
import Icons, { IconSize } from '@pdcfrontendui/components/Icons';

import { Duties } from './Duties';
import { Item } from '../components';
import { Team } from '../api/TeamPlan_api';
import { currentLanguage } from '../currentLanguage';

export type SwapRadioAccordionItemProps = {
  id: string;
  employeeName: string;
  isSwap: boolean;
  dutyLines?: api.TeamDuty[];
  team?: Team;
  selected: boolean;
  onSelect: () => void;
  callIn: api.CallIn | null;
  note: string;
  personGroupList: string[] | null;
  deviatingPayerUiLabel: string | null;
};

const SwapRadioAccordionItem = ({
  id,
  employeeName,
  isSwap,
  dutyLines,
  onSelect,
  selected,
  callIn,
  note,
  personGroupList,
  deviatingPayerUiLabel,
}: SwapRadioAccordionItemProps) => {
  return (
    <div className="swapradioaccordionitem-wrapper">
      <Accordion>
        <AccordionItem
          className={'swapradioaccordionitem'}
          title={
            <Item
              id={`list-view-item-${id}`}
              className={'swapradioaccordionitem-item'}
            >
              <div className="content">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div className="period">
                  <RadioButton
                    id={id + '-radiobutton'}
                    selected={selected}
                    onClick={() => onSelect()}
                  />
                  {/* The radiobutton poses too small a target, so a div is overlayed so that the touch targets meets expectations.
                      e.stopPropagation() is needed to stop the Accordion from opening when clicking the RadioButton */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    role="button"
                    className="clickArea"
                    id={id + '-clickArea'}
                    onClick={(e) => {
                      onSelect();
                      e.stopPropagation();
                    }}
                  />
                </div>

                <div className="shift-info">
                  <div className="employee-name">
                    {!isSwap ? (
                      <Icons.ArrowRight size={IconSize.XXSmall} />
                    ) : (
                      <Icons.Swap size={IconSize.XXSmall} />
                    )}
                    {employeeName || <>&nbsp;</>}
                  </div>
                  {callIn && (
                    <div className="shift-status">
                      {typeof callIn.priority === 'number' &&
                      !callIn.isFictive &&
                      callIn.priority > 0
                        ? `${Math.floor(callIn.priority)}: ${callIn.shortRule}`
                        : callIn.shortRule}
                    </div>
                  )}
                </div>
              </div>
              {deviatingPayerUiLabel && (
                <Icons.Info
                  size={IconSize.XXXSmall}
                  className="deviating-payer-icon"
                />
              )}
            </Item>
          }
        >
          <div className="swap-radio-details">
            {!!callIn && callIn.descCause && (
              <>
                <div className="detail-title">{currentLanguage.RuleBreak}</div>
                <div className="rule-break-priority-status">
                  {callIn.priority && (
                    <div>{Math.floor(callIn.priority)}:&nbsp;</div>
                  )}
                  <div>{callIn.descCause}</div>
                </div>
              </>
            )}

            {note && note.length > 0 && (
              <>
                <div className="detail-title">{currentLanguage.Note}</div>
                <div className="shift-status">{note}</div>
              </>
            )}

            {personGroupList && personGroupList.length > 0 && (
              <>
                <div className="detail-title">{currentLanguage.Groups}</div>
                <div className="person-group-list">
                  {personGroupList.map((personGroup, i) => (
                    <div key={i}>{personGroup}</div>
                  ))}
                </div>
              </>
            )}

            {deviatingPayerUiLabel && (
              <>
                <div className="detail-title">{currentLanguage.Location}</div>
                <div className="deviatingPayer">
                  <Icons.Info size={IconSize.XXXSmall} />
                  <span>{deviatingPayerUiLabel}</span>
                </div>
              </>
            )}

            {dutyLines && dutyLines.length > 0 && (
              <Duties dutyLines={dutyLines} />
            )}
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
export default SwapRadioAccordionItem;
