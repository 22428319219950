import { Language, langMap } from './generatedLanguages';
import { capitalize, changeDateFormatLanguage } from '@pdcfrontendui/utils';
import {
  localStorageGet,
  localStorageRemove,
  localStorageSet,
} from './util/localStorage';
import { LocalStorageKey } from './util/LocalStorageKey';

export type LanguageCode = keyof typeof langMap;
export function isValidLanguageCode(code: string): code is LanguageCode {
  return code in langMap;
}

let defaultLanguageId: LanguageCode = 'Da';

export function setDefaultLanguageId() {
  const browserLanguageCapitalized = capitalize(
    navigator.language || defaultLanguageId
  ).slice(0, 2);

  if (isValidLanguageCode(browserLanguageCapitalized)) {
    defaultLanguageId = browserLanguageCapitalized;
  }
}

export let currentLanguage: Language;

export function setCurrentLanguage(id: LanguageCode) {
  if (!isValidLanguageCode(id)) {
    // Prevent the same error from re-occurring when the application reloads
    localStorageRemove(LocalStorageKey.currentLanguage);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    throw new Error('Unknown language: ' + id);
  }
  currentLanguage = langMap[id];
  changeDateFormatLanguage(currentLanguage);
  localStorageSet(LocalStorageKey.currentLanguage, id);
}

export const getCurrentLanguage = () => {
  return localStorageGet(LocalStorageKey.currentLanguage) ?? defaultLanguageId;
};

setCurrentLanguage(getCurrentLanguage());
