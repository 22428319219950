import './OfferView.scss';

import {
  Accordion,
  Button,
  ButtonStyle,
  Card,
  Checkbox,
  Header,
  IconSize,
  Icons,
  LoadingButton,
  MobileSlideIn,
  SearchBar,
} from '@pdcfrontendui/components';
import { Team, TeamShift } from '../api/TeamPlan_api';
import { useEffect, useMemo, useRef, useState } from 'react';

import { AccordionItemControlled } from '@pdcfrontendui/components/AccordionItem';
import ButtonContainer from '@pdcfrontendui/components/ButtonContainer';
import { TeamMap } from '../api/model';
import { currentLanguage } from '../currentLanguage';
import ids from '../testing/ids';
import { localeFormat } from '../util/dates';
import { searchString } from '@pdcfrontendui/utils';
import { useIsDualView } from '@pdcfrontendui/hooks';
import ConfirmOfferModal from './ConfirmOfferModal';
import { ExchangeCandidateFilterEnum } from '../api/enumLib_api';

const scss = {
  Comp: 'OfferView',
  content: 'content',
  groupList: 'group-list',
  groupItemContents: 'group-item-contents',
  searchContainer: 'search-container',
  noGroupsWrapper: 'no-groups-wrapper',
};

type Props = {
  show: boolean;
  shift: TeamShift;
  teams: TeamMap;
  teamId: string;
  possibleCandidateFilters: ExchangeCandidateFilterEnum[];
  defaultExchangeCandidateFilter: ExchangeCandidateFilterEnum;
  selectedCandidateFilter: ExchangeCandidateFilterEnum | null;
  onBackClick: () => void;
  rejectOffer: () => void;
  acceptOffer: (
    teamIdList: string[],
    candidateFilter: ExchangeCandidateFilterEnum
  ) => void;
  updateSelectedCandidateFilter: (
    candidateFilter: ExchangeCandidateFilterEnum
  ) => void;
};

const OfferView = ({
  show,
  shift,
  teams,
  teamId,
  possibleCandidateFilters,
  defaultExchangeCandidateFilter,
  selectedCandidateFilter,
  onBackClick,
  rejectOffer,
  acceptOffer,
  updateSelectedCandidateFilter,
}: Props) => {
  const isMobile = !useIsDualView();
  const [expandedAccordionItems, setExpandedAccordionItems] = useState<
    string[]
  >([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const title = localeFormat(
    shift.period.from,
    false,
    currentLanguage.languageCode
  );

  const teamList: Team[] = useMemo(() => Object.values(teams), [teams]);

  const filteredAndGroupedTeams: Record<string, Team[]> = useMemo(
    () =>
      teamList
        .filter((f) => searchString(searchText, f.label))
        .reduce((group, team) => {
          const { parentId } = team;

          group[parentId] = group[parentId] ?? [];
          group[parentId]!.push(team);
          return group;
        }, {} as Record<string, Team[]>),
    [teamList, searchText]
  );

  useEffect(() => {
    if (show) {
      setSelectedGroups((prev) => [...prev, teamId]);
      setExpandedAccordionItems((prev) => {
        const parentId = teamList.find((team) => team.id === teamId)?.parentId;
        return parentId ? [...prev, parentId] : prev;
      });
    }
  }, [teamId, show]);

  useEffect(() => {
    if (searchText.length === 0) {
      // Expand the accordions with groups selected
      const accordionsWithSelectedGroups = selectedGroups.reduce<string[]>(
        (prev, curr) => {
          const team = teamList.find((team) => team.id === curr);
          if (team) {
            return [...prev, team.parentId];
          }
          return prev;
        },
        []
      );
      setExpandedAccordionItems(accordionsWithSelectedGroups);
    }
  }, [searchText]);

  useEffect(() => {
    if (searchInputRef.current && isSearchOpen) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen]);

  useEffect(() => {
    if (!show) {
      setExpandedAccordionItems([]);
      setSelectedGroups([]);
      setSearchText('');
      setIsSearchOpen(false);
    }
  }, [show]);

  return (
    <>
      <MobileSlideIn
        isMobile={isMobile}
        className={scss.Comp}
        bottom={50}
        absolute
      >
        {show && (
          <>
            {!isMobile && <Header></Header>}
            <Header centerize>
              <Header.Left>
                <Icons.ChevronLeft
                  size={IconSize.XSmall}
                  onClick={() => {
                    onBackClick();
                  }}
                />
              </Header.Left>
              <Header.Title>{title}</Header.Title>
              <Header.Right>
                <>
                  {!isSearchOpen && (
                    <Icons.Search
                      onClick={() => {
                        setIsSearchOpen(true);
                      }}
                    />
                  )}
                </>
              </Header.Right>
            </Header>
            {isSearchOpen && (
              <div className={scss.searchContainer}>
                <SearchBar
                  value={searchText}
                  onChange={(value: string) => {
                    setSearchText(value);
                  }}
                  ref={searchInputRef}
                />
                <Button
                  onClick={() => {
                    setSearchText('');
                    setIsSearchOpen(false);
                  }}
                  variant={ButtonStyle.GhostOnDark}
                >
                  {currentLanguage.Cancel}
                </Button>
              </div>
            )}
            <div className={scss.content}>
              <Card className="confirmation-card">
                <div>{currentLanguage.ChooseGroups}</div>
                <div className="confirmation-buttons">
                  <ButtonContainer>
                    <Button
                      id={ids.SwapView.rejectSwap}
                      variant={ButtonStyle.Secondary}
                      onClick={() => {
                        rejectOffer();
                      }}
                    >
                      {currentLanguage.Cancel}
                    </Button>
                    <LoadingButton
                      id={ids.SwapView.approveSwap}
                      loading={false}
                      disabled={selectedGroups.length === 0}
                      onClick={() => {
                        setConfirmModalOpen(true);
                      }}
                    >
                      {selectedGroups.length > 0
                        ? currentLanguage.OfferShiftTo_1(selectedGroups.length)
                        : currentLanguage.OfferShift}
                    </LoadingButton>
                  </ButtonContainer>
                </div>
              </Card>
              {Object.keys(filteredAndGroupedTeams).length > 0 ? (
                <div className={scss.groupList}>
                  <Accordion>
                    {Object.entries(filteredAndGroupedTeams)
                      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                      .map((entry) => {
                        const selectedGroupsCount = entry[1].filter((team) =>
                          selectedGroups.includes(team.id)
                        ).length;

                        const counter =
                          selectedGroupsCount > 0
                            ? selectedGroupsCount
                            : undefined;

                        return (
                          <AccordionItemControlled
                            key={entry[0]}
                            title={
                              teamList.find(
                                (team) => team.parentId === entry[0]
                              )?.unitUilabel ?? ''
                            }
                            expanded={
                              searchText.length > 0
                                ? true
                                : expandedAccordionItems.includes(entry[0])
                            }
                            onToggleExpanded={() => {
                              if (expandedAccordionItems.includes(entry[0])) {
                                setExpandedAccordionItems(
                                  expandedAccordionItems.filter(
                                    (item) => item !== entry[0]
                                  )
                                );
                              } else {
                                setExpandedAccordionItems([
                                  ...expandedAccordionItems,
                                  entry[0],
                                ]);
                              }
                            }}
                            counter={counter}
                          >
                            <div className={scss.groupItemContents}>
                              {entry[1]
                                .sort((a, b) => (a.label > b.label ? 1 : -1))
                                .map((team) => (
                                  <Checkbox
                                    key={team.id}
                                    checked={
                                      selectedGroups.includes(team.id) ?? false
                                    }
                                    label={team.label}
                                    onClick={() => {
                                      if (selectedGroups.includes(team.id)) {
                                        setSelectedGroups(
                                          selectedGroups.filter(
                                            (group) => group !== team.id
                                          )
                                        );
                                      } else {
                                        setSelectedGroups([
                                          ...selectedGroups,
                                          team.id,
                                        ]);
                                      }
                                    }}
                                  />
                                ))}
                            </div>
                          </AccordionItemControlled>
                        );
                      })}
                  </Accordion>
                </div>
              ) : (
                <div className={scss.noGroupsWrapper}>
                  {currentLanguage.NoOffersInSearch}
                </div>
              )}
            </div>
          </>
        )}
      </MobileSlideIn>
      <ConfirmOfferModal
        open={confirmModalOpen}
        selectedGroupCount={selectedGroups.length}
        possibleCandidateFilters={possibleCandidateFilters}
        defaultExchangeCandidateFilter={defaultExchangeCandidateFilter}
        selectedCandidateFilter={selectedCandidateFilter}
        onAccept={(candidateFiler) => {
          acceptOffer(selectedGroups, candidateFiler);
          setConfirmModalOpen(false);
        }}
        onCancel={() => {
          setConfirmModalOpen(false);
        }}
        updateSelectedCandidateFilter={updateSelectedCandidateFilter}
      />
    </>
  );
};

export default OfferView;
