import base64url from 'base64url';
import * as CryptoJS from 'crypto-js';

/*
      *The username must ignore case and be concated with the password before hashing
      *The secret(salt) is: frontEndSaltKE5SaF8UmFpOTp1yxFMcZdTd9gu1kwQ4Yg5wz1a81vONoU52UQdH8XscczbVq2atkGaprzHAdA
      *The secret is parsed to a binary wordArray and used in the algorithm
      *The used hashing algorithm is: HmacSHA512
      *The hash output is base64url encoded
    */
export const hashPassword = (username: string, password: string): string => {
  let isPasswordCasesensitive = true; /* settingsStore.getState().isPasswordCasesensitive; */
  let secret =
    'frontEndSaltKE5SaF8UmFpOTp1yxFMcZdTd9gu1kwQ4Yg5wz1a81vONoU52UQdH8XscczbVq2atkGaprzHAdA';
  let wordArraySecret = CryptoJS.enc.Base64.parse(secret);
  let passLower = isPasswordCasesensitive ? password : password.toLowerCase();
  let hash = CryptoJS.enc.Base64.stringify(
    // CryptoJS doesnt follow the eslint rule new-cap, so we need to ignore it here,
    // otherwise we should rename the function at import
    // eslint-disable-next-line new-cap
    CryptoJS.HmacSHA512(username.toLowerCase() + passLower, wordArraySecret)
  );

  return base64url.fromBase64(hash);
};
