import { LocalStorageKey, localStorageSchemas } from './LocalStorageKey';

import { LocalStorageHandler } from '@pdcfrontendui/utils';
import { useSyncExternalStore } from 'use-sync-external-store/shim/index.js';

export const APP_PREFIX = 'teamplan-';

const handler = new LocalStorageHandler(localStorageSchemas, APP_PREFIX, [
  LocalStorageKey.currentLanguage,
]);
handler.enableCache();

export const localStorageSet = handler.set.bind(handler);

export const localStorageGet = handler.get.bind(handler);

export const localStorageRemove = handler.remove.bind(handler);

export function useLocalStorage<T extends LocalStorageKey>(key: T) {
  return useSyncExternalStore(
    (update) => handler.addListener(key, update),
    () => handler.get(key)
  );
}
