import './SwapAccordionItem.scss';

import * as api from '../api/TeamPlan_api';
import * as dateFns from 'date-fns';

import { Accordion, AccordionItem } from '@pdcfrontendui/components';
import Icons, { IconSize } from '@pdcfrontendui/components/Icons';
import { Period, formatActivityOrDutylineTime } from '../util/dates';

import { Duties } from './Duties';
import { Item } from '../components';
import React from 'react';
import { Tab } from '../routes';
import { TeamActivity } from '../api/TeamPlan_api';
import { TeamShiftStatusEnum } from '../api/enumLib_api';
import { currentLanguage } from '../currentLanguage';
import { dateFormats } from '@pdcfrontendui/utils';
import { getShiftStatusText } from '../util/shifts';

export type SwapAccordionItemProps = {
  id: string;
  employeeName: string;
  isSwap: boolean;
  period?: Period;
  dutyLines?: api.TeamDuty[];
  className?: string;
  callIn: api.CallIn | null;
  numberOfApplicants: number;
  note?: string;
  responsibilities?: string[];
  activities?: TeamActivity[];
  personGroupList: string[] | null;
  deviatingPayerUiLabel: string | null;
};

const SwapAccordionItem = ({
  id,
  employeeName,
  isSwap,
  period,
  dutyLines,
  className,
  callIn,
  numberOfApplicants,
  responsibilities,
  activities,
  note,
  personGroupList,
  deviatingPayerUiLabel,
}: SwapAccordionItemProps) => {
  return (
    <div className="swapaccordionitem-wrapper">
      <Accordion>
        <AccordionItem
          id={`list-view-item-${id}`}
          className={className}
          title={
            <Item className={'swapaccordionitem-item'}>
              <div className="content">
                <div className="period">
                  <div className={'from'}>
                    {period ? (
                      dateFns.format(
                        period.from,
                        `HH${currentLanguage.HhMmSeparator}mm`
                      )
                    ) : (
                      <>&nbsp;</>
                    )}
                  </div>
                  <div className="to">
                    {period ? dateFormats.End_23DOT59(period.to) : <>&nbsp;</>}
                  </div>
                </div>
                <div className="shift-info">
                  <div className="employee-name">
                    {!isSwap ? (
                      <Icons.ArrowRight size={IconSize.XXSmall} />
                    ) : (
                      <Icons.Swap size={IconSize.XXSmall} />
                    )}
                    {employeeName || <>&nbsp;</>}
                  </div>
                  <div className="shift-status">
                    {callIn && !callIn.isFictive ? (
                      <div className="shift-status">
                        {typeof callIn.priority === 'number' &&
                        callIn.priority > 0
                          ? `${Math.floor(callIn.priority)}: ${
                              callIn.shortRule
                            }`
                          : callIn.shortRule}
                      </div>
                    ) : !isSwap && numberOfApplicants > 0 ? (
                      currentLanguage.NumApplicantsFromShiftExchange_1(
                        numberOfApplicants
                      )
                    ) : (
                      getShiftStatusText(
                        !isSwap
                          ? TeamShiftStatusEnum.offerActionRequired
                          : TeamShiftStatusEnum.swapActionRequired,
                        Tab.Changes
                      )
                    )}
                  </div>
                </div>
              </div>
              {deviatingPayerUiLabel && (
                <Icons.Info
                  size={IconSize.XXXSmall}
                  className="deviating-payer-icon"
                />
              )}
            </Item>
          }
        >
          <div className="swap-details">
            {!!responsibilities?.length && (
              <>
                <div className="detail-title">
                  {currentLanguage.responsibility}
                </div>
                <div className="responsibilities">
                  {responsibilities.map((responsibility, index) => (
                    <div key={index}>{responsibility}</div>
                  ))}
                </div>
              </>
            )}

            {!!activities?.length && (
              <>
                <div className="detail-title">{currentLanguage.Activities}</div>
                <div className="team-activities">
                  {activities.map((activity, index) => (
                    <React.Fragment key={index}>
                      <div>{formatActivityOrDutylineTime(activity)}</div>
                      <div>{activity.label}</div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}

            {!!callIn && callIn.descCause && (
              <>
                <div className="detail-title">{currentLanguage.RuleBreak}</div>
                <div className="rule-break-priority-status">
                  {callIn.priority && (
                    <div>{Math.floor(callIn.priority)}:&nbsp;</div>
                  )}
                  <div>{callIn.descCause}</div>
                </div>
              </>
            )}
            {note && note.length > 0 && (
              <>
                <div className="detail-title">{currentLanguage.Note}</div>
                <div className="shift-status">{note}</div>
              </>
            )}

            {personGroupList && personGroupList.length > 0 && (
              <>
                <div className="detail-title">{currentLanguage.Groups}</div>
                <div className="person-group-list">
                  {personGroupList.map((personGroup, i) => (
                    <div key={i}>{personGroup}</div>
                  ))}
                </div>
              </>
            )}

            {deviatingPayerUiLabel && (
              <>
                <div className="detail-title">{currentLanguage.Location}</div>
                <div className="deviatingPayer">
                  <Icons.Info size={IconSize.XXXSmall} />
                  <span>{deviatingPayerUiLabel}</span>
                </div>
              </>
            )}

            {dutyLines && dutyLines.length > 0 && (
              <Duties dutyLines={dutyLines} />
            )}
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
export default SwapAccordionItem;
