﻿/* Fully Generated from generatedWEBSERVICE_Api.ts */

//===============================================================
//  Auto Generated Code for Common
//===============================================================
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-useless-constructor */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  @typescript-eslint/no-extraneous-class */

/* This file contains, in this order:
 *   1. Data value objects
 *   2. Classes defining jsonRpc methods returning data value objects
 */

import { type WebResponseResult } from '@pdcfrontendui/staffplan';
import { WebRequest } from './WebRequest';

import * as Enums from './enumLib_api'
import {
  assertObject,
  getNullable,
  getEnumFromJSON,
  getEnum,
  getNullableEnum,
  getEnumArray,
  getNullableEnumArray,
  getArray,
  getNullableArray,
  getEnumArrayArray,
  getNullableEnumArrayArray,
  getArrayArray,
  getNullableArrayArray,
  getInt,
  getIntFromJSON,
  getReal,
  getRealFromJSON,
  getBoolean,
  getBooleanFromJSON,
  getString,
  getStringFromJSON,
  getDate,
  getDateFromJSON,
  isNullish,
  DomainWsIntervalType,
  DomainWsVariantObject,
  DomainWsVariantAvailability,
  DomainJsonObject,
  DomainWspdclasttrans,
  DomainWscommitid,
  getDomainJsonObject,
  getDomainJsonObjectFromJSON,
  getDomainWsVariantObject,
  getDomainWsVariantObjectFromJSON,
  getDomainWsVariantAvailability,
  getDomainWsVariantAvailabilityFromJSON,
  getDomainWsIntervalType,
  getDomainWsIntervalTypeFromJSON,
  getDomainWspdclasttrans,
  getDomainWscommitid,
  getDomainWscommitidFromJSON,
  nullishError,
  type StringObj,
} from './validators';

////////////////// 1. Data value objects /////////////////////////
// These are the actual data value objects used in typescript

// Field in a WebList. Contains type info AND presentation info
//  Responsible JMQ
export class WebTypeViewField {
  [key: string]: unknown;
  constructor(
    public fieldName: string,
    public label: string,
    public shortLabel: string,
    public webSimpleType: Enums.WebSimpleTypeEnum,
    public webMetaType: Enums.WebMetaTypeEnum,
    public typeName: string | null = null,
    public length: number | null = null,
    public recordType: WebRecordType | null = null,
    public webConfig: string | null = null) {}
}

export function getWebTypeViewFieldFromJSON(jsonObj: StringObj): WebTypeViewField {
  return new WebTypeViewField(
    /* 80 */
      getString(jsonObj, 'fieldName'),
    /* 80 */
      getString(jsonObj, 'label'),
    /* 80 */
      getString(jsonObj, 'shortLabel'),

      getEnum(jsonObj, 'webSimpleType', Enums.WebSimpleTypeEnum),

      getEnum(jsonObj, 'webMetaType', Enums.WebMetaTypeEnum),
    /* 80; some metatypes has a name objectlist, enum,.... for minutoffset and dataoffset the name are either start or end, for datetime it's empty */
      getNullable(jsonObj, 'typeName', getString),
    /* some metatypes has a parameter length/precision. text : length, real : precision, dateoffset/minutoffset/datetime : 8/12/17 number of used digits in YYYYMMDDHHMISSQQQ */
      getNullable(jsonObj, 'length', getInt),
    /* types: field order equals representation order */
      getNullable(jsonObj, 'recordType', getWebRecordType),
    /* 80; web config is information stored in the layout table - the content is only releavant for the web app */
      getNullable(jsonObj, 'webConfig', getString)
  );
}

export function getWebTypeViewField(jsonObj: StringObj, prop: string): WebTypeViewField {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebTypeViewField', prop);
  assertObject(val);
  return getWebTypeViewFieldFromJSON(val);
}

// cell/field value - normally either value or fields are used. But some fields doesnt have any values, and then both are null
//  Responsible JMQ
export class WebField {
  [key: string]: unknown;
  constructor(
    public name: string,
    public data: WebRecord[] | null = null,
    public value: string | null = null) {}
}

export function getWebFieldFromJSON(jsonObj: StringObj): WebField {
  return new WebField(
    /* 80; field name - same as in webTypeViewField */
      getString(jsonObj, 'name'),
    /* field is an object list. Undefined means [] */
    getNullableArray(jsonObj, 'data', getWebRecordFromJSON),
    /* 80; field value as text */
      getNullable(jsonObj, 'value', getString)
  );
}

export function getWebField(jsonObj: StringObj, prop: string): WebField {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebField', prop);
  assertObject(val);
  return getWebFieldFromJSON(val);
}

// a record. a webField list
//  Responsible JMQ
export class WebRecord {
  [key: string]: unknown;
  constructor(
    public primaryKey: string,
    public record: WebField[]) {}
}

export function getWebRecordFromJSON(jsonObj: StringObj): WebRecord {
  return new WebRecord(
    /* 80; primary key for record */
      getString(jsonObj, 'primaryKey'),

    getArray(jsonObj, 'record', getWebFieldFromJSON)
  );
}

export function getWebRecord(jsonObj: StringObj, prop: string): WebRecord {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebRecord', prop);
  assertObject(val);
  return getWebRecordFromJSON(val);
}

// All data for a Web list
//  Responsible JMQ
export class WebList {
  [key: string]: unknown;
  constructor(
    public listName: string,
    public objectName: string,
    public recordType: WebRecordType,
    public data: WebRecord[]) {}
}

export function getWebListFromJSON(jsonObj: StringObj): WebList {
  return new WebList(
    /* 80; uiLayout.name - uiLayout.internal is given from scope */
      getString(jsonObj, 'listName'),
    /* 80 */
      getString(jsonObj, 'objectName'),
    /* types: field order equals representation order */
      getWebRecordType(jsonObj, 'recordType'),
    /* order equals sorting order */
    getArray(jsonObj, 'data', getWebRecordFromJSON)
  );
}

export function getWebList(jsonObj: StringObj, prop: string): WebList {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebList', prop);
  assertObject(val);
  return getWebListFromJSON(val);
}

// All data for a Web element
//  Responsible JMQ
export class WebElement {
  [key: string]: unknown;
  constructor(
    public listName: string,
    public objectName: string,
    public recordType: WebRecordType,
    public record: WebRecord) {}
}

export function getWebElementFromJSON(jsonObj: StringObj): WebElement {
  return new WebElement(
    /* 80; uiLayout.name - uiLayout.internal is given from scope */
      getString(jsonObj, 'listName'),
    /* 80 */
      getString(jsonObj, 'objectName'),
    /* types: field order equals representation order */
      getWebRecordType(jsonObj, 'recordType'),

      getWebRecord(jsonObj, 'record')
  );
}

export function getWebElement(jsonObj: StringObj, prop: string): WebElement {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebElement', prop);
  assertObject(val);
  return getWebElementFromJSON(val);
}

// Settings defined which can be read before logon (see getGlobalSettings)
//  Responsible AGRU
export class GlobalSettings {
  [key: string]: unknown;
  constructor(
    public defaultWorkSalarysort: string,
    public idleLogoutSeconds: number,
    public logonMethod: Enums.LogonTypeEnum,
    public possibleCandidateFilters: Enums.ExchangeCandidateFilterEnum[],
    public swapIsUsed: Enums.SwapIsUsedEnum,
    public useDateTime: Date,
    public useDateTimeType: Enums.UseDateTimeTypeEnum,
    public openIdAcrValuesEmployee: string | null = null,
    public openIdAcrValuesPlanner: string | null = null,
    public openIdAuthorizationEndpoint: string | null = null,
    public openIdClientId: string | null = null,
    public openIdLogoutEndpoint: string | null = null,
    public openIdRedirectUriEmployee: string | null = null,
    public openIdRedirectUriPlanner: string | null = null,
    public openIdResource: string | null = null,
    public openIdResponseType: string | null = null,
    public openIdScopeEmployee: string | null = null,
    public openIdScopePlanner: string | null = null) {}
}

export function getGlobalSettingsFromJSON(jsonObj: StringObj): GlobalSettings {
  return new GlobalSettings(
    /* 80; The salarysort used on the default shiftDef in getModifiedDutyLines/getShiftCandidateList/assignShiftCandidate */
      getString(jsonObj, 'defaultWorkSalarysort'),
    /* Number of seconds of inactivity before the user is logged out automatically */
      getInt(jsonObj, 'idleLogoutSeconds'),
    /* Which method is used to logon, e.g. openId, windows or staffWebUser */
      getEnum(jsonObj, 'logonMethod', Enums.LogonTypeEnum),
    /* clob; The candidateFilter values that can be specified in offerOnShiftExchange */
    getEnumArray(jsonObj, 'possibleCandidateFilters', Enums.ExchangeCandidateFilterEnum),
    /* swapIsUsed=no means that shifts cannot be offered anywhere. swapIsUsed=onlySwapPg means that shifts can only be offered on pesongroups with swapPg, swapIsUsed=everywhere means that shifts can be offered anywhere */
      getEnum(jsonObj, 'swapIsUsed', Enums.SwapIsUsedEnum),

      getDate(jsonObj, 'useDateTime'),

      getEnum(jsonObj, 'useDateTimeType', Enums.UseDateTimeTypeEnum),
    /* 100 */
      getNullable(jsonObj, 'openIdAcrValuesEmployee', getString),
    /* 100 */
      getNullable(jsonObj, 'openIdAcrValuesPlanner', getString),
    /* 256 */
      getNullable(jsonObj, 'openIdAuthorizationEndpoint', getString),
    /* 50 */
      getNullable(jsonObj, 'openIdClientId', getString),
    /* 256 */
      getNullable(jsonObj, 'openIdLogoutEndpoint', getString),
    /* 256 */
      getNullable(jsonObj, 'openIdRedirectUriEmployee', getString),
    /* 256 */
      getNullable(jsonObj, 'openIdRedirectUriPlanner', getString),
    /* 100 */
      getNullable(jsonObj, 'openIdResource', getString),
    /* 30 */
      getNullable(jsonObj, 'openIdResponseType', getString),
    /* 30 */
      getNullable(jsonObj, 'openIdScopeEmployee', getString),
    /* 30 */
      getNullable(jsonObj, 'openIdScopePlanner', getString)
  );
}

export function getGlobalSettings(jsonObj: StringObj, prop: string): GlobalSettings {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('GlobalSettings', prop);
  assertObject(val);
  return getGlobalSettingsFromJSON(val);
}

// forward declaration; json object store logon settings
//  Responsible JMQ
export class LogonSettings {
  [key: string]: unknown;
  constructor(
    public pwdMinLength: number,
    public pwdMinUniq: number,
    public pwdMinDiff: number,
    public pwdDigits: number,
    public pwdUpperLetters: number,
    public pwdLowerLetters: number,
    public pwdSpecialLetters: number,
    public noPassword: boolean,
    public adPassword: boolean,
    public reuseCheck: number,
    public lockoutDuration: number[],
    public minPwdAge: number,
    public maxPwdAge: number) {}
}

export function getLogonSettingsFromJSON(jsonObj: StringObj): LogonSettings {
  return new LogonSettings(
    /* default(10) minimum password length */
      getInt(jsonObj, 'pwdMinLength'),
    /* default(6) minimum uniq characters */
      getInt(jsonObj, 'pwdMinUniq'),
    /* default(3) minimum number different characters */
      getInt(jsonObj, 'pwdMinDiff'),
    /* default(1) minimum number of digits in a password */
      getInt(jsonObj, 'pwdDigits'),
    /* default(1) minimum number of uppercase letters in a password */
      getInt(jsonObj, 'pwdUpperLetters'),
    /* default(1) minimum number of lowercase letters in a password */
      getInt(jsonObj, 'pwdLowerLetters'),
    /* default(0) minimum number of special letters in a password */
      getInt(jsonObj, 'pwdSpecialLetters'),
    /* default(false) don't use password - something else */
      getBoolean(jsonObj, 'noPassword'),
    /* default(false) don't use password - use Active Directory */
      getBoolean(jsonObj, 'adPassword'),
    /* default(10) how many earlier passwords are checked */
      getInt(jsonObj, 'reuseCheck'),
    /* clob; default([0,0,0,1,2,4,8,15,30,60,60,60,60,480]) duration between failed logons - after last number is used the account is locked */
    getArray(jsonObj, 'lockoutDuration', getIntFromJSON),
    /* default(1) days between password change */
      getInt(jsonObj, 'minPwdAge'),
    /* default(90) maximum number of days between password change */
      getInt(jsonObj, 'maxPwdAge')
  );
}

export function getLogonSettings(jsonObj: StringObj, prop: string): LogonSettings {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('LogonSettings', prop);
  assertObject(val);
  return getLogonSettingsFromJSON(val);
}

// Configuration of a list.
//  Responsible JMQ
export class WebSortConfig {
  [key: string]: unknown;
  constructor(
    public fieldName: string,
    public ascending: boolean) {}
}

export function getWebSortConfigFromJSON(jsonObj: StringObj): WebSortConfig {
  return new WebSortConfig(
    /* 80; Name in the datamodel, name can be an expression */
      getString(jsonObj, 'fieldName'),
    /* default(true) sort order */
      getBoolean(jsonObj, 'ascending')
  );
}

export function getWebSortConfig(jsonObj: StringObj, prop: string): WebSortConfig {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebSortConfig', prop);
  assertObject(val);
  return getWebSortConfigFromJSON(val);
}

// Configuration of a single field.
//  Responsible JMQ
export class WebFieldConfig {
  [key: string]: unknown;
  constructor(
    public fieldName: string,
    public listConfig: WebListConfig | null = null) {}
}

export function getWebFieldConfigFromJSON(jsonObj: StringObj): WebFieldConfig {
  return new WebFieldConfig(
    /* 80; Name in the datamodel, name can be an expression */
      getString(jsonObj, 'fieldName'),
    /* config needed if the field is an objectlist */
      getNullable(jsonObj, 'listConfig', getWebListConfig)
  );
}

export function getWebFieldConfig(jsonObj: StringObj, prop: string): WebFieldConfig {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebFieldConfig', prop);
  assertObject(val);
  return getWebFieldConfigFromJSON(val);
}

// Configuration of a list.
//  Responsible JMQ
export class WebListConfig {
  [key: string]: unknown;
  constructor(
    public fieldList: WebFieldConfig[],
    public sortList: WebSortConfig[] | null = null,
    public filter: string | null = null,
    public webConfig: string | null = null) {}
}

export function getWebListConfigFromJSON(jsonObj: StringObj): WebListConfig {
  return new WebListConfig(
    /* Field Elements */
    getArray(jsonObj, 'fieldList', getWebFieldConfigFromJSON),
    /* sorted by. undefined means [] */
    getNullableArray(jsonObj, 'sortList', getWebSortConfigFromJSON),
    /* 80; objRecord filter expression */
      getNullable(jsonObj, 'filter', getString),
    /* 80; Info about rendering */
      getNullable(jsonObj, 'webConfig', getString)
  );
}

export function getWebListConfig(jsonObj: StringObj, prop: string): WebListConfig {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebListConfig', prop);
  assertObject(val);
  return getWebListConfigFromJSON(val);
}

// List configuration - client side
//  Responsible JMQ
export class WebRecordType {
  [key: string]: unknown;
  constructor(
    public fieldList: WebTypeViewField[],
    public sortList: WebSortConfig[] | null = null,
    public filter: string | null = null,
    public webConfig: string | null = null) {}
}

export function getWebRecordTypeFromJSON(jsonObj: StringObj): WebRecordType {
  return new WebRecordType(
    /* name and type . Field order equals representation order */
    getArray(jsonObj, 'fieldList', getWebTypeViewFieldFromJSON),
    /* sorted by. Undefined means [] */
    getNullableArray(jsonObj, 'sortList', getWebSortConfigFromJSON),
    /* 80; objRecord filter expression */
      getNullable(jsonObj, 'filter', getString),
    /* 80; Info about rendering */
      getNullable(jsonObj, 'webConfig', getString)
  );
}

export function getWebRecordType(jsonObj: StringObj, prop: string): WebRecordType {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WebRecordType', prop);
  assertObject(val);
  return getWebRecordTypeFromJSON(val);
}

// Orgunit identified by Info1, Info2 or Info3.
//  Responsible MB
export class WsRefOrgunitByInfo {
  [key: string]: unknown;
  constructor(
    public info1: string | null = null,
    public info2: string | null = null,
    public info3: string | null = null) {}
}

export function getWsRefOrgunitByInfoFromJSON(jsonObj: StringObj): WsRefOrgunitByInfo {
  return new WsRefOrgunitByInfo(
    /* 256 */
      getNullable(jsonObj, 'info1', getString),
    /* 256 */
      getNullable(jsonObj, 'info2', getString),
    /* 256 */
      getNullable(jsonObj, 'info3', getString)
  );
}

export function getWsRefOrgunitByInfo(jsonObj: StringObj, prop: string): WsRefOrgunitByInfo {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefOrgunitByInfo', prop);
  assertObject(val);
  return getWsRefOrgunitByInfoFromJSON(val);
}

// Reference to Orgunit with different options. First defined option is used. All are optional, but one must be specified
//  Responsible MB
export class WsRefOrgunit {
  [key: string]: unknown;
  constructor(
    public unitid: string | null = null,
    public path: string | null = null,
    public externalkey: string | null = null,
    public info: WsRefOrgunitByInfo | null = null) {}
}

export function getWsRefOrgunitFromJSON(jsonObj: StringObj): WsRefOrgunit {
  return new WsRefOrgunit(
    /* 10 */
      getNullable(jsonObj, 'unitid', getString),
    /* 256 */
      getNullable(jsonObj, 'path', getString),
    /* 256 */
      getNullable(jsonObj, 'externalkey', getString),

      getNullable(jsonObj, 'info', getWsRefOrgunitByInfo)
  );
}

export function getWsRefOrgunit(jsonObj: StringObj, prop: string): WsRefOrgunit {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefOrgunit', prop);
  assertObject(val);
  return getWsRefOrgunitFromJSON(val);
}

// Person identified by SalaryId, optionally qualified by Institution Orgunit or Planning Department Orgunit; When salaryIds are reused validAt parameter may be specified to distinguish different persons
//  Responsible MB
export class WsRefPersonBySalaryId {
  [key: string]: unknown;
  constructor(
    public salaryid: string,
    public validAt: Date | null = null,
    public institution: WsRefOrgunit | null = null,
    public department: WsRefOrgunit | null = null) {}
}

export function getWsRefPersonBySalaryIdFromJSON(jsonObj: StringObj): WsRefPersonBySalaryId {
  return new WsRefPersonBySalaryId(
    /* 80 */
      getString(jsonObj, 'salaryid'),
    /* Validate by person1 validity */
      getNullable(jsonObj, 'validAt', getDate),
    /* Validate by person2 unit. Unit should be under the institution */
      getNullable(jsonObj, 'institution', getWsRefOrgunit),
    /* Validate by person2 unit */
      getNullable(jsonObj, 'department', getWsRefOrgunit)
  );
}

export function getWsRefPersonBySalaryId(jsonObj: StringObj, prop: string): WsRefPersonBySalaryId {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefPersonBySalaryId', prop);
  assertObject(val);
  return getWsRefPersonBySalaryIdFromJSON(val);
}

// Person identified by Aux1, Aux2 or Aux3; If Aux information isn't globally unique then ValidAt, Institution and Department may be specified to distinguish different persons
//  Responsible MB
export class WsRefPersonByAux {
  [key: string]: unknown;
  constructor(
    public aux1: string | null = null,
    public aux2: string | null = null,
    public aux3: string | null = null,
    public validAt: Date | null = null,
    public institution: WsRefOrgunit | null = null,
    public department: WsRefOrgunit | null = null) {}
}

export function getWsRefPersonByAuxFromJSON(jsonObj: StringObj): WsRefPersonByAux {
  return new WsRefPersonByAux(
    /* 256 */
      getNullable(jsonObj, 'aux1', getString),
    /* 256 */
      getNullable(jsonObj, 'aux2', getString),
    /* 256 */
      getNullable(jsonObj, 'aux3', getString),
    /* Validate by person1 validity */
      getNullable(jsonObj, 'validAt', getDate),
    /* Validate by person2 unit. Unit should be under the institution */
      getNullable(jsonObj, 'institution', getWsRefOrgunit),
    /* Validate by person2 unit */
      getNullable(jsonObj, 'department', getWsRefOrgunit)
  );
}

export function getWsRefPersonByAux(jsonObj: StringObj, prop: string): WsRefPersonByAux {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefPersonByAux', prop);
  assertObject(val);
  return getWsRefPersonByAuxFromJSON(val);
}

// Person identified by Name; We require at least one of the qualifiers ValidAt, Institution and Department to distinguish different persons with same name
//  Responsible MB
export class WsRefPersonByName {
  [key: string]: unknown;
  constructor(
    public name: string,
    public validAt: Date | null = null,
    public institution: WsRefOrgunit | null = null,
    public department: WsRefOrgunit | null = null) {}
}

export function getWsRefPersonByNameFromJSON(jsonObj: StringObj): WsRefPersonByName {
  return new WsRefPersonByName(
    /* 50 */
      getString(jsonObj, 'name'),
    /* Validate by person1 validity */
      getNullable(jsonObj, 'validAt', getDate),
    /* Validate by person2 unit. Unit should be under the institution */
      getNullable(jsonObj, 'institution', getWsRefOrgunit),
    /* Validate by person2 unit */
      getNullable(jsonObj, 'department', getWsRefOrgunit)
  );
}

export function getWsRefPersonByName(jsonObj: StringObj, prop: string): WsRefPersonByName {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefPersonByName', prop);
  assertObject(val);
  return getWsRefPersonByNameFromJSON(val);
}

// Reference to person with different options. First defined option is used. All are optional, but one must be specified
//  Responsible MB
export class WsRefPerson {
  [key: string]: unknown;
  constructor(
    public personid: number | null = null,
    public salaryid: WsRefPersonBySalaryId | null = null,
    public auxid: WsRefPersonByAux | null = null,
    public name: WsRefPersonByName | null = null,
    public externalkey: string | null = null) {}
}

export function getWsRefPersonFromJSON(jsonObj: StringObj): WsRefPerson {
  return new WsRefPerson(

      getNullable(jsonObj, 'personid', getInt),

      getNullable(jsonObj, 'salaryid', getWsRefPersonBySalaryId),

      getNullable(jsonObj, 'auxid', getWsRefPersonByAux),

      getNullable(jsonObj, 'name', getWsRefPersonByName),
    /* 256 */
      getNullable(jsonObj, 'externalkey', getString)
  );
}

export function getWsRefPerson(jsonObj: StringObj, prop: string): WsRefPerson {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefPerson', prop);
  assertObject(val);
  return getWsRefPersonFromJSON(val);
}

// ShiftType identified by ExternalKey; Qualifiers Orgunit may be used to distinguish different shiftTypes if ExternalKeys aren't globally unique
//  Responsible MB
export class WsRefShiftTypeByExternalId {
  [key: string]: unknown;
  constructor(
    public externalid: string,
    public orgunit: WsRefOrgunit | null = null) {}
}

export function getWsRefShiftTypeByExternalIdFromJSON(jsonObj: StringObj): WsRefShiftTypeByExternalId {
  return new WsRefShiftTypeByExternalId(
    /* 256 */
      getString(jsonObj, 'externalid'),

      getNullable(jsonObj, 'orgunit', getWsRefOrgunit)
  );
}

export function getWsRefShiftTypeByExternalId(jsonObj: StringObj, prop: string): WsRefShiftTypeByExternalId {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefShiftTypeByExternalId', prop);
  assertObject(val);
  return getWsRefShiftTypeByExternalIdFromJSON(val);
}

// ShiftType identified by Name; Qualifiers Orgunit are typically required to distinguish different shiftTypes with same name
//  Responsible MB
export class WsRefShiftTypeByName {
  [key: string]: unknown;
  constructor(
    public name: string,
    public orgunit: WsRefOrgunit | null = null) {}
}

export function getWsRefShiftTypeByNameFromJSON(jsonObj: StringObj): WsRefShiftTypeByName {
  return new WsRefShiftTypeByName(
    /* 80; shiftTypeLabel */
      getString(jsonObj, 'name'),

      getNullable(jsonObj, 'orgunit', getWsRefOrgunit)
  );
}

export function getWsRefShiftTypeByName(jsonObj: StringObj, prop: string): WsRefShiftTypeByName {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefShiftTypeByName', prop);
  assertObject(val);
  return getWsRefShiftTypeByNameFromJSON(val);
}

// Reference to ShiftType with different options. First defined option is used. All are optional, but one must be specified
//  Responsible MB
export class WsRefShifttype {
  [key: string]: unknown;
  constructor(
    public shiftTypeId: string | null = null,
    public externalId: WsRefShiftTypeByExternalId | null = null,
    public name: WsRefShiftTypeByName | null = null) {}
}

export function getWsRefShifttypeFromJSON(jsonObj: StringObj): WsRefShifttype {
  return new WsRefShifttype(
    /* 9 */
      getNullable(jsonObj, 'shiftTypeId', getString),

      getNullable(jsonObj, 'externalId', getWsRefShiftTypeByExternalId),

      getNullable(jsonObj, 'name', getWsRefShiftTypeByName)
  );
}

export function getWsRefShifttype(jsonObj: StringObj, prop: string): WsRefShifttype {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefShifttype', prop);
  assertObject(val);
  return getWsRefShifttypeFromJSON(val);
}

// Reference to ShiftCount with different options. All are optional, but one must at least be specified
//  Responsible MB
export class WsRefShiftCounter {
  [key: string]: unknown;
  constructor(
    public counterid: number | null = null,
    public counterName: string | null = null) {}
}

export function getWsRefShiftCounterFromJSON(jsonObj: StringObj): WsRefShiftCounter {
  return new WsRefShiftCounter(

      getNullable(jsonObj, 'counterid', getInt),
    /* 80 */
      getNullable(jsonObj, 'counterName', getString)
  );
}

export function getWsRefShiftCounter(jsonObj: StringObj, prop: string): WsRefShiftCounter {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefShiftCounter', prop);
  assertObject(val);
  return getWsRefShiftCounterFromJSON(val);
}

// Reference to Shift definition by internal Id combined with validity.
//  Responsible MB
export class WsRefShiftDefById {
  [key: string]: unknown;
  constructor(
    public shiftid: string,
    public validAt: Date) {}
}

export function getWsRefShiftDefByIdFromJSON(jsonObj: StringObj): WsRefShiftDefById {
  return new WsRefShiftDefById(
    /* 9 */
      getString(jsonObj, 'shiftid'),

      getDate(jsonObj, 'validAt')
  );
}

export function getWsRefShiftDefById(jsonObj: StringObj, prop: string): WsRefShiftDefById {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefShiftDefById', prop);
  assertObject(val);
  return getWsRefShiftDefByIdFromJSON(val);
}

// Reference to a Shift definition by name.
//  Responsible MB
export class WsRefShiftDefByName {
  [key: string]: unknown;
  constructor(
    public name: string,
    public orgunit: WsRefOrgunit,
    public validAt: Date) {}
}

export function getWsRefShiftDefByNameFromJSON(jsonObj: StringObj): WsRefShiftDefByName {
  return new WsRefShiftDefByName(
    /* 80 */
      getString(jsonObj, 'name'),

      getWsRefOrgunit(jsonObj, 'orgunit'),

      getDate(jsonObj, 'validAt')
  );
}

export function getWsRefShiftDefByName(jsonObj: StringObj, prop: string): WsRefShiftDefByName {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefShiftDefByName', prop);
  assertObject(val);
  return getWsRefShiftDefByNameFromJSON(val);
}

// Reference to a Shift definition by externalid. If external id is globally unique then orgunit may be undefined. Otherwise externalid must be qualified by orgunit
//  Responsible MB
export class WsRefShiftDefByExternalId {
  [key: string]: unknown;
  constructor(
    public externalId: string,
    public orgunit: WsRefOrgunit | null = null) {}
}

export function getWsRefShiftDefByExternalIdFromJSON(jsonObj: StringObj): WsRefShiftDefByExternalId {
  return new WsRefShiftDefByExternalId(
    /* 256 */
      getString(jsonObj, 'externalId'),

      getNullable(jsonObj, 'orgunit', getWsRefOrgunit)
  );
}

export function getWsRefShiftDefByExternalId(jsonObj: StringObj, prop: string): WsRefShiftDefByExternalId {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefShiftDefByExternalId', prop);
  assertObject(val);
  return getWsRefShiftDefByExternalIdFromJSON(val);
}

// Reference to a Shift definition with different options. First defined option is used. All are optional, but one must be specified
//  Responsible MB
export class WsRefShiftDef {
  [key: string]: unknown;
  constructor(
    public id: WsRefShiftDefById | null = null,
    public name: WsRefShiftDefByName | null = null,
    public externalId: WsRefShiftDefByExternalId | null = null) {}
}

export function getWsRefShiftDefFromJSON(jsonObj: StringObj): WsRefShiftDef {
  return new WsRefShiftDef(

      getNullable(jsonObj, 'id', getWsRefShiftDefById),

      getNullable(jsonObj, 'name', getWsRefShiftDefByName),

      getNullable(jsonObj, 'externalId', getWsRefShiftDefByExternalId)
  );
}

export function getWsRefShiftDef(jsonObj: StringObj, prop: string): WsRefShiftDef {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefShiftDef', prop);
  assertObject(val);
  return getWsRefShiftDefFromJSON(val);
}

// Reference to Task definition by name.
//  Responsible MB
export class WsRefTaskDefByName {
  [key: string]: unknown;
  constructor(
    public name: string,
    public orgunit: WsRefOrgunit,
    public validAt: Date) {}
}

export function getWsRefTaskDefByNameFromJSON(jsonObj: StringObj): WsRefTaskDefByName {
  return new WsRefTaskDefByName(
    /* 80 */
      getString(jsonObj, 'name'),

      getWsRefOrgunit(jsonObj, 'orgunit'),

      getDate(jsonObj, 'validAt')
  );
}

export function getWsRefTaskDefByName(jsonObj: StringObj, prop: string): WsRefTaskDefByName {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefTaskDefByName', prop);
  assertObject(val);
  return getWsRefTaskDefByNameFromJSON(val);
}

// Reference to Task definition by externalid. If external id is globally unique then orgunit may be undefined. Otherwise externalid must be qualified by orgunit
//  Responsible MB
export class WsRefTaskDefByExternalId {
  [key: string]: unknown;
  constructor(
    public externalId: string,
    public orgunit: WsRefOrgunit | null = null) {}
}

export function getWsRefTaskDefByExternalIdFromJSON(jsonObj: StringObj): WsRefTaskDefByExternalId {
  return new WsRefTaskDefByExternalId(
    /* 256 */
      getString(jsonObj, 'externalId'),

      getNullable(jsonObj, 'orgunit', getWsRefOrgunit)
  );
}

export function getWsRefTaskDefByExternalId(jsonObj: StringObj, prop: string): WsRefTaskDefByExternalId {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefTaskDefByExternalId', prop);
  assertObject(val);
  return getWsRefTaskDefByExternalIdFromJSON(val);
}

// Reference to Task definition with different options. First defined option is used. All are optional, but one must be specified
//  Responsible MB
export class WsRefTaskDef {
  [key: string]: unknown;
  constructor(
    public id: number | null = null,
    public name: WsRefTaskDefByName | null = null,
    public externalId: WsRefTaskDefByExternalId | null = null) {}
}

export function getWsRefTaskDefFromJSON(jsonObj: StringObj): WsRefTaskDef {
  return new WsRefTaskDef(

      getNullable(jsonObj, 'id', getInt),

      getNullable(jsonObj, 'name', getWsRefTaskDefByName),

      getNullable(jsonObj, 'externalId', getWsRefTaskDefByExternalId)
  );
}

export function getWsRefTaskDef(jsonObj: StringObj, prop: string): WsRefTaskDef {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefTaskDef', prop);
  assertObject(val);
  return getWsRefTaskDefFromJSON(val);
}

// Reference to Task by name.
//  Responsible MB
export class WsRefTaskByName {
  [key: string]: unknown;
  constructor(
    public name: string,
    public orgunit: WsRefOrgunit,
    public validAt: Date) {}
}

export function getWsRefTaskByNameFromJSON(jsonObj: StringObj): WsRefTaskByName {
  return new WsRefTaskByName(
    /* 80 */
      getString(jsonObj, 'name'),

      getWsRefOrgunit(jsonObj, 'orgunit'),

      getDate(jsonObj, 'validAt')
  );
}

export function getWsRefTaskByName(jsonObj: StringObj, prop: string): WsRefTaskByName {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefTaskByName', prop);
  assertObject(val);
  return getWsRefTaskByNameFromJSON(val);
}

// Reference to Task definition by externalid. If external id is globally unique then orgunit may be undefined. Otherwise externalid must be qualified by orgunit
//  Responsible MB
export class WsRefTaskByExternalId {
  [key: string]: unknown;
  constructor(
    public externalId: string,
    public orgunit: WsRefOrgunit | null = null) {}
}

export function getWsRefTaskByExternalIdFromJSON(jsonObj: StringObj): WsRefTaskByExternalId {
  return new WsRefTaskByExternalId(
    /* 256 */
      getString(jsonObj, 'externalId'),

      getNullable(jsonObj, 'orgunit', getWsRefOrgunit)
  );
}

export function getWsRefTaskByExternalId(jsonObj: StringObj, prop: string): WsRefTaskByExternalId {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefTaskByExternalId', prop);
  assertObject(val);
  return getWsRefTaskByExternalIdFromJSON(val);
}

// Reference to Task with different options. First defined option is used. All are optional, but one must be specified
//  Responsible MB
export class WsRefTask {
  [key: string]: unknown;
  constructor(
    public id: number | null = null,
    public name: WsRefTaskByName | null = null,
    public externalId: WsRefTaskByExternalId | null = null) {}
}

export function getWsRefTaskFromJSON(jsonObj: StringObj): WsRefTask {
  return new WsRefTask(

      getNullable(jsonObj, 'id', getInt),

      getNullable(jsonObj, 'name', getWsRefTaskByName),

      getNullable(jsonObj, 'externalId', getWsRefTaskByExternalId)
  );
}

export function getWsRefTask(jsonObj: StringObj, prop: string): WsRefTask {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefTask', prop);
  assertObject(val);
  return getWsRefTaskFromJSON(val);
}

// Account identified by Name; Qualifiers Orgunit are typically required to distinguish different Account with same name
//  Responsible AW
export class WsRefAccountByName {
  [key: string]: unknown;
  constructor(
    public name: string,
    public orgunit: WsRefOrgunit) {}
}

export function getWsRefAccountByNameFromJSON(jsonObj: StringObj): WsRefAccountByName {
  return new WsRefAccountByName(
    /* 80; Account label */
      getString(jsonObj, 'name'),

      getWsRefOrgunit(jsonObj, 'orgunit')
  );
}

export function getWsRefAccountByName(jsonObj: StringObj, prop: string): WsRefAccountByName {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefAccountByName', prop);
  assertObject(val);
  return getWsRefAccountByNameFromJSON(val);
}

// Reference to Account with different options. First defined option is used. All are optional, but one must be specified
//  Responsible AW
export class WsRefAccount {
  [key: string]: unknown;
  constructor(
    public accountId: number | null = null,
    public externalId: string | null = null,
    public name: WsRefAccountByName | null = null) {}
}

export function getWsRefAccountFromJSON(jsonObj: StringObj): WsRefAccount {
  return new WsRefAccount(

      getNullable(jsonObj, 'accountId', getInt),
    /* 256 */
      getNullable(jsonObj, 'externalId', getString),

      getNullable(jsonObj, 'name', getWsRefAccountByName)
  );
}

export function getWsRefAccount(jsonObj: StringObj, prop: string): WsRefAccount {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefAccount', prop);
  assertObject(val);
  return getWsRefAccountFromJSON(val);
}

// Project identified by Name; Qualifiers Orgunit are typically required to distinguish different Project with same name
//  Responsible AW
export class WsRefProjectByName {
  [key: string]: unknown;
  constructor(
    public name: string,
    public orgunit: WsRefOrgunit) {}
}

export function getWsRefProjectByNameFromJSON(jsonObj: StringObj): WsRefProjectByName {
  return new WsRefProjectByName(
    /* 80; project label */
      getString(jsonObj, 'name'),

      getWsRefOrgunit(jsonObj, 'orgunit')
  );
}

export function getWsRefProjectByName(jsonObj: StringObj, prop: string): WsRefProjectByName {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefProjectByName', prop);
  assertObject(val);
  return getWsRefProjectByNameFromJSON(val);
}

// Reference to project with different options. First defined option is used. All are optional, but one must be specified
//  Responsible AW
export class WsRefProject {
  [key: string]: unknown;
  constructor(
    public projectId: number | null = null,
    public externalId: string | null = null,
    public name: WsRefProjectByName | null = null) {}
}

export function getWsRefProjectFromJSON(jsonObj: StringObj): WsRefProject {
  return new WsRefProject(

      getNullable(jsonObj, 'projectId', getInt),
    /* 256 */
      getNullable(jsonObj, 'externalId', getString),

      getNullable(jsonObj, 'name', getWsRefProjectByName)
  );
}

export function getWsRefProject(jsonObj: StringObj, prop: string): WsRefProject {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefProject', prop);
  assertObject(val);
  return getWsRefProjectFromJSON(val);
}

// Dimension item identified by Name
//  Responsible AW
export class WsRefDimensionItemByName {
  [key: string]: unknown;
  constructor(
    public name: string,
    public dimension: Enums.DimensionEnumEnum,
    public orgunit: WsRefOrgunit) {}
}

export function getWsRefDimensionItemByNameFromJSON(jsonObj: StringObj): WsRefDimensionItemByName {
  return new WsRefDimensionItemByName(
    /* 80 */
      getString(jsonObj, 'name'),

      getEnum(jsonObj, 'dimension', Enums.DimensionEnumEnum),

      getWsRefOrgunit(jsonObj, 'orgunit')
  );
}

export function getWsRefDimensionItemByName(jsonObj: StringObj, prop: string): WsRefDimensionItemByName {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefDimensionItemByName', prop);
  assertObject(val);
  return getWsRefDimensionItemByNameFromJSON(val);
}

// Reference to Dimension item with different options. First defined option is used. All are optional, but one must be specified
//  Responsible AW
export class WsRefDimensionItem {
  [key: string]: unknown;
  constructor(
    public dimensionItemId: number | null = null,
    public name: WsRefDimensionItemByName | null = null) {}
}

export function getWsRefDimensionItemFromJSON(jsonObj: StringObj): WsRefDimensionItem {
  return new WsRefDimensionItem(

      getNullable(jsonObj, 'dimensionItemId', getInt),

      getNullable(jsonObj, 'name', getWsRefDimensionItemByName)
  );
}

export function getWsRefDimensionItem(jsonObj: StringObj, prop: string): WsRefDimensionItem {
  const val: unknown = jsonObj[prop];
  if (isNullish(val)) nullishError('WsRefDimensionItem', prop);
  assertObject(val);
  return getWsRefDimensionItemFromJSON(val);
}


////////////////// 2. Classes defining jsonRpc methods returning data value objects /////////////////////////
/*
    These classes are responsible for deserialize and serialize from and to toJSON
    into the respectfull objects needed.
    AND
    Are responsible for knowing exact method and url endpoint to call with json RPC
*/

/**     % Necessary settings before login, e.g. useDate, logonMethod and OpenID configuration
Responsible: AGRU */
export class GetGlobalSettings extends WebRequest<GlobalSettings> {
  public webService = 'common' as const;
  public method = 'GetGlobalSettings' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<GlobalSettings>): GlobalSettings {
    return getGlobalSettingsFromJSON(json.result);
  }
}

/**     % Returns the orgunit with the unitid if the orgunit can be found
Responsible: AW */
export class FindOrgunit extends WebRequest<WsRefOrgunit> {
  public webService = 'common' as const;
  public method = 'FindOrgunit' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public orgunit: WsRefOrgunit,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefOrgunit>): WsRefOrgunit {
    return getWsRefOrgunitFromJSON(json.result);
  }
}

/**     % Returns the person with the personid if the person can be found
Responsible: AW */
export class FindPerson extends WebRequest<WsRefPerson> {
  public webService = 'common' as const;
  public method = 'FindPerson' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public person: WsRefPerson,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefPerson>): WsRefPerson {
    return getWsRefPersonFromJSON(json.result);
  }
}

/**     % Returns the shifttype with the shifttypeId if the shifttype can be found
Responsible: AW */
export class FindShifttype extends WebRequest<WsRefShifttype> {
  public webService = 'common' as const;
  public method = 'FindShifttype' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public shifttype: WsRefShifttype,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefShifttype>): WsRefShifttype {
    return getWsRefShifttypeFromJSON(json.result);
  }
}

/**     % Returns the shiftCounter with the counterid if the shiftCounter can be found
Responsible: AW */
export class FindShiftCounter extends WebRequest<WsRefShiftCounter> {
  public webService = 'common' as const;
  public method = 'FindShiftCounter' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public shiftCounter: WsRefShiftCounter,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefShiftCounter>): WsRefShiftCounter {
    return getWsRefShiftCounterFromJSON(json.result);
  }
}

/**     % Returns the ShiftDef with the ShiftDefId if the ShiftDef can be found
Responsible: AW */
export class FindShiftDef extends WebRequest<WsRefShiftDef> {
  public webService = 'common' as const;
  public method = 'FindShiftDef' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public shiftdef: WsRefShiftDef,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefShiftDef>): WsRefShiftDef {
    return getWsRefShiftDefFromJSON(json.result);
  }
}

/**     % Returns the TaskDef with the TaskDef id if the TaskDef can be found
Responsible: AW */
export class FindTaskDef extends WebRequest<WsRefTaskDef> {
  public webService = 'common' as const;
  public method = 'FindTaskDef' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public taskdef: WsRefTaskDef,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefTaskDef>): WsRefTaskDef {
    return getWsRefTaskDefFromJSON(json.result);
  }
}

/**     % Returns the Task with the Task id if the Task can be found
Responsible: AW */
export class FindTask extends WebRequest<WsRefTask> {
  public webService = 'common' as const;
  public method = 'FindTask' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public task: WsRefTask,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefTask>): WsRefTask {
    return getWsRefTaskFromJSON(json.result);
  }
}

/**     % Returns the Account with the wsRefAccount if the account can be found
Responsible: AW */
export class FindAccount extends WebRequest<WsRefAccount> {
  public webService = 'common' as const;
  public method = 'FindAccount' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public account: WsRefAccount,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefAccount>): WsRefAccount {
    return getWsRefAccountFromJSON(json.result);
  }
}

/**     % Returns the project with the wsRefProject if the project can be found
Responsible: AW */
export class FindProject extends WebRequest<WsRefProject> {
  public webService = 'common' as const;
  public method = 'FindProject' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public project: WsRefProject,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefProject>): WsRefProject {
    return getWsRefProjectFromJSON(json.result);
  }
}

/**     % Returns the DimensionItem with the wsRefDimensionItem if the DimensionItem  can be found
Responsible: AW */
export class FindDimensionItem extends WebRequest<WsRefDimensionItem> {
  public webService = 'common' as const;
  public method = 'FindDimensionItem' as const;
  public noUserValidation = true as const;
  public noAuditAction = false as const;
  constructor(
    public dimensionItem: WsRefDimensionItem,
) {
    super();
  }
  resultFromJSON(json: WebResponseResult<WsRefDimensionItem>): WsRefDimensionItem {
    return getWsRefDimensionItemFromJSON(json.result);
  }
}

