import {
  AbsenceType,
  CallIn,
  Person,
  Team,
  TeamDuty,
  TeamShift,
  TeamShiftDef,
} from '../api/TeamPlan_api';
import {
  Button,
  ButtonIconPosition,
  ButtonStyle,
  IconSize,
  Icons,
  LoadingButton,
} from '@pdcfrontendui/components';
import { getShiftStatusStyling, getShiftStatusText } from '../util/shifts';

import { Duties } from './Duties';
import { EmployeeMap } from '../api/model';
import { Item } from '../components';
import React from 'react';
import RegisterAbsenceSection from './Sections/RegisterAbsenceSection';
import { TeamShiftStatusEnum } from '../api/enumLib_api';
import { WebRecordType } from '../api/Common_api';
import classNames from 'classnames';
import colorVarMap from '@pdcfrontendui/components/colors/colors';
import { currentLanguage } from '../currentLanguage';
import dateFns from 'date-fns';
import { formatActivityOrDutylineTime } from '../util/dates';
import ids from '../testing/ids';
import { ActionRequiredCard } from './ActionRequiredCard';
import {
  isCreatedShift,
  isEditedShift,
  ShiftDraft,
  teamShiftToEditedShift,
} from '../ListView/CreateShift/EditedShift';

function ShiftInfoEntry({
  label,
  children,
  className,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={className}>
      <div>{label}</div>
      {typeof children === 'string' ? <div>{children}</div> : children}
    </div>
  );
}

export function ShiftInfo({
  shift,
  isAssigningShift,
  absenceType,
  dutyLines,
  shiftRecordType,
  children,
  substitute,
  onRemoveShift,
  openEditShift,
  shiftDraft,
  attemptEndAbsence,
  formerAssignee,
  employee,
  alwaysShowOriginalEmployee,
  hideStatus,
}: {
  shift: TeamShift;
  isAssigningShift?: boolean;
  absenceType?: AbsenceType;
  dutyLines: TeamDuty[];
  shiftRecordType?: WebRecordType;
  children?: React.ReactNode;
  substitute?: CallIn;
  onRemoveShift?: () => void;
  isCreatedShift?: boolean;
  employee?: Person;
  alwaysShowOriginalEmployee?: boolean;
  formerAssignee?: Person;
  hideStatus?: boolean;
  attemptEndAbsence?: () => void;
  openEditShift?: () => void;
  shiftDraft: ShiftDraft | null;
}) {
  // Editing an offered shift is allowed by the backend, but it would cause the offer to be removed.
  // There are other ways to achieve the same thing, so we don't allow it in the frontend.
  const editable =
    shift.isTimeEditable &&
    shift.status !== TeamShiftStatusEnum.offered &&
    !!openEditShift;
  return (
    <div
      className={classNames('shift-information-section', {
        ['itemToAssign']: isAssigningShift,
      })}
    >
      <h3 className="shift-information-title">
        <div className="shift-information-label">
          <div>{shift.label}</div>
          {shift.responsibilities.length > 0 && (
            <div>
              {shift.responsibilities.map((val, i) => (
                <div key={i}>{val}</div>
              ))}
            </div>
          )}
        </div>
        {!shiftDraft &&
          !isAssigningShift &&
          (editable ? (
            <Icons.Edit
              color={colorVarMap.colorPrimaryDark}
              onClick={openEditShift}
              ariaLabel={currentLanguage.EditShift}
              className="editOrDelete"
            />
          ) : (
            !attemptEndAbsence &&
            onRemoveShift &&
            shift.status !== TeamShiftStatusEnum.actionRequired && (
              <Icons.Delete
                color={colorVarMap.colorErrorDark}
                onClick={onRemoveShift}
                ariaLabel={currentLanguage.RemoveShift}
                className="editOrDelete"
              />
            )
          ))}
      </h3>
      {employee &&
        (alwaysShowOriginalEmployee ||
          isAssigningShift ||
          shift.status === TeamShiftStatusEnum.actionRequired) &&
        !isCreatedShift(shiftDraft) && (
          <div className="originalEmployee">
            {`${currentLanguage.OriginalBelongingTo} ${employee.name}`}
          </div>
        )}
      <div className={'info-table'}>
        {absenceType && (
          <ShiftInfoEntry label={currentLanguage.absenceType}>
            {absenceType.label}
          </ShiftInfoEntry>
        )}
        {!hideStatus && !isAssigningShift && (
          <ShiftInfoEntry
            label={currentLanguage.Status}
            className={classNames(
              'shift-status',
              getShiftStatusStyling(shift.status)
            )}
          >
            {shift.status === TeamShiftStatusEnum.offerActionRequired
              ? currentLanguage.NumApplicantsFromShiftExchange_1(
                  shift.numSwapSuggestions ?? 0
                )
              : getShiftStatusText(shift.status)}
          </ShiftInfoEntry>
        )}

        {shift.deviatingPayerUiLabel && (
          <ShiftInfoEntry label={currentLanguage.Location}>
            <div className="deviating-payer">
              <Icons.Info size={IconSize.XXXSmall} />
              <span>{shift.deviatingPayerUiLabel}</span>
            </div>
          </ShiftInfoEntry>
        )}

        {formerAssignee && (
          <ShiftInfoEntry label={currentLanguage.FormerAssignee}>
            {formerAssignee.name}
          </ShiftInfoEntry>
        )}
        {!!substitute && substitute.descCause && (
          <ShiftInfoEntry label={currentLanguage.RuleBreak}>
            <div className="rule-break-priority-status">
              {substitute.priority && (
                <div>{Math.floor(substitute.priority)}:&nbsp;</div>
              )}
              <div>{substitute.descCause}</div>
            </div>
          </ShiftInfoEntry>
        )}
        {shift.activities.length > 0 && (
          <ShiftInfoEntry label={currentLanguage.Activities}>
            <div>
              {shift.activities.map((activity, index) => (
                <div key={index}>
                  <div>{formatActivityOrDutylineTime(activity)}</div>
                  <div>{activity.label}</div>
                </div>
              ))}
            </div>
          </ShiftInfoEntry>
        )}
        {shift.tasks.length > 0 && (
          <ShiftInfoEntry label={currentLanguage.Tasks}>
            <div>
              {shift.tasks.map((task, index) => (
                <div key={index}>
                  <div>{formatActivityOrDutylineTime(task)}</div>
                  <div>{task.label}</div>
                </div>
              ))}
            </div>
          </ShiftInfoEntry>
        )}
        {dutyLines.length > 0 && (
          <div>
            <Duties dutyLines={dutyLines} />
          </div>
        )}
        {shiftRecordType?.fieldList.map((type, index) => {
          const rec = shift.record.filter((r) => r.name === type.fieldName)[0];
          if (rec?.value && rec.value.length > 0) {
            return (
              <ShiftInfoEntry key={index} label={type.label}>
                {rec.value}
              </ShiftInfoEntry>
            );
          }
          return null;
        })}
        {attemptEndAbsence && (
          <Button
            variant={ButtonStyle.Ghost}
            danger
            onClick={attemptEndAbsence}
            className="endAbsence"
          >
            {currentLanguage.EndAbsence}
          </Button>
        )}
      </div>
      {children}
    </div>
  );
}

export default function ShiftCard({
  team,
  shift,
  personId,
  employees,
  absenceTypes,
  shiftRecordType,
  currentDate,
  loading,
  isAssigningShift,
  canCancelOffer,
  callFindSubstitute,
  callAssignShift,
  callMarkAsHandled,
  callRegisterAbsence,
  callCancelOfferOnShiftExchange,
  onRemoveShift,
  setSwapViewOpen,
  setOfferViewOpen,
  loadingSwap,
  substitute,
  isSwap,
  setEditedShift,
  attemptEndAbsence,
  shiftDef,
  shiftDraft,
}: {
  employees: EmployeeMap;
  team: Team;
  shift: TeamShift;
  personId: number;
  isAssigningShift: boolean;
  currentDate: Date;
  absenceTypes: AbsenceType[];
  shiftRecordType: WebRecordType;
  loading: boolean;
  loadingSwap: boolean;
  substitute?: CallIn;
  isSwap: boolean;
  canCancelOffer: boolean;
  shiftDef: TeamShiftDef | null;
  shiftDraft: ShiftDraft | null;
  callFindSubstitute: (
    teamId: string,
    shiftId: string,
    personId: number
  ) => void;
  callAssignShift: (
    teamId: string,
    personId: number,
    employeeName: string,
    shiftId: string
  ) => void;
  callMarkAsHandled: (
    teamId: string,
    shiftId: string,
    personId: number
  ) => void;
  callRegisterAbsence: (
    teamId: string,
    shiftId: string,
    absenceTypeId: string,
    returnDate: Date | null
  ) => void;
  callCancelOfferOnShiftExchange: (
    teamId: string,
    personId: number,
    shiftId: string
  ) => void;
  onRemoveShift: () => void;
  setSwapViewOpen: (open: boolean) => void;
  setOfferViewOpen: (open: boolean) => void;
  attemptEndAbsence?: () => void;
  setEditedShift: (draft: ShiftDraft | null) => void;
}) {
  const employee = employees[shift.personId];
  // TODO: fix on wi149459
  // const isFictive = !!employee?.isFictive;
  const isFictive = false;
  const canRegisterAbsence =
    !isFictive &&
    (shift.status === TeamShiftStatusEnum.planned ||
      shift.status === TeamShiftStatusEnum.substitute ||
      shift.status === TeamShiftStatusEnum.noActivities) &&
    !isAssigningShift;

  const actionRequired =
    !isAssigningShift && shift.status === TeamShiftStatusEnum.actionRequired;

  // Show original duties if shift is assignable, and not the absense
  // And if action is required, show the original duties since the absence duties are shown on a separate card
  const dutyLines = [
    ...(actionRequired || isAssigningShift
      ? shift.dutyLinesOriginal
      : shift.dutyLines),
  ].sort((a, b) => {
    if (a.start === null && b.start === null) return 0;
    if (a.start === null) return 1;
    if (b.start === null) return -1;
    return a.start - b.start;
  });

  const absenceType =
    shift.status === TeamShiftStatusEnum.planned ||
    shift.status === TeamShiftStatusEnum.substitute ||
    shift.status === TeamShiftStatusEnum.noActivities ||
    shift.status === TeamShiftStatusEnum.swapActionRequired ||
    isAssigningShift
      ? undefined
      : absenceTypes.find((absenceType) => absenceType.id === shift.type);

  const showGoToSwapOrChooseApplicants =
    shift.status === TeamShiftStatusEnum.offerActionRequired ||
    shift.status === TeamShiftStatusEnum.swapActionRequired;

  const showAbsenceCard =
    (shift.status === TeamShiftStatusEnum.actionRequired ||
      shift.status === TeamShiftStatusEnum.absent ||
      shift.status === TeamShiftStatusEnum.replaced) &&
    !isAssigningShift;

  const showPlannedWorkCard =
    shift.status !== TeamShiftStatusEnum.actionRequired && !showAbsenceCard;

  const openEditShift =
    employee && !isAssigningShift
      ? () => {
          setEditedShift(teamShiftToEditedShift(shift, shiftDef, employee));
        }
      : undefined;

  return (
    <>
      {/* This animates and should always be rendered, although it's only relevant for absences */}
      <ActionRequiredCard
        // Prevent triggering animations when selecting another shift
        key={shift.id}
        callFindSubstitute={callFindSubstitute}
        callMarkAsHandled={callMarkAsHandled}
        isCreatedShift={isCreatedShift(shiftDraft)}
        openOfferView={() => setOfferViewOpen(true)}
        shift={shift}
        team={team}
        employee={employee}
        isAssigningShift={isAssigningShift}
        openEditShift={openEditShift}
      >
        <ShiftInfo
          dutyLines={dutyLines}
          shiftDraft={shiftDraft}
          shift={{ ...shift, status: TeamShiftStatusEnum.actionRequired }}
          shiftRecordType={shiftRecordType}
          isAssigningShift={isAssigningShift}
          formerAssignee={employees[shift.formerPersonId]}
          employee={employees[shift.personId]}
          alwaysShowOriginalEmployee
        />
      </ActionRequiredCard>
      {(showPlannedWorkCard || isAssigningShift) && (
        <div className="shift">
          <ShiftInfo
            dutyLines={dutyLines}
            isCreatedShift={!!shiftDraft}
            shiftDraft={shiftDraft}
            shift={shift}
            shiftRecordType={shiftRecordType}
            isAssigningShift={isAssigningShift}
            formerAssignee={employees[shift.formerPersonId]}
            onRemoveShift={onRemoveShift}
            employee={employees[shift.personId]}
            substitute={substitute}
            openEditShift={openEditShift}
          >
            {substitute?.assignable && isAssigningShift && (
              <Button
                className="assign-shift-button"
                onClick={() => {
                  const name = employees[personId]?.name;
                  if (name) {
                    callAssignShift(team.id, personId, name, shift.id);
                  }
                }}
                id={ids.ShiftView.assign}
              >
                {currentLanguage.assignShift}
              </Button>
            )}
          </ShiftInfo>
          {showGoToSwapOrChooseApplicants && (
            <div className="swap-handover-button-container">
              <LoadingButton
                id={ids.ShiftView.goToSwap}
                onClick={() => setSwapViewOpen(true)}
                variant={ButtonStyle.Ghost}
                danger
                icon={Icons.ChevronRight}
                iconPosition={ButtonIconPosition.Right}
                loading={loadingSwap}
              >
                {isSwap
                  ? currentLanguage.GoToSwap
                  : currentLanguage.ChooseApplicants}
              </LoadingButton>
            </div>
          )}
          {canRegisterAbsence && (
            <RegisterAbsenceSection
              key={shift.id}
              onRegisterAbsenceClick={(absenceTypeId, returnDate) => {
                callRegisterAbsence(
                  team.id,
                  shift.id,
                  absenceTypeId,
                  returnDate
                );
              }}
              absenceTypes={absenceTypes}
              shift={shift}
              disabled={
                dateFns.differenceInCalendarDays(
                  shift.period.from,
                  currentDate
                ) < 0
              }
              loading={loading}
            />
          )}
          {canCancelOffer && (
            <Item
              className="cancelExchange"
              id={ids.ShiftView.exchange}
              onClick={() => {
                callCancelOfferOnShiftExchange(team.id, personId, shift.id);
              }}
            >
              <div className="label">
                <Icons.Swap size={IconSize.XSmall} className="icon" />
                {currentLanguage.cancelOfferOnShiftExchange}
              </div>
            </Item>
          )}
        </div>
      )}
      {showAbsenceCard && (
        <ShiftInfo
          dutyLines={
            isEditedShift(shiftDraft)
              ? shiftDraft.originalShift.dutyLines
              : shift.dutyLines
          }
          shift={shift}
          absenceType={absenceType}
          hideStatus={
            shift.status === TeamShiftStatusEnum.actionRequired &&
            !isCreatedShift(shiftDraft) &&
            !isAssigningShift
          }
          attemptEndAbsence={attemptEndAbsence}
          shiftDraft={shiftDraft}
        />
      )}
    </>
  );
}
