import './MenuLists.scss';

import * as api from '../api/TeamPlan_api';

import { FavoriteStatusEnum, NotificationStatusEnum } from '../api/enumLib_api';
import { useMemo, useState } from 'react';

import { MenuItemLoadedType } from './MenuReducer';
import NoPersonelGroupFollowed from './NoPersonelGroupFollowed';
import NonTouchMenuItem from './NonTouchMenuItem';
import { Team } from '../api/TeamPlan_api';
import TouchMenuItem from './TouchMenuItem';
import { currentLanguage } from '../currentLanguage';
import { hasProperty } from '@pdcfrontendui/utils';
import ids from '../testing/ids';

export const scss = {
  Comp: 'TouchMenu',
  title: 'title',
  teamContents: 'teamContents',
  teamLabel: 'teamLabel',
  teamOrg: 'teamOrg',
  additionalGroupList: 'additionalGroupList',
  followingGroupList: 'followingGroupList',
  listItem: 'listItem',
  iconList: 'iconList',
  unresolved: 'unresolved',
  chat: 'chat',
  download: 'download',
  spinner: 'spinner',
  menuButton: 'menuButton',
  downloadMenu: 'downloadMenu',
  teamButton: 'teamButton',
  contextMenu: 'contextMenu',
  visible: 'visible',
  nonClickableIcons: 'nonClickableIcons',
};

type Props = {
  teams: api.Team[];
  currentTeam: string;
  useTouch: boolean;
  actionsRequired: Record<string, number>;
  unreadCountMap: Record<string, number>;
  isSearching: boolean;
  menuItemLoading: MenuItemLoadedType;
  onItemClick: (id: string) => void;
  onFavoriteClick: (team: Team, status: FavoriteStatusEnum) => void;
  onNotificationClick: (id: string, status: NotificationStatusEnum) => void;
  onDownloadClick: (id: string) => void;
};

const MenuLists = ({
  teams,
  currentTeam,
  useTouch,
  actionsRequired,
  unreadCountMap,
  isSearching,
  menuItemLoading,
  onItemClick,
  onFavoriteClick,
  onNotificationClick,
  onDownloadClick,
}: Props) => {
  const [openedMenu, setOpenedMenu] = useState<string>('');

  const followedPersonelGroups = useMemo(
    () =>
      teams.filter(
        (team) => team.favoriteStatus === FavoriteStatusEnum.favorite
      ),
    [teams]
  );

  const nonFollowedPersonelGroups = useMemo(
    () =>
      teams.filter(
        (team) => team.favoriteStatus === FavoriteStatusEnum.nothing
      ),
    [teams]
  );

  const handleNotificationClick = (team: Team) => {
    onNotificationClick(
      team.id,
      team.notificationStatus === NotificationStatusEnum.allNotifications
        ? NotificationStatusEnum.noNotifications
        : NotificationStatusEnum.allNotifications
    );
  };

  const handleFollowClick = (team: Team, status: FavoriteStatusEnum) => {
    setOpenedMenu('');
    onFavoriteClick(team, status);
  };

  const renderMenuItem = (team: Team, isFollowed: boolean) => {
    return (
      <div
        className={scss.listItem}
        key={team.id}
        id={ids.Menu.sideMenu}
        role="listitem"
      >
        {!useTouch ? (
          <NonTouchMenuItem
            teamId={team.id}
            isActive={team.id === currentTeam}
            isLoaded={hasProperty(actionsRequired, team.id)}
            isNotificationDisabled={
              team.notificationStatus === NotificationStatusEnum.noNotifications
            }
            label={team.label}
            groupLabel={team.unitUilabel}
            unreadMessageCount={unreadCountMap[team.id] || 0}
            actionsRequiredCount={actionsRequired[team.id] ?? 0}
            isFollowed={isFollowed}
            onItemClick={() => onItemClick(team.id)}
            menuItemLoading={menuItemLoading}
            onFollowClick={() =>
              handleFollowClick(
                team,
                isFollowed
                  ? FavoriteStatusEnum.nothing
                  : FavoriteStatusEnum.favorite
              )
            }
            onNotificationClick={() => handleNotificationClick(team)}
            onDownloadClick={() => onDownloadClick(team.id)}
          />
        ) : (
          <TouchMenuItem
            isOpen={team.id === openedMenu}
            isActive={team.id === currentTeam}
            isLoaded={hasProperty(actionsRequired, team.id)}
            isNotificationDisabled={
              team.notificationStatus === NotificationStatusEnum.noNotifications
            }
            label={team.label}
            groupLabel={team.unitUilabel}
            unreadMessageCount={unreadCountMap[team.id] || 0}
            actionsRequiredCount={actionsRequired[team.id] ?? 0}
            isFollowed={isFollowed}
            teamId={team.id}
            menuItemLoading={menuItemLoading}
            onOpen={() => setOpenedMenu(team.id)}
            onClose={() => setOpenedMenu('')}
            onItemClick={() => onItemClick(team.id)}
            onFollowClick={() =>
              handleFollowClick(
                team,
                isFollowed
                  ? FavoriteStatusEnum.nothing
                  : FavoriteStatusEnum.favorite
              )
            }
            onNotificationClick={() => handleNotificationClick(team)}
            onDownloadClick={() => onDownloadClick(team.id)}
          />
        )}
      </div>
    );
  };

  return (
    <div className={scss.Comp}>
      <div className={scss.title}>{currentLanguage.GroupsFollowed}</div>
      <div className={scss.followingGroupList} role="list">
        {followedPersonelGroups.length === 0 && isSearching && (
          <NoPersonelGroupFollowed />
        )}
        {followedPersonelGroups.length > 0 &&
          followedPersonelGroups.map((team) => renderMenuItem(team, true))}
      </div>
      <br />
      <div className={scss.title}>{currentLanguage.OtherGroups}</div>
      <div className={scss.additionalGroupList} role="list">
        {nonFollowedPersonelGroups.map((team) => {
          return renderMenuItem(team, false);
        })}
      </div>
    </div>
  );
};

export default MenuLists;
