import * as dateFns from 'date-fns';

import { ButtonSize, ButtonStyle } from '@pdcfrontendui/components/Button';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { dateFromString, dateToString, localeFormat } from '../../util/dates';

import { ABSENCE_EXPECTED_BACK_MAX } from '../../constants';
import { AbsenceType } from '../../api/TeamPlan_api';
import { AvailabilityOpenHandlingEnum } from '../../api/enumLib_api';
import LoadingButton from '@pdcfrontendui/components/LoadingButton';
import { Section } from '../../components';
import SelectInput from '@pdcfrontendui/components/SelectInput';
import { TeamShift } from '../../api/TeamPlan_api';
import { currentLanguage } from '../../currentLanguage';
import ids from '../../testing/ids';

type ExpectedBackOption = {
  id: string;
  label: string;
};

export type StateFromProps = {
  loading: boolean;
  absenceTypes: AbsenceType[];
  shift: TeamShift;
  disabled: boolean;
};

export type DispatchFromProps = {
  onRegisterAbsenceClick: (
    absenceTypeId: string,
    returnDate: Date | null
  ) => void;
};

const RegisterAbsenceSection = ({
  shift,
  disabled,
  absenceTypes,
  loading,
  onRegisterAbsenceClick,
}: DispatchFromProps & StateFromProps) => {
  const [selectedAbsenceType, setSelectedAbsenceType] = useState({
    id: '',
    label: '',
    openable: AvailabilityOpenHandlingEnum.disabled,
  });

  const selectedAbsenceTypeIsValid = useMemo(
    () =>
      !!absenceTypes.length &&
      absenceTypes.some((absenceType) => selectedAbsenceType === absenceType),
    [selectedAbsenceType, absenceTypes]
  );

  const expectedBackOptions = useMemo(() => {
    const expectedBackOptions: ExpectedBackOption[] = dateFns
      .eachDay(
        dateFns.addDays(shift.period.from, 1),
        dateFns.addDays(shift.period.from, ABSENCE_EXPECTED_BACK_MAX)
      )
      .map((date) => ({
        id: dateToString(date),
        label: localeFormat(date, false, currentLanguage.languageCode),
      }));
    if (
      selectedAbsenceType.openable !== AvailabilityOpenHandlingEnum.disabled
    ) {
      expectedBackOptions.push({
        id: currentLanguage.ongoingAbsence,
        label: currentLanguage.ongoingAbsence,
      });
    }
    return expectedBackOptions;
  }, [shift, selectedAbsenceType.openable]);

  const [selectedExpectedBack, setSelectedExpectedBack] = useState(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    expectedBackOptions[0]!.id
  );

  const onAbsenceTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const selected = absenceTypes.find((a) => a.id === value);
    if (selected) {
      setSelectedAbsenceType(selected);
    }
  };

  const onExpectedBackChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedExpectedBack(event.target.value);
  };

  const onRegisterAbsenceClicked = () => {
    if (selectedExpectedBack === currentLanguage.ongoingAbsence) {
      onRegisterAbsenceClick(selectedAbsenceType.id, null);
    } else {
      onRegisterAbsenceClick(
        selectedAbsenceType.id,
        dateFromString(selectedExpectedBack)
      );
    }
  };

  // Auto select first absence type if none is selected or selected absence type is not in the list
  useLayoutEffect(() => {
    if (absenceTypes[0] && !selectedAbsenceTypeIsValid) {
      setSelectedAbsenceType(absenceTypes[0]);
    }
  }, [absenceTypes, selectedAbsenceTypeIsValid]);

  return (
    <Section
      title={currentLanguage.registerAbsence}
      className="registerAbsence"
    >
      <SelectInput
        required
        id={ids.ShiftView.absenceSelect}
        onChange={onAbsenceTypeChange}
        label={currentLanguage.absenceType}
        value={selectedAbsenceType.id}
        disabled={disabled || !selectedAbsenceTypeIsValid}
        defaultValue={currentLanguage.SelectAbsenceType}
        options={absenceTypes.map((absenceType) => {
          return {
            value: absenceType.id,
            key: absenceType.id,
            label: absenceType.label,
          };
        })}
        ghost={false}
      />
      <SelectInput
        required
        id={ids.ShiftView.backSelect}
        onChange={onExpectedBackChange}
        label={currentLanguage.expectedToBeBack}
        defaultValue={currentLanguage.SelectAbsenceBack}
        value={selectedExpectedBack}
        disabled={disabled}
        options={expectedBackOptions.map((expectedBackOption) => {
          return {
            value: expectedBackOption.id,
            key: expectedBackOption.id,
            label: expectedBackOption.label,
          };
        })}
      />
      <LoadingButton
        id={ids.ShiftView.absenceButton}
        onClick={() => {
          onRegisterAbsenceClicked();
        }}
        size={ButtonSize.Large}
        variant={ButtonStyle.Primary}
        disabled={
          disabled ||
          selectedExpectedBack === '' ||
          selectedAbsenceType.id === '' ||
          !selectedAbsenceTypeIsValid
        }
        loading={loading}
      >
        {currentLanguage.registerAbsence}
      </LoadingButton>
    </Section>
  );
};

export default RegisterAbsenceSection;

