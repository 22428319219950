import ListView, { DispatchFromProps, StateFromProps } from './ListView';
import {
  attemptGetMessages,
  atteptGetUnreadMessagesCount,
  changeDate,
  toggleCalendar,
  toggleSearchBar,
} from '../appActions';
import {
  attemptLoadTeamRoster,
  changePeriod,
  updateDayLabelSticky,
  updateSelectedShift,
} from './ListViewActions';
import {
  setOfferViewOpen,
  setSwapViewOpen,
} from '../ShiftView/ShiftViewActions';

import { Store, connect } from '../rootReducer';
import { ThunkDispatch } from '../types';

export type ListViewProps = {
  /**
   * Use the team id from the route param instead of .currentTeam from store.
   * This prevents issues with rendering and state tearing.
   */
  teamId: string;
};

const mapStateToProps = (
  store: Store,
  props: ListViewProps
): StateFromProps => ({
  shifts: store.listViewReducer.shiftMap[props.teamId] ?? {},
  employees: store.listViewReducer.employeeMap[props.teamId] ?? {},
  loading: store.listViewReducer.loading,
  backLoading: store.listViewReducer.backLoading,
  currentDate: store.appReducer.globalSettings.useDateTime,
  period: store.listViewReducer.period,
  dayMessageMap: store.appReducer.dayMessageMap,
  userId: store.loginReducer.userId,
  shouldShowSearchBar: store.appReducer.shouldShowSearchBar,
  shouldShowCalendar: store.appReducer.shouldShowCalendar,
  splitView: store.appReducer.screensizeBig,
  // If value exists then the team has been loaded at least once.
  hasLoadedShifts: !!store.listViewReducer.shiftMap[props.teamId],
  requiredActionsMap: store.listViewReducer.actionRequiredCounters,
  dayLabelStickyDate: store.listViewReducer.dayLabelStickyDate,
  holidays: store.appReducer.holidays,
  activeDate: store.appReducer.activeDate,
  teams: store.chooseTeamReducer.teams,
  screensizeBig: store.appReducer.screensizeBig,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  selectShift: (shiftId) => {
    dispatch(setSwapViewOpen(false));
    dispatch(setOfferViewOpen(false));
    dispatch(updateSelectedShift(shiftId));
  },
  loadShifts: (teamId, requestPeriod, isBack) =>
    dispatch(attemptLoadTeamRoster(teamId, requestPeriod, isBack, false)),
  changePeriod: (period) => {
    dispatch(changePeriod(period));
  },
  updateDayLabelSticky: (date) => dispatch(updateDayLabelSticky(date)),
  getMessages: (personIds, from, to) => {
    dispatch(attemptGetMessages(personIds, from, to, false));
  },
  getUnreadCount: (teamId, from, to) => {
    dispatch(atteptGetUnreadMessagesCount([teamId], from, to, false));
  },
  toggleCalendar: () => {
    dispatch(toggleCalendar());
  },
  changeDate: (date) => {
    const newDate = new Date(date);
    newDate.setHours(2); // Adding some hours to avoid being one day off on account of UTC
    dispatch(changeDate(newDate));
  },
  toggleSearchBar: () => {
    dispatch(toggleSearchBar());
  },
});

export default connect<StateFromProps, DispatchFromProps, ListViewProps>(
  mapStateToProps,
  mapDispatchToProps
)(ListView);

