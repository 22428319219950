import { AsyncThunk, ThunkDispatch } from '../types';

import { CallIn } from '../api/TeamPlan_api';
import { attemptSendNotificationLog } from '../appActions';
import getApi from '../getApi';
import { isCreatedShift } from '../ListView/CreateShift/EditedShift';
import { Period } from '../util/dates';

export type FindSubstituteViewAction =
  | {
      type: 'LOADSUBSTITUTES_REQUEST';
    }
  | {
      type: 'LOADSUBSTITUTES_SUCCESS';
      loadSubstitutesResponse: CallIn[];
    }
  | {
      type: 'LOADSUBSTITUTES_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'SET_LOADEDFORSHIFT';
      id: string;
    }
  | {
      type: 'RESET_SUBSTITUTE_STORE';
    };

export const loadSubstitutesRequest = (): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUTES_REQUEST',
});

export const loadSubstitutesSuccess = (
  loadSubstitutesResponse: CallIn[]
): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUTES_SUCCESS',
  loadSubstitutesResponse,
});

export const loadSubstitutesFailure = (
  errorMessage: string
): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUTES_FAILURE',
  errorMessage,
});

export const setLoadedForShift = (id: string): FindSubstituteViewAction => ({
  type: 'SET_LOADEDFORSHIFT',
  id,
});

export const resetSubstituteStore = (): FindSubstituteViewAction => ({
  type: 'RESET_SUBSTITUTE_STORE',
});

export function attemptLoadSubstitutes(
  shiftId: string,
  teamId: string,
  updatedPeriod: Period | null
) {
  return (dispatch: ThunkDispatch) => {
    dispatch(loadSubstitutesRequest());
    // TODO make request here for substitues
    return getApi()
      .getCallIn(shiftId, teamId, updatedPeriod)
      .then((substitutes) => {
        dispatch(loadSubstitutesSuccess(substitutes));
      })
      .catch((err) => {
        dispatch(loadSubstitutesFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      });
  };
}

export function attemptGetShiftCandidateList(teamId: string): AsyncThunk {
  return async (dispatch, getState) => {
    try {
      const shiftDraft = getState().shiftViewReducer.shiftDraft;
      if (!shiftDraft || !isCreatedShift(shiftDraft)) {
        console.error('No edited shift found');
        return;
      }
      dispatch(loadSubstitutesRequest());
      const substitutes = await getApi().getShiftCandidateList(
        teamId,
        shiftDraft
      );
      dispatch(loadSubstitutesSuccess(substitutes));
    } catch (err) {
      if (err instanceof Error) {
        dispatch(loadSubstitutesFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      }
    }
  };
}

