import { IconSize, Spinner } from '@pdcfrontendui/components';

import React from 'react';
import { currentLanguage } from '../../currentLanguage';
import scss from './ShiftsLoadingState.module.scss';

function ShiftsLoadingState() {
  return (
    <div className={scss.comp}>
      {currentLanguage.MomentLoadingData}
      <Spinner className={scss.spinner} size={IconSize.XLarge} />
    </div>
  );
}
export default ShiftsLoadingState;
