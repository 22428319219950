import { useRef } from 'react';

/**
 *
 * @returns A WeakMap that persists across renders
 */
export default function useWeakMap<T extends object, U>() {
  const ref = useRef<WeakMap<T, U>>();
  // Performance optimization: only create the WeakMap once on first render
  if (!ref.current) {
    ref.current = new WeakMap<T, U>();
  }
  return ref.current;
}
