import { currentLanguage } from '../currentLanguage';
import { Period } from './dates';
import { differenceInDays, addMinutes, startOfDay } from 'date-fns';

export function applyMinuteOffset(date: Date, minuteOffset: number) {
  return addMinutes(startOfDay(date), minuteOffset);
}

/**
 *
 * Apply a minute offset interval to a given date.
 * If to is smaller than from, it is assumed that the interval crosses midnight.
 * Odd things will happen if from is greater than 1440, or arguments are negative.
 * @example
 * applyMinuteOffsetInterval(new Date('2020-10-10'), 0, 1440) // { from: 10th October 2020, 00.00, to: 11th October 2020, 00.00 }
 * applyMinuteOffsetInterval(new Date('2020-10-10'), 360, 720) // { from: 10th October 2020, 06.00, to: 10th October 2020, 12.00 }
 * applyMinuteOffsetInterval(new Date('2020-10-10'), 720, 360) // { from: 10th October 2020, 12.00, to: 11th October 2020, 06.00 }
 */
export function applyMinuteOffsetInterval(
  date: Date,
  from: number,
  to: number
): Period {
  while (to < from) {
    to += 1440;
  }
  return {
    from: applyMinuteOffset(date, from),
    to: applyMinuteOffset(date, to),
  };
}

export function periodToMinuteOffsetInterval(period: Period) {
  const diff = differenceInDays(period.to, period.from);
  const from = period.from.getHours() * 60 + period.from.getMinutes();
  let to = period.to.getHours() * 60 + period.to.getMinutes() + diff * 1440;
  while (to <= from) {
    to += 1440;
  }
  return {
    from,
    to,
  };
}

/**
 * Assumes that the interval is never more than 24 hours.
 */
export function formatMinuteOffsetInterval(from: number, to: number) {
  while (to > 1440) {
    to -= 1440;
  }
  const fromHours = Math.floor(from / 60)
    .toString()
    .padStart(2, '0');
  const fromMinutes = (from % 60).toString().padStart(2, '0');
  const toHours = Math.floor(to / 60)
    .toString()
    .padStart(2, '0');
  const toMinutes = (to % 60).toString().padStart(2, '0');
  return `${fromHours}${currentLanguage.HhMmSeparator}${fromMinutes} - ${
    toHours === '00' && toMinutes === '00' ? 24 : toHours
  }${currentLanguage.HhMmSeparator}${toMinutes}`;
}
