import EmptyState from './EmptyState/EmptyState';
import React from 'react';
import { currentLanguage } from '../currentLanguage';
import { localeFormatLongDay } from '../util/dates';

function NoPlannedShiftsImage() {
  return (
    <svg
      width="221.042722px"
      height="190.948253px"
      viewBox="0 0 221.042722 190.948253"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-117.978639, -338.025874)">
          <g transform="translate(117.978639, 329.000000)">
            <rect x="11.0213608" y="0" width="203" height="203"></rect>
            <g transform="translate(0.000000, 9.025874)">
              <g
                transform="translate(110.521361, 95.474126) rotate(-7.308488) translate(-110.521361, -95.474126) translate(9.782873, 12.138044)"
                fill="#DFF5F5"
                fillRule="nonzero"
              >
                <path d="M155.44994,35.1031197 C174.139255,45.2129398 197.202664,50.0851422 200.914017,61.656623 C204.62537,73.3499089 188.984668,91.9860833 183.285089,110.256842 C177.585511,128.527602 181.959606,146.432946 174.669448,156.177351 C167.37929,166.043561 148.424879,167.870636 132.651627,166.043561 C116.878376,164.33829 104.153737,159.100672 85.3318738,159.953307 C66.6425593,160.684138 41.8560216,167.505221 24.4921905,161.536773 C7.26090761,155.568325 -2.68021711,136.688541 0.633491127,119.635832 C3.94719937,102.704929 20.5157406,87.357491 28.9988337,68.2340964 C37.6144751,49.1107017 38.2772167,26.0895452 50.0740181,13.0564036 C61.8708194,0.0232620428 84.9342288,-3.14366955 103.623543,3.06838857 C122.312858,9.2804467 136.628077,24.8714945 155.44994,35.1031197 Z"></path>
              </g>
              <g
                transform="translate(57.602987, 39.352087)"
                fill="#387D7D"
                fillRule="nonzero"
              >
                <path d="M69.8927525,0 C72.8599858,0 75.2695073,2.41345494 75.2695073,5.38719653 L75.2695073,16.1615896 L102.151216,16.1615896 C105.118449,16.1615896 107.526938,18.575148 107.526938,21.5488896 L107.526938,91.5825479 C107.526938,94.5568069 105.118449,96.9701584 102.151216,96.9701584 L5.37644502,96.9701584 C2.40869529,96.9701584 0,94.5568069 0,91.5825479 L0,21.5488896 C0,18.575148 2.40869529,16.1615896 5.37644502,16.1615896 L32.2581537,16.1615896 L32.2581537,5.38719653 C32.2581537,2.41345494 34.6667457,0 37.6344955,0 L69.8927525,0 Z M96.7744612,86.1949374 L96.7744612,26.9360861 L10.7527868,26.9360861 L10.7527868,86.1949374 L96.7744612,86.1949374 Z M64.5159976,10.7743931 L43.0108372,10.7743931 L43.0108372,16.1615896 L64.5159976,16.1615896 L64.5159976,10.7743931 Z"></path>
              </g>
              <g transform="translate(106.419844, 1.205977)" fill="#387D7D">
                <g transform="translate(0.000000, 102.991055)">
                  <ellipse
                    cx="5.6224479"
                    cy="5.73178516"
                    rx="5.6224479"
                    ry="5.73178516"
                  ></ellipse>
                </g>
                <g
                  transform="translate(64.220796, 35.993031)"
                  fillRule="nonzero"
                >
                  <path d="M0,20.0242045 L0,15.8927161 L7.36072333,7.27450854 C8.56895156,5.86903025 9.46281319,4.87283959 10.0423082,4.27821415 C9.43857288,4.3168262 8.64318752,4.33999342 7.65690968,4.35543824 L0.721906898,4.39405028 L0.721906898,0 L16.9591308,0 L16.9591308,3.7530904 L9.45069303,12.579803 L6.80622611,15.4988733 C8.24852488,15.4139268 9.14238652,15.3675924 9.48781102,15.3675924 L17.5325657,15.3675924 L17.5325657,20.0242045 L0,20.0242045 Z"></path>
                </g>
                <g
                  transform="translate(42.424489, 0.000000)"
                  fillRule="nonzero"
                >
                  <path d="M0,31.7726807 L0,25.2171912 L11.0260251,11.5425628 C12.8358954,9.31247101 14.1748591,7.73180159 15.042916,6.78830124 C14.1385482,6.84956749 12.9470975,6.88632725 11.4696986,6.91083375 L1.08138334,6.97210001 L1.08138334,0 L25.4039981,0 L25.4039981,5.95508015 L14.1567036,19.9605464 L10.1954138,24.5922754 C12.355911,24.4574897 13.6948746,24.3839702 14.2123047,24.3839702 L26.2629774,24.3839702 L26.2629774,31.7726807 L0,31.7726807 Z"></path>
                </g>
              </g>
              <g
                transform="translate(83.287466, 87.073158)"
                stroke="#387D7D"
                strokeWidth="2.33231503"
              >
                <g>
                  <path d="M18.4340015,0.00836921719 C17.0114004,3.78408177 13.4203002,6.46701369 9.21817355,6.46701369 C5.01370126,6.46701369 1.42025549,3.77929936 0,0"></path>
                </g>
                <g transform="translate(39.075650, 0.000000)">
                  <path d="M18.4340015,0.00836921719 C17.0114004,3.78408177 13.4203002,6.46701369 9.21817355,6.46701369 C5.01370126,6.46701369 1.42025549,3.77929936 0,0"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function NoPlannedShifts({
  className,
  from,
  to,
}: {
  className?: string;
  from: Date;
  to: Date;
}) {
  return (
    <EmptyState
      className={className}
      image={NoPlannedShiftsImage}
      heading={currentLanguage.NothingPlanned}
      description={currentLanguage.NothingPlannedForGroup_2(
        localeFormatLongDay(from, false, currentLanguage.languageCode),
        localeFormatLongDay(to, false, currentLanguage.languageCode)
      )}
    />
  );
}
