import type {
  Person,
  TeamActivity,
  TeamDuty,
  TeamShift,
  TeamShiftDef,
} from '../../api/TeamPlan_api';
import { TeamShiftStatusEnum } from '../../api/enumLib_api';
import { EDITED_SHIFT_ID } from '../../constants';
import getApi from '../../getApi';
import { periodToMinuteOffsetInterval } from '../../util/minuteOffset';

export enum ShiftDraftType {
  /**
   * A new shift is being created, and a type has not been selected yet.
   * Just a dummy value.
   */
  NewlyCreated = 'NewlyCreated',
  /**
   * A new shift is being created.
   */
  Created = 'Created',
  /**
   * An existing shift is being edited.
   */
  Edited = 'Edited',
}

export type NewlyCreatedShift = {
  type: ShiftDraftType.NewlyCreated;
};

export type CreatedShift = {
  type: ShiftDraftType.Created;
  from: Date;
  to: Date;
  dutyLines: TeamDuty[];
  def: TeamShiftDef;
};

export type EditedShift = {
  type: ShiftDraftType.Edited;
  from: Date;
  to: Date;
  dutyLines: TeamDuty[];
  /**
   * If null, then the current team does not have access to the shift definition.
   */
  readonly def: TeamShiftDef | null;
  /**
   * The original shift that is being edited.
   * Part of the type as convenience.
   */
  readonly originalShift: TeamShift;
  /**
   * The original employee for the shift.
   * Part of the type as convenience.
   */
  readonly originalEmployee: Person;
};

export type ShiftDraft = NewlyCreatedShift | CreatedShift | EditedShift;

export function isNewlyCreatedShift(
  shift: ShiftDraft | null
): shift is NewlyCreatedShift {
  return shift?.type === ShiftDraftType.NewlyCreated;
}

export function isCreatedShift(
  shift: ShiftDraft | null
): shift is CreatedShift {
  return shift?.type === ShiftDraftType.Created;
}

export function isEditedShift(shift: ShiftDraft | null): shift is EditedShift {
  return shift?.type === ShiftDraftType.Edited;
}

export function isEditedShiftWithKnownDef(
  shift: ShiftDraft | null
): shift is EditedShift & { def: TeamShiftDef } {
  return shift?.type === ShiftDraftType.Edited && shift.def !== null;
}

/**
 * Convert the current edited shift to a TeamShift, so that it can fit into the same flows as an ordinary shift.
 */
export function shiftDraftToTeamShift(
  shiftDraft: CreatedShift | EditedShift
): TeamShift {
  switch (shiftDraft.type) {
    case ShiftDraftType.Created:
      return {
        id: EDITED_SHIFT_ID,
        type: '',
        from: shiftDraft.from,
        to: shiftDraft.to,
        formerPersonId: 0,
        label: shiftDraft.def.label,
        personId: -999, // Arbitrary value, meant to not match any person
        numSwapSuggestions: null,
        period: { from: shiftDraft.from, to: shiftDraft.to },
        // This is the closest, as a created shift will always need to be assigned
        status: TeamShiftStatusEnum.substitute,
        record: [],
        responsibilities: [],
        activities: [],
        dutyLines: shiftDraft.dutyLines,
        dutyLinesOriginal: shiftDraft.dutyLines,
        tasks: [],
        deviatingPayerUiLabel: null,
        isTimeEditable: shiftDraft.def.editable,
        shiftDefId: shiftDraft.def.id,
      };
    case ShiftDraftType.Edited:
      return {
        ...shiftDraft.originalShift,
        id: EDITED_SHIFT_ID,
        from: shiftDraft.from,
        to: shiftDraft.to,
        // Use original label if type is not changed. The definition label might be slightly different.
        label:
          shiftDraft.def?.id === shiftDraft.originalShift.shiftDefId
            ? shiftDraft.originalShift.label
            : shiftDraft.def?.label ?? shiftDraft.originalShift.label,
        period: { from: shiftDraft.from, to: shiftDraft.to },
        dutyLinesOriginal: shiftDraft.dutyLines,
        dutyLines: shiftDraft.dutyLines,
        isTimeEditable:
          shiftDraft.def?.editable ?? shiftDraft.originalShift.isTimeEditable,
        shiftDefId: shiftDraft.def?.id ?? shiftDraft.originalShift.shiftDefId,
      };
  }
}

export function getShiftDraftLabel(draft: CreatedShift | EditedShift): string {
  switch (draft.type) {
    case ShiftDraftType.Created:
      return draft.def.label;
    case ShiftDraftType.Edited:
      return draft.def?.label ?? draft.originalShift.label;
  }
}

export function teamShiftToEditedShift(
  originalShift: TeamShift,
  def: TeamShiftDef | null,
  originalEmployee: Person
): EditedShift {
  return {
    type: ShiftDraftType.Edited,
    from: new Date(originalShift.period.from),
    to: new Date(originalShift.period.to),
    def,
    dutyLines: [
      ...(originalShift.dutyLinesOriginal.length
        ? originalShift.dutyLinesOriginal
        : originalShift.dutyLines),
    ],
    originalShift,
    originalEmployee,
  };
}

/**
 * Warnings and notifications that are shown when editing or deleting a shift.
 */
export type UpdateShiftNotifications = {
  activitiesDeleted: TeamActivity[] | null;
  fetchActivitiesDeletedFailed: boolean;
  modifiedDutylines: ModifiedDutylines | null;
  deviatingWorkplace: string | null;
  unknownShiftDef: string | null;
};

export type ModifiedDutylines = {
  /**
   * The duty lines that have been modified.
   */
  dutylines: TeamDuty[];
  /** Whether or not a dutyline type has changed. If so, notification should be `caution` when editing and `error` when deleting. */
  typeHasChanged: boolean;
};

/**
 * Determine if a shift's duty lines have been modified (ie. they differ from the corresponding shift def).
 * Not to be confused with `getModifiedDutylines`, which is an API call.
 */
export function calculateModifiedDutylines(
  currentDutylines: TeamDuty[],
  def: TeamShiftDef
): ModifiedDutylines {
  const dutylines: TeamDuty[] = [];
  let typeHasChanged = currentDutylines.length !== def.dutyLines.length;
  // We compare these in order, which may not give the best representation of changes.
  // But we expect it to suffice, as giving the wrong representation will not restrict functionality.
  for (let i = 0; i < currentDutylines.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentDutyline = currentDutylines[i]!;
    const defDutyline = def.dutyLines[i];
    if (
      !defDutyline ||
      defDutyline.salarysort !== currentDutyline.salarysort ||
      defDutyline.salarysortText !== currentDutyline.salarysortText ||
      defDutyline.start !== currentDutyline.start ||
      defDutyline.end !== currentDutyline.end
    ) {
      dutylines.push(currentDutyline);
      if (
        defDutyline?.salarysort !== currentDutyline.salarysort ||
        defDutyline.salarysortText !== currentDutyline.salarysortText
      ) {
        typeHasChanged ||= true;
      }
    }
  }
  return { dutylines, typeHasChanged };
}

/**
 * Ask the backend for the modified duty lines for a shift draft.
 */
export async function updateShiftDraftDutylines<
  T extends (EditedShift & { def: TeamShiftDef }) | CreatedShift
>(draft: T): Promise<T> {
  const interval = periodToMinuteOffsetInterval(draft);
  const compareWith = isCreatedShift(draft)
    ? { from: draft.def.startTime, to: draft.def.endTime }
    : periodToMinuteOffsetInterval(draft.originalShift.period);
  const timeIsEdited =
    compareWith.from !== interval.from || compareWith.to !== interval.to;
  if (timeIsEdited) {
    // Either check for how the dutylines will be affected by the change
    // But if there is less than two duty lines we don't need to ask the backend for new duty lines. We just update it.
    const dutyLines =
      draft.def.dutyLines.length > 1
        ? await getApi().getModifiedDutyLines(draft)
        : draft.def.dutyLines.map((dutyLine) => ({
            ...dutyLine,
            start: interval.from,
            end: interval.to,
          }));
    draft = {
      ...draft,
      dutyLines,
    };
  }
  return draft;
}
