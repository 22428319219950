// This should use generatedEnumLib, but casing differes
export enum LangLanguageEnum {
  Ar = 0,
  Da = 1,
  En = 2,
  Es = 3,
  Fo = 4,
  No = 5,
  Pl = 6,
  Ru = 7,
  Se = 8,
}
