import {
  ExchangeCandidateFilterEnum,
  ExchangeTypeEnum,
} from '../api/enumLib_api';
import { ModalType, ModalWrapperProps } from '../Modal/ModalWrapper';
import ShiftView, { DispatchFromProps, StateFromProps } from './ShiftView';
import {
  attemptAssignShift,
  attemptAssignShiftCandidate,
  attemptCancelOfferOnShiftExchange,
  attemptEndAbsence,
  attemptExchangeAccept,
  attemptExchangeReject,
  attemptGetAbsenceTypes,
  attemptGetExchange,
  attemptMarkAsHandled,
  attemptOfferOnShiftExchange,
  attemptRegisterAbsence,
  attemptRemoveShift,
  exchangeSelectExchangeId,
  setConfirmDeleteModal,
  setShiftDraft,
  setOfferViewOpen,
  setSwapViewOpen,
  updateSelectedCandidateFilter,
} from './ShiftViewActions';
import {
  attemptMarkMessagesAsRead,
  attemptPostMessage,
  openModal,
  showConfirmDialog,
} from '../appActions';

import { EDITED_SHIFT_ID } from '../constants';
import type { Location } from 'history';
import { ModalVariant } from '@pdcfrontendui/components';
import { Period } from '../util/dates';
import { Store } from '../rootReducer';
import { ThunkDispatch } from '../types';
import { attemptGetEmployees } from '../ListView/ListViewActions';
import { connect } from 'react-redux';
import { currentLanguage } from '../currentLanguage';
import {
  shiftDraftToTeamShift,
  isCreatedShift,
  isEditedShift,
} from '../ListView/CreateShift/EditedShift';
import { push } from '../history';
import { getSiteRoutes, getTeamRouteParams } from '../routes';
import { dateFormats } from '@pdcfrontendui/utils';

export type ShiftViewProps = {
  teamId: string;
  personId: number;
  shiftId: string;
  location: Location;
};

const mapStateToProps = (
  store: Store,
  props: ShiftViewProps
): StateFromProps => {
  const routeState = getTeamRouteParams(props.location.search);
  const shifts = store.listViewReducer.shiftMap[props.teamId] ?? {};
  const shift =
    (routeState.shiftId === EDITED_SHIFT_ID &&
      isCreatedShift(store.shiftViewReducer.shiftDraft)) ||
    isEditedShift(store.shiftViewReducer.shiftDraft)
      ? shiftDraftToTeamShift(store.shiftViewReducer.shiftDraft)
      : shifts[props.shiftId] ?? null;
  const employees = store.listViewReducer.employeeMap[props.teamId] ?? {};

  return {
    team: store.chooseTeamReducer.teams[props.teamId] ?? null,
    loading: store.shiftViewReducer.loading,
    loadingEmployee: store.listViewReducer.loadingEmployees,
    shift,
    employees,
    absenceTypes: store.shiftViewReducer.absenceTypes,
    isAssigningShift:
      shift?.personId !== undefined && shift.personId !== props.personId,
    currentDate: store.appReducer.globalSettings.useDateTime,
    shiftRecordType: store.shiftViewReducer.shiftRecordType,
    dayMessageMap: store.appReducer.dayMessageMap,
    userId: store.loginReducer.userId,
    shifts,
    loadingSwap: store.shiftViewReducer.loadingSwap,
    swapDetails: store.shiftViewReducer.swapDetails,
    isSwapViewOpen: store.shiftViewReducer.isSwapViewOpen,
    isOfferViewOpen: store.shiftViewReducer.isOfferViewOpen,
    pendingAcceptRequest: store.shiftViewReducer.pendingAcceptRequest,
    pendingRejectRequest: store.shiftViewReducer.pendingRejectRequest,
    substitutes: store.findSubstituteViewReducer.substitutes,
    selectedExchangeId: store.shiftViewReducer.selectedExchangeId,
    acceptRequestEnabled: store.shiftViewReducer.acceptRequestEnabled,
    teams: store.chooseTeamReducer.teams,
    shiftDraft: store.shiftViewReducer.shiftDraft,
    possibleCandidateFilters:
      store.appReducer.globalSettings.possibleCandidateFilters,
    defaultExchangeCandidateFilter:
      store.appReducer.userSettings.defaultExchangeCandidateFilter,
    selectedExchangeCandidateFilter:
      store.shiftViewReducer.selectedCandidateFilter,
    showConfirmDeleteShift: store.shiftViewReducer.showConfirmDeleteShift,
    deleteShiftLoading: store.shiftViewReducer.deleteShiftLoading,
    shiftDefMap: store.appReducer.shiftDefMap,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  callMarkAsHandled: (teamId, shiftId, personId) => {
    const modalContent: ModalWrapperProps = {
      modalType: ModalType.markAsHandled,
      modalBackText: currentLanguage.Cancel,
      modalBtn: {
        confirmButtonText: currentLanguage.accept,
        onModalConfirm: () =>
          void dispatch(attemptMarkAsHandled(teamId, personId, shiftId)),
      },
    };
    dispatch(openModal(modalContent));
  },
  callOfferOnShiftExchange: (
    shiftId: string,
    currentTeamId: string,
    teamIdList: string[],
    candidateFilter: ExchangeCandidateFilterEnum
  ) => {
    void dispatch(
      attemptOfferOnShiftExchange(
        shiftId,
        teamIdList,
        currentTeamId,
        candidateFilter
      )
    ).then(() => {
      push(getSiteRoutes().team(currentTeamId));
    });
  },
  callCancelOfferOnShiftExchange: (
    teamId: string,
    personId: number,
    shiftId: string
  ) => {
    const modalContent: ModalWrapperProps = {
      modalType: ModalType.cancelOffer,
      modalBackText: currentLanguage.KeepOffer,
      modalBtn: {
        confirmButtonText: currentLanguage.CancelOffer,
        onModalConfirm: () =>
          void dispatch(
            attemptCancelOfferOnShiftExchange(teamId, personId, shiftId)
          ),
      },
    };
    dispatch(openModal(modalContent));
  },
  callRegisterAbsence: (
    teamId: string,
    shiftId: string,
    absenceTypeId: string,
    returnDate: Date | null
  ) => {
    void dispatch(
      attemptRegisterAbsence(teamId, shiftId, absenceTypeId, returnDate)
    );
  },
  callAssignShift: (
    teamId: string,
    personId: number,
    employeeName: string,
    shiftId: string,
    updatedPeriod,
    currentlyOnFictive: boolean
  ) => {
    const modalContent: ModalWrapperProps = {
      modalType: ModalType.shiftAssignment,
      content: `${currentLanguage.ConfirmShiftAssignmentContent} ${
        employeeName || 'this employee'
      }?`,
      modalBackText: currentLanguage.Cancel,
      modalBtn: {
        confirmButtonText: currentLanguage.assignShift,
        onModalConfirm: () => {
          void dispatch(
            attemptAssignShift(
              teamId,
              personId,
              shiftId,
              updatedPeriod,
              currentlyOnFictive
            )
          );
        },
      },
    };
    dispatch(openModal(modalContent));
  },
  callAssignCreatedShift(teamId, personId, employeeName) {
    dispatch(
      showConfirmDialog({
        title: currentLanguage.ConfirmShiftAssignment,
        message: `${currentLanguage.ConfirmShiftAssignmentContent} ${employeeName}?`,
        buttonCancelText: currentLanguage.Cancel,
        buttonOkText: currentLanguage.assignShift,
        onOk: () => {
          void dispatch(attemptAssignShiftCandidate(teamId, personId));
        },
      })
    );
  },
  loadAbsenceTypes: (shiftId, teamId) =>
    void dispatch(attemptGetAbsenceTypes(shiftId, teamId)),
  callGetEmployees: (personIds, teamId) =>
    void dispatch(attemptGetEmployees(personIds, teamId)),
  callPostMessage: (personId, messageText, isInternal, date) => {
    dispatch(attemptPostMessage(personId, messageText, isInternal, date));
  },
  callMarkMessagesAsRead: (messageIds) => {
    dispatch(attemptMarkMessagesAsRead(messageIds));
  },
  getExchange: (personId, shiftStart) => {
    void dispatch(attemptGetExchange(personId, shiftStart));
  },
  setSwapViewOpen: (open: boolean) => {
    dispatch(setSwapViewOpen(open));
  },
  setOfferViewOpen: (open: boolean) => {
    dispatch(setOfferViewOpen(open));
  },
  callExchangeAccept: (
    unitid: string,
    requestExchangePeriod: Period,
    isSwap: boolean,
    offerPersonId: number,
    offerPersonName: string,
    offerExchangeId: number,
    requestPersonId: number | null,
    requestPersonName: string
  ) => {
    const content = isSwap
      ? currentLanguage.ConfirmShiftExchangeContent_2(
          requestPersonName,
          offerPersonName
        )
      : `${currentLanguage.ConfirmShiftAssignmentContent} ${
          offerPersonName || 'this employee'
        }?`;

    const modalContent: ModalWrapperProps = {
      modalType: isSwap ? ModalType.shiftExchange : ModalType.shiftAssignment,
      content: content,
      modalBackText: currentLanguage.Cancel,
      modalBtn: {
        confirmButtonText: isSwap
          ? `${
              currentLanguage.Approve
            } ${currentLanguage.exchange.toLowerCase()}`
          : currentLanguage.assignShift,
        onModalConfirm: () =>
          void dispatch(
            attemptExchangeAccept(
              unitid,
              requestExchangePeriod,
              offerPersonId,
              offerExchangeId,
              requestPersonId
            )
          ),
      },
    };
    dispatch(openModal(modalContent));
  },
  callExchangeReject: (
    exchangeId: number,
    exchangePeriod: Period,
    exchangeUnitId: string,
    exchangeType: ExchangeTypeEnum,
    remark: string | null,
    isSwap: boolean
  ) => {
    dispatch(
      showConfirmDialog({
        variant: ModalVariant.Alert,
        title: isSwap
          ? currentLanguage.RejectShiftExchange
          : currentLanguage.RejectShiftAssignment,
        message: isSwap
          ? currentLanguage.RejectShiftExchangeContent
          : currentLanguage.RejectShiftAssignmentContent,
        buttonCancelText: isSwap
          ? currentLanguage.KeepExchange
          : currentLanguage.KeepOffer,
        buttonOkText: isSwap
          ? `${
              currentLanguage.Reject
            } ${currentLanguage.exchange.toLowerCase()}`
          : currentLanguage.CancelOffer,
        onOk: () =>
          void dispatch(
            attemptExchangeReject(
              exchangeId,
              exchangePeriod,
              exchangeUnitId,
              exchangeType,
              remark
            )
          ),
      })
    );
  },
  exchangeSelectExchangeId: (exchangeId) => {
    dispatch(exchangeSelectExchangeId(exchangeId));
  },
  updateSelectedCandidateFilter: (candidateFilter) => {
    dispatch(updateSelectedCandidateFilter(candidateFilter));
  },
  attemptRemoveShift: async (
    shiftId,
    teamId,
    employeeName,
    shiftLabel,
    shiftTimeLabel
  ) => {
    await dispatch(
      attemptRemoveShift(
        shiftId,
        teamId,
        employeeName,
        shiftLabel,
        shiftTimeLabel
      )
    );
  },
  setConfirmDeleteModal: (shown: boolean) => {
    dispatch(setConfirmDeleteModal(shown));
  },
  attemptEndAbsence: (teamId, shift, employeeName) => {
    dispatch(
      showConfirmDialog({
        variant: ModalVariant.Alert,
        onOk: () => {
          void dispatch(attemptEndAbsence(teamId, shift));
        },
        buttonCancelText: currentLanguage.Cancel,
        buttonOkText: currentLanguage.EndImperative,
        title: currentLanguage.EndAbsence,
        message: currentLanguage.ConfirmEndAbsence_2(
          employeeName,
          dateFormats.$31DOT_okt(shift.period.from)
        ),
      })
    );
  },
  setShiftDraft: (shift) => {
    dispatch(setShiftDraft(shift));
  },
});

export default connect<
  StateFromProps,
  DispatchFromProps,
  ShiftViewProps,
  Store
>(
  mapStateToProps,
  mapDispatchToProps
)(ShiftView);

