import * as dateFns from 'date-fns';

import { TeamActivity, TeamDuty } from '../api/TeamPlan_api';
import { addDays, startOfDay } from 'date-fns';

import { DAYS_LOADED_DEFAULT } from '../constants';
import { currentLanguage } from '../currentLanguage';
import da from 'date-fns/locale/da';
import en from 'date-fns/locale/en';
import sv from 'date-fns/locale/sv';
import { capitalize, dateFormats } from '@pdcfrontendui/utils';

export type Period = {
  from: Date;
  to: Date;
};

export function getDateFromDutyline(input: number) {
  return new Date(new Date(0).setHours(input / 60, input % 60, 0, 0));
}
export function getTimeStringFromNumber(input: number, isEnd = false): string {
  switch (input) {
    case -1:
    case 9999:
      return '';
    case 1440:
    // @ts-expect-error - falls through
    case 2880:
      if (isEnd) {
        return `24${currentLanguage.HhMmSeparator}00`;
      }
    // TODO Here result becomes undefined!
    // falls through
    default:
      if (input >= 1440) {
        return (
          dateFns.format(
            getDateFromDutyline(input),
            `HH${currentLanguage.HhMmSeparator}mm`
          ) + '(+1)'
        );
      }
      return dateFns.format(
        getDateFromDutyline(input),
        `HH${currentLanguage.HhMmSeparator}mm`
      );
  }
}
export function formatActivityOrDutylineTime(
  dutyline: TeamDuty | TeamActivity
) {
  if (dutyline.start === null || dutyline.end === null) {
    return getTimeStringFromNumber(dutyline.start ?? dutyline.end ?? -1);
  }
  return (
    getTimeStringFromNumber(dutyline.start) +
    ' - ' +
    getTimeStringFromNumber(dutyline.end, true)
  );
}

export function localeFormat(date: Date, showYear: boolean, locale = '') {
  let returnString = '';
  switch (locale) {
    case 'Da':
      returnString = dateFns.format(date, 'ddd DD. MMM', {
        locale: da,
      });
      break;
    case 'Se':
      returnString = dateFns.format(date, 'ddd DD. MMM', {
        locale: sv,
      });
      break;
    default:
      returnString = dateFns.format(date, 'ddd Do MMM', {
        locale: en,
      });
  }
  if (showYear) {
    returnString += dateFns.format(date, ' YYYY');
  }
  return capitalize(returnString);
}

/**
 * @example - Danish
 * formatPeriod(new Date(2021, 0, 1, 0, 0), new Date(2021, 0, 1, 23, 59), 'Da')
 * // => 'fre 1. jan kl. 00:00 - 23:59'
 * @example - Swedish
 * formatPeriod(new Date(2021, 0, 1, 0, 0), new Date(2021, 0, 1, 23, 59), 'Se')
 * // => 'fre 1 jan kl. 00:00 - 23:59'
 * @example - English
 * formatPeriod(new Date(2021, 0, 1, 0, 0), new Date(2021, 0, 1, 23, 59), 'En')
 * // => 'Fri 1st Jan 00:00 - 23:59'
 */
export function formatPeriod(from: Date, to: Date, localeStr: string) {
  const locale = localeStr === 'Da' ? da : localeStr === 'Se' ? sv : en;
  const dayFrom = dateFns.format(from, `ddd Do MMM`, { locale });
  const dayTo =
    Math.abs(dateFns.differenceInMinutes(to, from)) > 24 * 60
      ? dateFns.format(to, `ddd Do MMM`, { locale }) + ' '
      : '';
  const kl = locale === da ? 'kl. ' : '';
  return `${dayFrom} ${kl}${dateFormats.$23DOT59(
    from
  )} - ${dayTo}${dateFormats.End_23DOT59(to)}`;
}

export function localeMonthFormat(date: Date, locale = '') {
  let returnString = '';
  switch (locale) {
    case 'Da':
      returnString = dateFns.format(date, 'MMMM YYYY', {
        locale: da,
      });
      break;
    case 'Se':
      returnString = dateFns.format(date, 'MMMM YYYY', {
        locale: sv,
      });
      break;
    default:
      returnString = dateFns.format(date, 'MMMM YYYY', {
        locale: en,
      });
  }
  return capitalize(returnString);
}

/**
 * Like localeFormat, but with long day names, and without the first letter capitalized
 */
export function localeFormatLongDay(
  date: Date,
  showYear: boolean,
  locale = ''
) {
  let returnString = '';
  switch (locale) {
    case 'Da':
      returnString = dateFns.format(date, 'dddd DD. MMM', {
        locale: da,
      });
      break;
    case 'Se':
      returnString = dateFns.format(date, 'dddd DD. MMM', {
        locale: sv,
      });
      break;
    default:
      returnString = dateFns.format(date, 'dddd Do MMM', {
        locale: en,
      });
  }
  if (showYear) {
    returnString += dateFns.format(date, ' YYYY');
  }
  return returnString;
}

export function dateToString(date: Date): string {
  return dateFns.format(date, 'YYYY-MM-DD');
}

export function dateTimeToString(date: Date): string {
  return dateFns.format(date, 'YYYY-MM-DDTHH:mm');
}
export function dateToTimeString(date: Date): string {
  return dateFns.format(date, 'HH:mm');
}

export function dateFromString(date: string): Date {
  return dateFns.parse(date);
}

export function clonePeriod(period: Period): Period {
  return {
    from: period.from,
    to: period.to,
  };
}

export function combinePeriods(period1: Period, period2: Period): Period {
  const combinedPeriod = {
    from: period1.from,
    to: period1.to,
  };
  if (dateFns.differenceInCalendarDays(period1.from, period2.from) > 0) {
    combinedPeriod.from = period2.from;
  } else if (dateFns.differenceInCalendarDays(period1.to, period2.to) < 0) {
    combinedPeriod.to = period2.to;
  }
  return combinedPeriod;
}

const BEGINNING_OF_TIME = new Date(1900, 0, 3);
const END_OF_TIME = new Date(2100, 0, 3);

function isBorderOfTime(d: Date): boolean {
  return d === BEGINNING_OF_TIME || d === END_OF_TIME;
}
function weekday(date: Date): number {
  return (date.getDay() + 6) % 7;
}
function plusDays(day: Date, days: number): Date {
  return isBorderOfTime(day)
    ? day
    : new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate() + days,
        day.getHours(),
        day.getMinutes()
      );
}
export function floorDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function floorWeek(day: Date): Date {
  return plusDays(day, -weekday(day));
}

export function toDefaultLoadPeriod(from: Date): Period {
  from = startOfDay(from);
  return {
    from,
    to: addDays(from, DAYS_LOADED_DEFAULT),
  };
}

