import {
  InlineNotification,
  NotificationType,
} from '@pdcfrontendui/components';
import React from 'react';
import scss from './ModalNotification.module.scss';
import classNames from 'classnames';

/**
 * Abstraction of InlineNotification used inside modal dialogs.
 */
export function ModalNotification({
  title,
  body,
  items,
  type,
  className,
}: {
  title?: string;
  body: string;
  items?: string[];
  type: NotificationType;
  className?: string;
}) {
  return (
    <InlineNotification
      type={type}
      className={classNames(scss.notification, className)}
    >
      {title && <div className={scss.title}>{title}</div>}
      <div>{body}</div>
      {items && (
        <ul>
          {items.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      )}
    </InlineNotification>
  );
}

export function TransparentNotification({
  title,
  body,
  items,
  className,
}: {
  title?: string;
  body: string;
  items?: string[];
  className?: string;
}) {
  return (
    <div className={classNames(scss.notification, className)}>
      {title && <div className={scss.title}>{title}</div>}
      <div>{body}</div>
      {items && (
        <ul>
          {items.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
