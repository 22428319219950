import './BusinessCard.scss';

import Icons, { IconSize } from '@pdcfrontendui/components/Icons';

import { Person } from '../api/TeamPlan_api';
import React from 'react';
import { currentLanguage } from '../currentLanguage';
import ids from '../testing/ids';
import { EmployeeIcon } from './EmployeeIcon';

type BusinessCardProps = {
  employee: Person;
  loadingEmployee: boolean;
};
const hasLetters = /[a-zA-ZæøåÆØÅ]/g;
const BusinessCard = ({ employee, loadingEmployee }: BusinessCardProps) => {
  if (!employee) {
    if (loadingEmployee) {
      return <div>{currentLanguage.Loading}</div>;
    }
    return <div>{currentLanguage.noEmployeeToShow}</div>;
  }
  const numbers = employee.phoneList.filter(
    (item, i, ar) => ar.indexOf(item) === i
  );
  return (
    <div className="businessCard">
      <div>
        <EmployeeIcon isFictive={employee.isFictive} />
        <div>{employee.name}</div>
      </div>
      {numbers.map((phone, i) => {
        if (hasLetters.test(phone)) {
          return null;
        }
        return (
          <a
            key={phone}
            href={'tel:' + phone}
            className="href-style contact-info"
            id={ids.ShiftView.phone + i}
          >
            <Icons.Phone size={IconSize.XSmall} />
            {phone}
          </a>
        );
      })}
      {employee.email !== '' && (
        <a
          key={employee.email}
          href={'mailto:' + employee.email}
          className="href-style contact-info"
          id={ids.ShiftView.email}
        >
          <Icons.Messages size={IconSize.XSmall} />
          <div>{employee.email}</div>
        </a>
      )}
    </div>
  );
};
export default BusinessCard;
