import './EmptyState.scss';

import React, { CSSProperties, memo } from 'react';

import classNames from 'classnames';

const scss = { comp: 'EmptyState' };

function EmptyState({
  image: Image,
  heading,
  description,
  className,
  style,
}: {
  image: () => JSX.Element;
  heading: string;
  description: string | React.ReactNode;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div className={classNames(scss.comp, className)} style={style}>
      <Image />
      <h3>{heading}</h3>
      {typeof description === 'string' && <p>{description}</p>}
      {typeof description !== 'string' && <>{description}</>}
    </div>
  );
}

export default memo(EmptyState);
