import * as dateFns from 'date-fns';

import {
  Button,
  ButtonStyle,
  IconSize,
  Icons,
} from '@pdcfrontendui/components';
import { changeDate, toggleCalendar } from '../appActions';
import {
  changePeriod,
  updateDayLabelSticky,
} from '../ListView/ListViewActions';
import { localeFormat, localeMonthFormat } from '../util/dates';

import CalendarNavigation from './CalendarNavigation';
import { DAYS_LOADED_DEFAULT } from '../constants';
import React from 'react';
import { Store } from '../rootReducer';
import { ThunkDispatch } from '../types';
import { connect } from 'react-redux';
import { currentLanguage } from '../currentLanguage';
import { dateMath } from '@pdcfrontendui/utils';
import ids from '../testing/ids';

export type Props = {
  nextMonth: () => void;
  previousMonth: () => void;
  loadNewMonth: (firstDateOfMonth: Date) => void;
  loadDaysFromDate: (date: Date, days: number) => void;
};
export type DispatchFromProps = {
  changeDate: (date: Date) => void;
  toggleCalendar: () => void;
};

export type StateFromProps = {
  activeDate: Date;
  currentDate: Date;
  dayLabelStickyDate: Date;
  shouldShowCalendar: boolean;
};

const mapStateToProps = (store: Store): StateFromProps => ({
  activeDate: store.appReducer.activeDate,
  currentDate: store.appReducer.globalSettings.useDateTime,
  dayLabelStickyDate: store.listViewReducer.dayLabelStickyDate,
  shouldShowCalendar: store.appReducer.shouldShowCalendar,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  toggleCalendar: () => {
    dispatch(toggleCalendar());
  },
  changeDate: (date: Date) => {
    const from = dateMath.floorDay(date);
    const newPeriod = {
      from,
      to: dateFns.addDays(from, 7),
    };
    const newDate = new Date(date);
    newDate.setHours(2); // Adding some hours to avoid being one day off on account of UTC
    dispatch(changeDate(newDate));
    dispatch(changePeriod(newPeriod));
    dispatch(updateDayLabelSticky(newDate));
  },
});

const DateHeader = ({
  activeDate,
  currentDate,
  dayLabelStickyDate,
  shouldShowCalendar,
  changeDate,
  nextMonth,
  previousMonth,
  toggleCalendar,
  loadDaysFromDate,
  loadNewMonth,
}: StateFromProps & DispatchFromProps & Props) => {
  const todayButtonClicked = () => {
    changeDate(new Date(currentDate));
    toggleCalendar();
  };

  const calendarIconClick = () => {
    if (shouldShowCalendar) {
      loadDaysFromDate(activeDate, DAYS_LOADED_DEFAULT);
    } else {
      loadNewMonth(activeDate);
      loadDaysFromDate(activeDate, 0);
    }
    toggleCalendar();
  };

  return (
    <div key="day-label" className={'day-label-sticky'}>
      {shouldShowCalendar ? (
        <CalendarNavigation
          title={localeMonthFormat(
            dayLabelStickyDate,
            currentLanguage.languageCode
          )}
          onPrevious={previousMonth}
          onNext={nextMonth}
        />
      ) : (
        <div className="CalendarNavigation">
          {localeFormat(dayLabelStickyDate, true, currentLanguage.languageCode)}
        </div>
      )}
      {shouldShowCalendar && (
        <Button
          className="today"
          variant={ButtonStyle.Ghost}
          onClick={() => todayButtonClicked()}
        >
          {currentLanguage.today}
        </Button>
      )}
      {shouldShowCalendar ? (
        <Icons.CalendarFill
          className="calendarIcon"
          id={ids.Router.CalendarButton}
          key="calendarIcon"
          onClick={() => calendarIconClick()}
          size={IconSize.XSmall}
        />
      ) : (
        <Icons.Calendar
          className="calendarIcon"
          id={ids.Router.CalendarButton}
          key="calendarIcon"
          onClick={() => calendarIconClick()}
          size={IconSize.XSmall}
        />
      )}
    </div>
  );
};
export default connect<StateFromProps, DispatchFromProps, {}, Store>(
  mapStateToProps,
  mapDispatchToProps
)(DateHeader);
