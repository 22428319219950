import { IconSize, Icons } from '@pdcfrontendui/components';
import { TeamViewMode, getSiteRoutes, useTeamRouteParams } from '../routes';
import { Store, connect } from '../rootReducer';
import { toggleCalendar, toggleSearchBar } from '../appActions';

import { DAYS_LOADED_DEFAULT } from '../constants';
import Header from '@pdcfrontendui/components/Header';
import React from 'react';
import { ThunkDispatch } from '../types';
import ids from '../testing/ids';
import { useNavigate } from 'react-router-dom';
import { currentLanguage } from '../currentLanguage';
import {
  ShiftDraft,
  ShiftDraftType,
} from '../ListView/CreateShift/EditedShift';
import { setShiftDraft } from '../ShiftView/ShiftViewActions';

type Props = {
  title: string;
  loadDaysFromDate: (date: Date, days: number) => void;
};

type StateFromProps = {
  activeDate: Date;
  shouldShowCalendar: boolean;
  shouldShowSearchBar: boolean;
  teamId: string;
  shiftDraft: ShiftDraft | null;
};

type DispatchFromProps = {
  toggleCalendar: () => void;
  toggleSearchBar: () => void;
  setShiftDraft: (shift: ShiftDraft | null) => void;
};

const mapStateToProps = (store: Store): StateFromProps => ({
  activeDate: store.appReducer.activeDate,
  shouldShowCalendar: store.appReducer.shouldShowCalendar,
  shouldShowSearchBar: store.appReducer.shouldShowSearchBar,
  teamId: store.listViewReducer.currentTeam,
  shiftDraft: store.shiftViewReducer.shiftDraft,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  toggleCalendar: () => {
    dispatch(toggleCalendar());
  },
  toggleSearchBar: () => {
    dispatch(toggleSearchBar());
  },
  setShiftDraft: (shift) => {
    dispatch(setShiftDraft(shift));
  },
});

const TopHeader = ({
  title,
  teamId,
  activeDate,
  shouldShowSearchBar,
  shouldShowCalendar,
  loadDaysFromDate,
  toggleCalendar,
  toggleSearchBar,
  shiftDraft,
  setShiftDraft,
}: Props & StateFromProps & DispatchFromProps) => {
  const navigate = useNavigate();
  const params = useTeamRouteParams();
  const isEditingShift = shiftDraft !== null;
  return (
    <Header centerize>
      <Header.Left>
        <Icons.Plus
          ariaLabel={currentLanguage.CreateNewShift}
          style={{ opacity: isEditingShift ? 0.5 : 1 }}
          onClick={
            isEditingShift
              ? undefined
              : () => {
                  setShiftDraft({
                    type: ShiftDraftType.NewlyCreated,
                  });
                  navigate(
                    getSiteRoutes().team(teamId, {
                      ...params,
                      mode: TeamViewMode.CreateOrEdit,
                    })
                  );
                }
          }
        />
      </Header.Left>
      <Header.Title bold>{title}</Header.Title>
      {!shouldShowSearchBar && teamId !== '' && (
        <Header.Right>
          <Icons.Search
            id={ids.Router.SearchButton}
            onClick={() => {
              if (shouldShowCalendar) {
                loadDaysFromDate(activeDate, DAYS_LOADED_DEFAULT);
                toggleCalendar();
              }
              toggleSearchBar();
            }}
            size={IconSize.XSmall}
          />
        </Header.Right>
      )}
    </Header>
  );
};

export default connect<StateFromProps, DispatchFromProps, Props>(
  mapStateToProps,
  mapDispatchToProps
)(TopHeader);
