import scss from './ListView.module.scss';

import * as dateFns from 'date-fns';

import { ButtonSize, ButtonStyle } from '@pdcfrontendui/components/Button';
import {
  CounterVariant,
  IconSize,
  Icons,
  Tabs,
} from '@pdcfrontendui/components';
import {
  DAYS_LOADED_DEFAULT,
  DAYS_LOADED_INCREMENT,
  NO_SHIFTS_DAYS_LOAD,
} from '../constants';
import { EmployeeMap, ShiftMap, TeamMap } from '../api/model';
import {
  Period,
  clonePeriod,
  combinePeriods,
  dateToString,
} from '../util/dates';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Tab,
  getSiteRoutes,
  getTeamRouteParams,
  useTeamRouteParams,
} from '../routes';
import { getDayShiftsMap, getSearchFilteredShifts } from '../util/shifts';

import CalendarWrapper from '../components/Calendar';
import DateHeader from '../components/DateHeader';
import { DayMessageMap } from '../util/getChatKey';
import EmployeeFilterBar from '../EmployeeFilterBar/EmployeeFilterBar';
import ListViewItems from './ListViewItems';
import LoadingButton from '@pdcfrontendui/components/LoadingButton';
import NoChangesShifts from '../components/NoChangesShift';
import NoPlannedShifts from '../components/NoPlannedShifts';
import NoStatusShifts from '../components/NoStatusShifts';
import ShiftsLoadingState from '../components/ShiftsLoadingState';
import { addMonths } from 'date-fns';
import classNames from 'classnames';
import { currentLanguage } from '../currentLanguage';
import { dateMath } from '@pdcfrontendui/utils';
import ids from '../testing/ids';
import useAnimationFrame from '../hooks/useAnimationFrame';
import useWeakMap from '../hooks/useWeakMap';
import { useLocation, useNavigate } from 'react-router-dom';
import { push } from '../history';
import { Holiday } from '../api/Calendar_api';
import { TeamShiftStatusEnum } from '../api/enumLib_api';
import TopHeader from '../components/TopHeader';
import { ListViewProps } from './ListViewContainer';

export type StateFromProps = {
  shifts: ShiftMap;
  employees: EmployeeMap;
  loading: boolean;
  backLoading: boolean;
  currentDate: Date;
  period: Period;
  hideSelectedShiftMarker?: boolean;
  dayMessageMap: DayMessageMap;
  userId: number;
  shouldShowSearchBar: boolean;
  shouldShowCalendar: boolean;
  splitView?: boolean;

  dayLabelStickyDate: Date;
  hasLoadedShifts: boolean;
  requiredActionsMap: Record<string, number>;
  holidays: Holiday[];
  activeDate: Date;
  teams: TeamMap;
  screensizeBig: boolean;
};

export type DispatchFromProps = {
  selectShift: (shiftId: string) => void;
  loadShifts: (id: string, requestPeriod: Period, isBack: boolean) => void;
  changePeriod: (period: Period) => void;
  updateDayLabelSticky: (date: Date) => void;
  getUnreadCount: (teamId: string, from: Date, to: Date) => void;
  getMessages: (personIds: number[], from: Date, to: Date) => void;
  toggleCalendar: () => void;
  toggleSearchBar: () => void;
  changeDate: (date: Date) => void;
};

enum LoadPage {
  Back = 'Back',
  Expand = 'Expand',
  Forward = 'Forward',
}

function LoadMoreButton({
  loading,
  handleLoadPage,
  isBack,
}: {
  loading: boolean;
  isBack: boolean;
  handleLoadPage: (loadDirection: LoadPage) => void;
}) {
  return (
    <LoadingButton
      className={classNames(scss.loadMore, {
        [scss.up ?? '']: isBack,
        [scss.down ?? '']: !isBack,
      })}
      loading={loading}
      id={ids.ListView.load + (isBack ? '-back' : '')}
      onClick={() => {
        handleLoadPage(isBack ? LoadPage.Back : LoadPage.Forward);
      }}
      size={ButtonSize.Medium}
      variant={ButtonStyle.Ghost}
    >
      {isBack && <Icons.ArrowUp size={IconSize.XXSmall} className="arrow" />}
      {currentLanguage.loadMoreDays}
      {!isBack && <Icons.ArrowDown size={IconSize.XXSmall} className="arrow" />}
    </LoadingButton>
  );
}

const ListView = ({
  teamId,
  period,
  shifts,
  employees,
  loading,
  backLoading,
  currentDate,
  dayLabelStickyDate,
  loadShifts,
  changePeriod,
  updateDayLabelSticky,
  selectShift: _selectShift,
  dayMessageMap,
  userId,
  shouldShowSearchBar,
  splitView,
  hasLoadedShifts,
  shouldShowCalendar,
  requiredActionsMap,
  activeDate,
  toggleCalendar,
  screensizeBig,
  holidays,
  getMessages,
  getUnreadCount,
  teams,
  changeDate,
  toggleSearchBar,
}: DispatchFromProps & StateFromProps & ListViewProps) => {
  const hideSelectedShiftMarker = !screensizeBig;
  const location = useLocation();
  const navigate = useNavigate();
  const {
    shiftId,
    search = '',
    tab = Tab.Planned,
    mode,
  } = useTeamRouteParams();
  const selectShift = useCallback(
    (
      teamId: string,
      newShiftId: string,
      personId: number,
      replace?: boolean
    ) => {
      _selectShift(newShiftId);
      navigate(
        getSiteRoutes().team(teamId, {
          ...getTeamRouteParams(location.search),
          shiftId: newShiftId,
          personId,
          mode: undefined,
        }),
        { replace }
      );
    },
    [_selectShift, location.search, navigate]
  );
  const setSearch = (search: string) => {
    navigate(
      getSiteRoutes().team(teamId, {
        ...getTeamRouteParams(location.search),
        search,
      }),
      { replace: true }
    );
  };

  const shiftStatuses = useMemo(() => {
    const res: {
      actionRequired: string[];
      offered: string[];
    } = {
      actionRequired: [],
      offered: [],
    };

    Object.keys(shifts).forEach((key) => {
      const fromString = shifts[key]?.period.from.toDateString();
      if (fromString) {
        if (shifts[key]?.status === TeamShiftStatusEnum.actionRequired) {
          res.actionRequired.push(fromString);
        } else if (shifts[key]?.status === TeamShiftStatusEnum.offered) {
          res.offered.push(fromString);
        }
      }
    });
    return res;
  }, [shifts]);

  const loadNewMonth = (firstDateOfMonth: Date) => {
    updateDayLabelSticky(firstDateOfMonth);
    if (dateFns.differenceInMonths(firstDateOfMonth, currentDate) <= 0) {
      firstDateOfMonth = currentDate;
    }
    const newPeriod = {
      from: firstDateOfMonth,
      // set to to sunday 6 weeks later, to match displayed calendar weeks
      to: dateFns.setDay(
        dateFns.addDays(dateFns.setDate(firstDateOfMonth, 1), 35),
        0,
        {
          weekStartsOn: 1,
        }
      ),
    };
    loadShifts(teamId, newPeriod, false);
  };

  const loadDaysFromDate = (date: Date, days: number) => {
    const newPeriod = {
      from: dateFns.startOfDay(date),
      to: dateFns.endOfDay(dateFns.addDays(date, days)),
    };

    changePeriod(newPeriod);
    changeDate(date);
    loadShifts(teamId, newPeriod, false);
    const personIds = Object.keys(employees[teamId] ?? {}).map(Number);
    if (personIds.length) {
      getMessages(personIds, newPeriod.from, newPeriod.to);
    }
    getUnreadCount(teamId, newPeriod.from, newPeriod.to);
  };

  const resetEmployeeFilter = () => {
    setSearch('');
    if (shouldShowCalendar) {
      loadDaysFromDate(activeDate, DAYS_LOADED_DEFAULT);
      toggleCalendar();
    }
    toggleSearchBar();
  };

  const container = useRef<HTMLDivElement>(null);
  // Keep track of scroll position to not call updateDayLabelSticky too often
  const scrollTop = useRef(0);
  // To allow garbage collection of elements that we want to associate with dates. Using a normal Map or an object, we would have to manually do cleanup.
  const daysMap = useWeakMap<HTMLDivElement, Date>();
  // Also a map in the other direction to allow scrolling to a given date. This is a regular object, since it's assigned each render.
  const daysMapInverse = useRef<Record<string, HTMLDivElement>>({});
  const [hasScroll, setHasScroll] = useState(false);
  const dayShiftsMap = useMemo(
    () => getDayShiftsMap(period, shifts, dayMessageMap, userId),
    [period, shifts, dayMessageMap, userId]
  );
  const updateDayLabelStickyRef = useRef(updateDayLabelSticky);
  updateDayLabelStickyRef.current = updateDayLabelSticky;

  const renderedShiftMap =
    tab === Tab.Planned
      ? dayShiftsMap.planned
      : tab === Tab.Pending
      ? dayShiftsMap.pending
      : dayShiftsMap.changes;

  const filteredRenderedShiftMap = useMemo(
    () =>
      shouldShowSearchBar
        ? getSearchFilteredShifts(renderedShiftMap, employees, search)
        : renderedShiftMap,
    [renderedShiftMap, employees, search, shouldShowSearchBar]
  );

  const firstShiftInList = useMemo(() => {
    return Object.values(filteredRenderedShiftMap).find((item) => item[0])?.[0];
  }, [filteredRenderedShiftMap]);

  const hasNoVisibleShifts = Object.values(filteredRenderedShiftMap).every(
    (item) => item.length === 0
  );

  const scrollToDay = useCallback((date: Date) => {
    const elem = daysMapInverse.current[dateToString(date)];
    if (elem && container.current) {
      container.current.scrollTop = elem.offsetTop;
    }
  }, []);

  const scrollToTop = useCallback(() => {
    scrollToDay(period.from);
  }, [period.from, scrollToDay]);

  // Maybe a little expensive calling a function each frame, but it's fully declarative, and we get around having to deal with scroll events.
  useAnimationFrame(() => {
    if (
      container.current &&
      container.current.scrollTop !== scrollTop.current
    ) {
      const top = container.current.getBoundingClientRect().top;
      for (const childNode of Array.from(container.current.childNodes)) {
        if (childNode instanceof HTMLDivElement) {
          const date = daysMap.get(childNode);
          if (date && childNode.getBoundingClientRect().top - top > 0) {
            // If it's the first existing day label, then don't subtract one day
            updateDayLabelSticky(
              childNode === daysMapInverse.current[dateToString(period.from)]
                ? date
                : dateMath.minusDay(date)
            );
            break;
          }
        }
      }
      scrollTop.current = container.current.scrollTop;
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (container.current) {
      setHasScroll(
        container.current.scrollHeight > container.current.offsetHeight
      );
    }
  });

  // Scroll to the point right after loading button after shifts have loaded or we have changed tabs
  useLayoutEffect(scrollToTop, [scrollToTop, tab, hasLoadedShifts]);

  useEffect(() => {
    updateDayLabelStickyRef.current(period.from);
  }, [period.from]);

  useLayoutEffect(() => {
    if (splitView && !shiftId && firstShiftInList?.id && mode === undefined) {
      // If we're in dual view, we have not selected a shift, and there are shifts in the list, then select the first one.
      selectShift(teamId, firstShiftInList.id, firstShiftInList.personId, true);
    }
  }, [
    screensizeBig,
    shiftId,
    firstShiftInList?.id,
    firstShiftInList?.personId,
    teamId,
    selectShift,
    splitView,
    location.search,
    mode,
  ]);

  const handleLoadPage = (loadDirection: LoadPage) => {
    if (loading || shouldShowCalendar) {
      return;
    }
    const requestPeriod = clonePeriod(period);

    if (loadDirection === LoadPage.Back) {
      const from = dateFns.startOfDay(period.from);
      requestPeriod.from = dateFns.subDays(from, DAYS_LOADED_INCREMENT);
      requestPeriod.to = from;
    } else if (loadDirection === LoadPage.Expand) {
      const from = dateFns.startOfDay(period.from);
      const to = dateFns.startOfDay(period.to);
      requestPeriod.from = dateFns.subDays(from, NO_SHIFTS_DAYS_LOAD.BACK);
      requestPeriod.to = dateFns.addDays(to, NO_SHIFTS_DAYS_LOAD.FORWARD);
    } else {
      const from = dateFns.startOfDay(period.to);
      requestPeriod.from = from;
      requestPeriod.to = dateFns.addDays(from, DAYS_LOADED_INCREMENT);
    }
    loadShifts(
      teamId,
      requestPeriod,
      loadDirection === LoadPage.Back || loadDirection === LoadPage.Expand
    );
    getUnreadCount(teamId, requestPeriod.from, requestPeriod.to);
    getMessages(
      Object.keys(employees).map(Number),
      requestPeriod.from,
      requestPeriod.to
    );
    changePeriod(combinePeriods(requestPeriod, period));
  };

  const setSelectedTab = (tab: Tab) => {
    const list =
      tab === Tab.Planned
        ? dayShiftsMap.planned
        : tab === Tab.Pending
        ? dayShiftsMap.pending
        : dayShiftsMap.changes;
    const filteredList = getSearchFilteredShifts(list, employees, search);
    const firstInList = Object.values(filteredList).find(
      (item) => item[0]
    )?.[0];
    // When selecting a tab on desktop, we want to select the first shift in the list, if there is one.
    const shiftId = screensizeBig ? firstInList?.id ?? '' : '';
    const personId = screensizeBig ? firstInList?.personId : undefined;
    push(
      getSiteRoutes().team(teamId, {
        ...getTeamRouteParams(location.search),
        shiftId,
        personId,
        tab,
        mode: undefined,
      })
    );
  };

  const showLoadMoreButtons =
    !shouldShowCalendar && !hasNoVisibleShifts && !shouldShowSearchBar;

  return (
    <>
      <TopHeader
        loadDaysFromDate={loadDaysFromDate}
        title={teams[teamId]?.label ?? ''}
      />
      {shouldShowSearchBar ? (
        <EmployeeFilterBar
          value={search}
          updateFilterByEmployee={setSearch}
          onClick={resetEmployeeFilter}
        />
      ) : (
        <Tabs
          tabsArr={[Tab.Planned, Tab.Pending, Tab.Changes]}
          selectedTab={tab}
          setSelectedTab={setSelectedTab}
          tabLabelMap={{
            [Tab.Planned]: currentLanguage.Planned,
            [Tab.Pending]: currentLanguage.Pending,
            [Tab.Changes]: currentLanguage.Changes,
          }}
          counterMap={{
            [Tab.Pending]: {
              count: requiredActionsMap[teamId] ?? 0,
              variant: CounterVariant.Danger,
            },
          }}
          idMap={{
            [Tab.Planned]: ids.ShiftView.tabPlanned,
            [Tab.Pending]: ids.ShiftView.tabPending,
            [Tab.Changes]: ids.ShiftView.tabChanges,
          }}
        />
      )}
      <DateHeader
        loadDaysFromDate={loadDaysFromDate}
        loadNewMonth={loadNewMonth}
        nextMonth={() => updateDayLabelSticky(addMonths(dayLabelStickyDate, 1))}
        previousMonth={() =>
          updateDayLabelSticky(addMonths(dayLabelStickyDate, -1))
        }
      />
      <CalendarWrapper
        monthDay={dayLabelStickyDate}
        currentDate={currentDate}
        selectedDate={activeDate}
        onDayClick={(date) => {
          loadDaysFromDate(date, 7);
          toggleCalendar();
        }}
        onMonthClick={loadNewMonth}
        shiftStatuses={shiftStatuses}
        largeLayout={screensizeBig}
        holidays={holidays}
        shouldShowCalendar={shouldShowCalendar}
      />
      <div
        ref={container}
        className={classNames(scss.comp, {
          [scss.hasScroll!]: hasScroll,
          [scss.empty!]: hasNoVisibleShifts && hasLoadedShifts,
        })}
      >
        {showLoadMoreButtons && (
          <LoadMoreButton
            isBack={true}
            loading={loading && backLoading}
            handleLoadPage={handleLoadPage}
          />
        )}
        {hasNoVisibleShifts ? (
          <>
            {!hasLoadedShifts ? (
              <ShiftsLoadingState />
            ) : tab === Tab.Pending ? (
              <NoStatusShifts from={period.from} to={period.to} />
            ) : tab === Tab.Changes ? (
              <NoChangesShifts from={period.from} to={period.to} />
            ) : (
              <NoPlannedShifts from={period.from} to={period.to} />
            )}
            {hasLoadedShifts && (
              <LoadingButton
                loading={loading}
                className={scss.loadMoreEmpty}
                variant={ButtonStyle.Secondary}
                onClick={() => handleLoadPage(LoadPage.Expand)}
                id={ids.ListView.expandLoadPeriod}
              >
                {currentLanguage.loadMoreDays}
              </LoadingButton>
            )}
          </>
        ) : (
          <ListViewItems
            currentDate={currentDate}
            employeeMap={employees}
            selectShift={selectShift}
            shiftMap={filteredRenderedShiftMap}
            teamId={teamId}
            hideSelectedShiftMarker={hideSelectedShiftMarker}
            selectedShiftId={shiftId}
            hasMessageLookup={dayShiftsMap.hasMessageLookup}
            hasUnreadLookup={dayShiftsMap.hasUnreadLookup}
            daysMap={daysMap}
            daysMapInverse={daysMapInverse}
            selectedTab={tab}
          />
        )}
        {showLoadMoreButtons && (
          <LoadMoreButton
            isBack={false}
            loading={loading && !backLoading}
            handleLoadPage={handleLoadPage}
          />
        )}
      </div>
    </>
  );
};
export default ListView;
