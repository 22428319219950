import Login, { DispatchFromProps, LoginProps, StateFromProps } from './Login';
import { Store, connect } from '../rootReducer';
import {
  attemptGetGlobalSettings,
  attemptGetVersion,
  closeModal,
  openModal,
} from '../appActions';
import { attemptLogin, attemptOpenIdLogin } from './LoginAction';

import { ModalWrapperProps } from '../Modal/ModalWrapper';
import { OpenIdConfig } from './OpenId';
import { ThunkDispatch } from '../types';

const mapStateToProps = (store: Store): StateFromProps => ({
  errorMessage: store.loginReducer.errorMessage,
  version: store.appReducer.version,
  openModals: store.appReducer.openModals,
  modalLoading: store.appReducer.modalLoading,
  loginType: store.appReducer.globalSettings.logonMethod,
  // These values should be non-null if logon method is OpenId
  openId: {
    authorizeUrl: store.appReducer.globalSettings.openIdAuthorizationEndpoint ?? '',
    scope: store.appReducer.globalSettings.openIdScopePlanner ?? '',
    clientId: store.appReducer.globalSettings.openIdClientId ?? '',
    redirectUri: store.appReducer.globalSettings.openIdRedirectUriPlanner ?? '',
    acrValues: store.appReducer.globalSettings.openIdAcrValuesPlanner ?? '',
    resource: store.appReducer.globalSettings.openIdResource ?? '',
  },
  attemptingLogin: store.loginReducer.attemptingLogin,
  loadingGetSettings: store.appReducer.loadingGetSettings,
  loadingGetVersion: store.appReducer.loadingGetVersion,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchFromProps => ({
  attemptLogin: (username: string, password: string) =>
    dispatch(attemptLogin(username, password)),
  attemptOpenIdLogin: (settings: OpenIdConfig, errorMessage: string) =>
    dispatch(attemptOpenIdLogin(settings, errorMessage)),
  attemptGetVersion: () => dispatch(attemptGetVersion()),
  attemptGetGlobalSettings: () => dispatch(attemptGetGlobalSettings()),
  openModal: (modal: ModalWrapperProps) => {
    dispatch(openModal(modal));
  },
  closeModal: () => dispatch(closeModal()),
});

export default connect<StateFromProps, DispatchFromProps, LoginProps>(
  mapStateToProps,
  mapDispatchToProps
)(Login);
