import { ActionType } from '../ActionType';
import { ChooseTeamAction } from './ChooseTeamAction';
import { CommonAction } from '../commonAction';
import { Reducer } from 'redux';
import { TeamMap } from '../api/model';

export type ChooseTeamReducer = {
  teams: TeamMap;
  loading: boolean;
};

/**
 * The initial state of the loaded teams.
 * Constant to be used for compare.
 */
export const NO_TEAMS_LOADED = {};

const initialState: ChooseTeamReducer = {
  teams: NO_TEAMS_LOADED,
  loading: false,
};

const chooseTeamReducer: Reducer<ChooseTeamReducer> = (
  state: ChooseTeamReducer = initialState,
  action: ChooseTeamAction | CommonAction
) => {
  switch (action.type) {
    case ActionType.INIT:
      return initialState;
    case 'LOADTEAMS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'LOADTEAMS_SUCCESS':
      return {
        ...state,
        loading: false,
        teams: action.loadTeamsResponse,
      };
    case 'LOADTEAMS_FAILURE':
      return {
        ...state,
        loading: false,
      };
  }
  return state;
};

export default chooseTeamReducer;
