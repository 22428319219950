import React, { ReactNode } from 'react';

export interface ErrorBoundaryProps {
  onBtnClick?: () => void;
  logError?: (error: Error, errorInfo: React.ErrorInfo) => void;
  customFallBackUI?: ReactNode;
  children?: ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  error: Error;
  errorInfo: React.ErrorInfo;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: { name: '', message: '' },
      // eslint-disable-next-line react/no-unused-state
      errorInfo: { componentStack: '' },
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Display fallback UI
    // eslint-disable-next-line react/no-unused-state
    this.setState({ hasError: true, error, errorInfo });
    // You can also log the error to an error reporting service
    this.props.logError?.(error, errorInfo);
  }

  render() {
    const { customFallBackUI } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        customFallBackUI || (
          <div>
            <h3> Something went wrong.</h3>
            <p> {this.state.error.message}</p>
          </div>
        )
      );
    }
    return this.props.children;
  }
}
