import {
  InfoModal,
  InfoModalProps,
  ModalVariant,
} from '@pdcfrontendui/components';

import ErrorBoundaryContainer from '../../sharedComponents/ErrorBoundaryContainer';
import type { Optional } from '@pdcfrontendui/types';
import { connect } from '../../rootReducer';
import { hideInfoDialog } from '../../appActions';
import ids from '../../testing/ids';

export type InfoDialogProps = Optional<
  Omit<InfoModalProps, 'buttonOkId' | 'buttonCancelId'>,
  'variant'
>;

const InfoDialog = connect(
  (store) => store.appReducer.infoDialog,
  (dispatch) => ({
    hideInfoDialog: () => {
      dispatch(hideInfoDialog());
    },
  })
)((props) => {
  return (
    <ErrorBoundaryContainer>
      <InfoModal
        {...props}
        variant={props.variant ?? ModalVariant.Dialog}
        onOk={() => {
          props.onOk();
          props.hideInfoDialog();
        }}
        buttonId={ids.Modal.dialogConfirm}
      />
    </ErrorBoundaryContainer>
  );
});

export default InfoDialog;
