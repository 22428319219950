import { RouteProps, matchPath } from 'react-router-dom';

import { getSiteRoutes } from '../routes';
import { objectKeys } from '@pdcfrontendui/utils';

export const routerPropsMock = {
  history: {} as any,
  location: {} as any,
  match: {} as any,
  staticContext: {} as any,
};

export function getCurrentSiteRoute(currentPath: string): string {
  const matchPathProps: RouteProps = {};
  const siteRoutes = getSiteRoutes();
  const siteRouteMatch = objectKeys(siteRoutes).find((siteRoute) => {
    const value = siteRoutes[siteRoute];
    matchPathProps.path = typeof value === 'function' ? value() : value;
    return !!matchPath(currentPath, matchPathProps.path);
  });
  return siteRouteMatch ?? '';
}

