import { IconSize, Icons } from '@pdcfrontendui/components';
import scss from './EmployeeIcon.module.scss';
import classNames from 'classnames';

export function EmployeeIcon({
  isFictive,
  className,
}: {
  isFictive?: boolean;
  className?: string;
}) {
  return (
    <div
      className={classNames(scss.comp, className, isFictive && scss.isFictive)}
    >
      <Icons.User size={IconSize.XSmall} />
      {isFictive && <span className={scss.fictiveDot} />}
    </div>
  );
}
