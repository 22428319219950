import './Badge.scss';

import classNames from 'classnames';

type props = {
  amount: number;
  className?: string;
  style?: 'error' | 'light';
};
const Badge = ({ amount = 0, className, style }: props) => {
  return (
    <div className={classNames('badge', className, style)}>
      {amount > 99 ? '99+' : amount}
    </div>
  );
};

export default Badge;
