import { OpenIdConfig, generateOpenIdAuthorizationEndpoint } from './OpenId';
import { attemptSendNotificationLog, setToken } from '../appActions';

import { ApiError } from '@pdcfrontendui/staffplan';
import { LogonResponse } from '../api/Login_api';
import { ThunkDispatch } from '../types';
import { UserLoginTypeEnum } from '../api/enumLib_api';
import { currentLanguage } from '../currentLanguage';
import { enablePushNotifications } from '../api/PushNotification';
import getApi from '../getApi';
import { init } from '../commonAction';

export enum LoginEvents {
  INIT = 'INIT',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  SET_TOKEN = 'SET_TOKEN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGOUT = 'LOGOUT',
}

export type LoginAction =
  | {
      type: LoginEvents.LOGOUT;
    }
  | {
      type: LoginEvents.LOGIN_REQUEST;
    }
  | {
      type: LoginEvents.LOGIN_SUCCESS;
      token: string;
      userType: UserLoginTypeEnum;
      userId: number;
      fullName: string;
    }
  | {
      type: LoginEvents.LOGIN_FAILURE;
      errorMessage: string;
    }
  | {
      type: LoginEvents.SET_TOKEN;
      token: string;
    };

export const loginRequest = (): LoginAction => ({
  type: LoginEvents.LOGIN_REQUEST,
});

export const loginSuccess = (
  token: string,
  userType: UserLoginTypeEnum,
  userId: number,
  fullName: string
): LoginAction => ({
  type: LoginEvents.LOGIN_SUCCESS,
  token,
  userId,
  userType,
  fullName,
});

export const loginFailure = (errorMessage: string): LoginAction => ({
  type: LoginEvents.LOGIN_FAILURE,
  errorMessage,
});

function loginSuccessHandler(dispatch: ThunkDispatch, json: LogonResponse) {
  getApi()
    .getTeams()
    .then(() => {
      enablePushNotifications();
      dispatch(
        loginSuccess(
          json.token,
          json.userKey.UserType,
          json.userKey.UserId,
          json.name
        )
      );
    })
    .catch((err) => {
      if (err instanceof ApiError && err.response) {
        err.response.exception === 'noAccessToTeamPlan'
          ? dispatch(loginFailure(currentLanguage.NoAccessToTeamPlan))
          : dispatch(loginFailure(err.message));
      }
      dispatch(attemptSendNotificationLog(err));
    });
}

function loginFailureHandler(dispatch: ThunkDispatch, error: Error) {
  dispatch(loginFailure(error.message));
  dispatch(attemptSendNotificationLog(error));
}

export function attemptLogin(username: string, password: string) {
  return (dispatch: ThunkDispatch) => {
    dispatch(loginRequest());
    return getApi()
      .login(username.toLowerCase(), password)
      .then((json) => loginSuccessHandler(dispatch, json))
      .catch((err: Error) => loginFailureHandler(dispatch, err));
  };
}

export function attemptOpenIdLogin(
  settings: OpenIdConfig,
  errorMessage: string
) {
  return (dispatch: ThunkDispatch) => {
    dispatch(loginRequest());

    const authorizeUrl = generateOpenIdAuthorizationEndpoint(
      settings,
      !!errorMessage
    );
    const browser = window.cordova?.InAppBrowser || window;
    const newWindow = browser.open(
      authorizeUrl,
      'openid',
      'location=yes,usewkwebview=yes'
    )!;
    const interval = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(interval);
        dispatch(loginFailure(''));
      }
    }, 500);

    function login(parameters: string) {
      newWindow.close();
      const params = new URLSearchParams(parameters);
      const code = params.get('code');
      if (code) {
        return getApi()
          .loginOpenId(code, settings.redirectUri)
          .then((json) => loginSuccessHandler(dispatch, json))
          .catch((err: Error) => loginFailureHandler(dispatch, err));
      } else {
        const message =
          params.get('error_description') ||
          'Missing code parameter: ' + parameters;
        loginFailureHandler(dispatch, new Error(message));
      }
    }

    // make sure window.pdc.teamplan is always defined
    if (!window.pdc.teamplan) {
      window.pdc.teamplan = {};
    }
    // This is used when TeamPlan is not running in PDC Shell
    // TODO AGRU: Do this in a better way
    window.pdc.teamplan.openIdCallback = login;

    function onLoadStart(event: Event & { url: string }) {
      const eventUrl = event.url && event.url.toLowerCase();
      if (eventUrl?.indexOf(settings.redirectUri.toLowerCase()) === 0) {
        newWindow.removeEventListener('loadstart', onLoadStart);
        login(new URL(event.url).search);
      }
    }

    try {
      // The loadstart event is emitted by Cordova's InAppBrowser when a page is loaded.
      newWindow.addEventListener('loadstart', onLoadStart);
    } catch {
      // Ignore possible security error in browsers
    }
  };
}

declare global {
  interface Window {
    cordova: any;
  }
}

export const logoutAction = (): LoginAction => ({
  type: LoginEvents.LOGOUT,
});

export function logout(shouldCallApi = true) {
  return (dispatch: ThunkDispatch) => {
    dispatch(logoutAction());
    dispatch(init());
    if (shouldCallApi) {
      return getApi()
        .logout()
        .catch((err: Error) => {
          dispatch(attemptSendNotificationLog(err));
        })
        .finally(() => dispatch(setToken('')));
    } else {
      dispatch(setToken(''));
    }
  };
}
