import {
  AndroidNotification,
  IOSNotification,
  handlePushNotificationReceived,
  pushNotificationRegisterError,
  pushNotificationRegisterSuccess,
} from './PushNotificationActions';
import { localStorageGet, localStorageSet } from '../util/localStorage';

import { AnyAction } from 'redux';
import { Api } from './Api';
import { Dispatch } from 'react-redux';
import { LocalStorageKey } from '../util/LocalStorageKey';
import { PushProviderTypeEnum } from './enumLib_api';
import { ThunkDispatch } from '../types';
import { debug as debugMaker } from 'debug';
import { isCordova } from '../isCordova';

const debug = debugMaker('PushNotification');

declare global {
  interface Window {
    device: {
      platform: string;
    };
  }
}

export interface PushNotificationData {
  title: string;
  message: string;
}

export function enablePushNotifications() {
  if (isCordova()) {
    debug('enable push notifications');
    window.pdc.shell.emit('push-enable');
    window.pdc.dispatch('setup-push');
    setTimeout(() => window.pdc.shell.emit('push-token-refresh'), 500);
  }
}

/**
 * Call this method to set up push notifications for the current device.
 * This should be called after the 'onload' and 'deviceready' events have fired.
 */
export function setupPushNotifications(api: Api, dispatch: ThunkDispatch) {
  if (!window.pdc.shell.on) {
    throw new Error('Need window.pdc.shell to be an eventemitter!');
  }
  const shell = window.pdc.shell;

  shell.removeAllListeners('push-token-refresh');

  shell.on('push-token-refresh', (deviceRegistrationId: string) => {
    if (deviceRegistrationId) {
      // We save the deviceId so we can refresh later
      localStorageSet(LocalStorageKey.deviceToken, deviceRegistrationId);
    }
    const deviceId = localStorageGet(LocalStorageKey.deviceToken);
    if (!deviceId) {
      return debug('No deviceId, not sending registerDevice');
    }
    const token = localStorageGet(LocalStorageKey.token);
    if (!token) {
      return debug('No token, not sending registerDevice');
    }
    api
      .registerDevice(deviceId, PushProviderTypeEnum.fcm)
      .then((response) => {
        dispatch(pushNotificationRegisterSuccess(deviceRegistrationId));
      })
      .catch((err) => {
        dispatch(pushNotificationRegisterError(err));
      });
  });

  shell.on(
    'push-notification',
    (data: AndroidNotification | IOSNotification) => {
      dispatch(handlePushNotificationReceived(data));
    }
  );
}

