import EmptyState from './EmptyState/EmptyState';
import React from 'react';
import { currentLanguage } from '../currentLanguage';
import { localeFormatLongDay } from '../util/dates';

function NoStatusShiftsImage() {
  return (
    <svg
      width="221.042722px"
      height="190.948253px"
      viewBox="0 0 221.042722 190.948253"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-118.978639, -329.000000)">
          <g transform="translate(118.978639, 329.000000)">
            <path
              d="M165.232813,47.2411639 C183.922128,57.3509839 206.985537,62.2231864 210.69689,73.7946672 C214.408244,85.4879531 198.767541,104.124127 193.067963,122.394887 C187.368384,140.665646 191.742479,158.57099 184.452321,168.315395 C177.162163,178.181605 158.207752,180.008681 142.434501,178.181605 C126.661249,176.476334 113.93661,171.238716 95.114747,172.091352 C76.4254325,172.822182 51.6388949,179.643265 34.2750637,173.674817 C17.0437809,167.706369 7.10265613,148.826585 10.4163644,131.773876 C13.7300726,114.842973 30.2986138,99.4955351 38.7817069,80.3721405 C47.3973483,61.2487459 48.06009,38.2275893 59.8568913,25.1944478 C71.6536927,12.1613062 94.717102,8.99437463 113.406417,15.2064328 C132.095731,21.4184909 146.410951,37.0095387 165.232813,47.2411639 Z"
              id="Path"
              fill="#DFF5F5"
              fillRule="nonzero"
              transform="translate(110.521361, 95.474126) rotate(-7.308488) translate(-110.521361, -95.474126) "
            ></path>
            <circle fill="#DFF5F5" cx="106.021361" cy="103" r="45"></circle>
            <g
              transform="translate(55.021361, 50.000000)"
              fill="#387D7D"
              fillRule="nonzero"
            >
              <g>
                <path d="M70.5643699,53 L65.9525622,53 C64.9495678,53 63.9957397,53.4818307 63.405743,54.3078261 L47.947829,75.7443735 L40.9465346,66.0290942 C40.3565379,65.2129321 39.4125431,64.7212682 38.3997154,64.7212682 L33.7879077,64.7212682 C33.1487446,64.7212682 32.77508,65.4489308 33.1487446,65.9700945 L45.4010098,82.9620001 C45.9882386,83.7816148 46.9346436,84.2677875 47.9429123,84.2677875 C48.9511811,84.2677875 49.8975861,83.7816148 50.4848149,82.9620001 L71.1936997,54.2488264 C71.5771976,53.7276626 71.203533,53 70.5643699,53 Z"></path>
                <path d="M52.5,0 C23.5078125,0 0,23.5078125 0,52.5 C0,81.4921875 23.5078125,105 52.5,105 C81.4921875,105 105,81.4921875 105,52.5 C105,23.5078125 81.4921875,0 52.5,0 Z M52.5,96.09375 C28.4296875,96.09375 8.90625,76.5703125 8.90625,52.5 C8.90625,28.4296875 28.4296875,8.90625 52.5,8.90625 C76.5703125,8.90625 96.09375,28.4296875 96.09375,52.5 C96.09375,76.5703125 76.5703125,96.09375 52.5,96.09375 Z"></path>
              </g>
            </g>
            <g
              transform="translate(104.906893, 88.208879) scale(-1, -1) translate(-104.906893, -88.208879) translate(80.021361, 85.000000)"
              stroke="#387D7D"
              strokeWidth="2.42487605"
            >
              <g>
                <path d="M18.3305325,0.00830547259 C16.9159164,3.75526011 13.3449729,6.41775732 9.16643247,6.41775732 C4.98555965,6.41775732 1.41228368,3.75051412 0,0"></path>
              </g>
              <g transform="translate(31.440533, 0.000000)" id="Path">
                <path d="M18.3305325,0.00830547259 C16.9159164,3.75526011 13.3449729,6.41775732 9.16643247,6.41775732 C4.98555965,6.41775732 1.41228368,3.75051412 0,0"></path>
              </g>
            </g>
            <path
              d="M177.127887,42.102871 C180.49125,44.120889 183.085845,45.129898 184.911671,45.129898 C186.737496,45.129898 189.476235,44.120889 193.127887,42.102871 C190.821581,46.0428109 189.668427,48.9256938 189.668427,50.7515196 C189.668427,52.5773454 190.821581,55.0277959 193.127887,58.102871 C189.476235,56.084853 186.737496,55.0758439 184.911671,55.0758439 C183.085845,55.0758439 180.49125,56.084853 177.127887,58.102871 C178.857617,54.2590271 179.722481,51.3761442 179.722481,49.4542223 C179.722481,47.5323004 178.857617,45.08185 177.127887,42.102871 Z"
              fill="#387D7D"
              transform="translate(185.127887, 50.102871) rotate(14.000000) translate(-185.127887, -50.102871) "
            ></path>
            <path
              d="M144.613608,6.78593915 C150.919915,10.5697229 155.78478,12.4616148 159.208203,12.4616148 C162.631626,12.4616148 167.766762,10.5697229 174.613608,6.78593915 C170.289284,14.1733265 168.127122,19.5787319 168.127122,23.0021554 C168.127122,26.4255788 170.289284,31.0201734 174.613608,36.7859391 C167.766762,33.0021554 162.631626,31.1102635 159.208203,31.1102635 C155.78478,31.1102635 150.919915,33.0021554 144.613608,36.7859391 C147.856852,29.5787319 149.478473,24.1733265 149.478473,20.5697229 C149.478473,16.9661193 147.856852,12.3715247 144.613608,6.78593915 Z"
              fill="#387D7D"
              transform="translate(159.613608, 21.785939) rotate(16.000000) translate(-159.613608, -21.785939) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function NoStatusShifts({
  className,
  from,
  to,
}: {
  className?: string;
  from: Date;
  to: Date;
}) {
  return (
    <EmptyState
      className={className}
      image={NoStatusShiftsImage}
      heading={currentLanguage.NoUnresolvedIssues}
      description={currentLanguage.NothingToTakeActionOn_2(
        localeFormatLongDay(from, false, currentLanguage.languageCode),
        localeFormatLongDay(to, false, currentLanguage.languageCode)
      )}
    />
  );
}
